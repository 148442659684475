import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from '../../../../commonComponents/DataTable';
import AccountActions from '../../components/Actions';
import { getMoneyString } from '../../../../utils';

const SalaryTable = props => (
  <div>
    <DataTable>
      <DataTable.Head>
        <DataTable.Row>
          <DataTable.Cell>Наименовая счета</DataTable.Cell>
          <DataTable.Cell>Доступный остаток</DataTable.Cell>
          <DataTable.Cell>Доходность</DataTable.Cell>
          <DataTable.Cell>Доход на сегодня</DataTable.Cell>
          <DataTable.Cell>Баланс на сегодня</DataTable.Cell>
          <DataTable.Cell>Операции</DataTable.Cell>
        </DataTable.Row>
      </DataTable.Head>
      <DataTable.Body>
        {props.accounts.map(item => (
          <DataTable.Row key={item.id}>
            <DataTable.Cell>{item.title}</DataTable.Cell>
            <DataTable.Cell>{getMoneyString(item.clearBalance, item.currency)}</DataTable.Cell>
            <DataTable.Cell>{item.percent.toLocaleString()}% годовых</DataTable.Cell>
            <DataTable.Cell>
              <span className="table-value table-value--green">
                {getMoneyString(item.incomeToday, item.currency)}
              </span>
            </DataTable.Cell>
            <DataTable.Cell>{getMoneyString(item.balance, item.currency)}</DataTable.Cell>
            <DataTable.Cell>
              <AccountActions>
                {/* item.currency !== 'RUB' && (
                  <AccountActions.CurrencyTransfer
                    accountId={item.id}
                    collapsed
                  />
                ) */}
                <AccountActions.LinkToTransfer accountType="salary" accountId={item.id} collapsed />
                <AccountActions.LinkToDetails
                  accountId={item.id}
                  dataType="salary"
                  dateOfCreate={item.dateOfCreate}
                  collapsed
                />
              </AccountActions>
            </DataTable.Cell>
          </DataTable.Row>
        ))}
      </DataTable.Body>
    </DataTable>
    <div className="table-description">
      <p className="table-description__item" key="incomeToday">
        <span className="table-description__label table-description__label--incomeToday" />
        Доход в текущем месяце к выплате (после удержания налогов и членских взносов)
      </p>
      <p className="table-description__item" key="mark">
        <span className="table-description__label table-description__label--none" />
        Начисленный доход перечисляется автоматически на текущий счет первого числа месяца,
        следующего за отчетным.
      </p>
    </div>
  </div>
);

SalaryTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    clearBalance: PropTypes.number,
    percent: PropTypes.number,
    incomeToday: PropTypes.number,
    balance: PropTypes.number,
  })).isRequired,
};

const mapStateToProps = state => ({
  accounts: state.accountsReducer.accounts.salary,
});

export default connect(mapStateToProps)(SalaryTable);
