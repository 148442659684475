import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { FormControl } from '../../../../commonComponents/FormControl';
import SelectBox from '../../../../commonComponents/SelectBox';
import Table from '../../../../commonComponents/Table';
import Pagination from '../../../../commonComponents/Pagination';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import Typography from '../../../../commonComponents/Typography';
import SetMaxSum from '../SetMaxSum';
import { getMoneyString } from '../../../../utils';
import {
  getAccounts,
  changeAccount,
  setTransferSum,
  submitForm,
  getShedule,
} from '../../actions/loanTransfers';
import Button from '../../../../commonComponents/Button';
import Modal from '../../../../commonComponents/Modal';
import { DEFAULT_CURRENCY_LABEL } from '../../../../constants';
import ConfirmMethodControls from '../ConfirmMethodControls';

class TransferFormLoan extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
    loanAccount: PropTypes.number,
    debitAccount: PropTypes.number,
    transferSum: PropTypes.number.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    transferFormError: PropTypes.string,
    sheduleError: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
    secondNearestPayment: PropTypes.shape({
      date: PropTypes.string,
      sum: PropTypes.number,
    }),
    shedule: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      date: PropTypes.string,
      sum: PropTypes.number,
    })).isRequired,
    initialAccount: PropTypes.number,
  };

  static defaultProps = {
    className: null,
    loanAccount: null,
    debitAccount: null,
    transferFormError: null,
    sheduleError: null,
    secondNearestPayment: null,
    initialAccount: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      availableSum: 0,
      currency: DEFAULT_CURRENCY_LABEL,
      page: 1,
      latePayment: 0,
      sumError: null,
      isFullPaymentAvailable: false,
      confirmMethod: 'sms',
      accountFromError: null,
      accountToError: null,
    };

    this.changeSummTimer = null;
  }

  componentDidMount() {
    this.props.dispatch(getAccounts());

    if (this.props.initialAccount) {
      this.props.dispatch(changeAccount(this.props.initialAccount, 'loan'));
    }
  }

  componentWillReceiveProps(nextProps) {
    const selectedLoan = nextProps.accounts.find(item => item.id === nextProps.loanAccount);

    if (selectedLoan) {
      this.setState({
        availableSum: selectedLoan.balanceToClose || selectedLoan.maxSum,
        currency: selectedLoan.currencyName,
        latePayment: selectedLoan.latePayment,
        isFullPaymentAvailable: !!selectedLoan.balanceToClose,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.transferSum !== this.props.transferSum && this.props.transferSum > 0) {
      clearTimeout(this.changeSummTimer);
      this.changeSummTimer = setTimeout(() => {
        this.props.dispatch(getShedule());
      }, 300);
    }

    if (!this.props.accounts.length && prevProps.accounts.length) {
      this.props.dispatch(getAccounts());
    }
  }

  getSelectedAccountFrom = () =>
    this.props.accounts.find(item => item.id === this.props.loanAccount);

  getAccountLabelLoan = account =>
    `"${account.accountName}", (задолженность ${getMoneyString(
      account.sum,
      account.currencyName,
    )})`;

  getCurrency = () => {
    const account = this.getSelectedAccountFrom();

    if (account) {
      return account.currencyName || DEFAULT_CURRENCY_LABEL;
    }
    return DEFAULT_CURRENCY_LABEL;
  };

  getDebitAccounts() {
    const selectedAccountLoan = this.props.accounts.find((item) => {
      return item.id === this.props.loanAccount;
    });

    if (selectedAccountLoan) {
      return selectedAccountLoan.availableAccounts.map(item => ({
        value: item.id,
        label: `"${item.accountName}", ${getMoneyString(item.sum, item.currencyName)}`,
      }));
    }
    return null;
  }

  setTransferSum = sum => this.props.dispatch(setTransferSum(sum));

  changeAccount = (id, direction) => {
    this.resetErrors(() => {
      this.props.dispatch(changeAccount(id, direction));
    });
  };

  handleChangetransferSum = (value) => {
    this.setTransferSum(value);
  };

  handleChangeConfirmMethod = (event) => {
    this.setState({
      confirmMethod: event.target.value,
    });
  };

  resetErrors = (callback) => {
    this.setState(
      {
        sumError: null,
        accountFromError: null,
        accountToError: null,
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    );
  };

  validateAccoutFrom = () => {
    const account = this.props.loanAccount;

    if (!account) {
      return 'Выберите заем для погашения';
    }

    return null;
  };

  validateAccoutTo = () => {
    const account = this.props.debitAccount;

    if (!account) {
      return 'Выберите счет для оплаты';
    }

    return null;
  };

  validateSumm = () => {
    const sum = parseFloat(this.props.transferSum);

    if (sum <= 0) {
      return 'Введите сумму погашения';
    }

    if (sum > this.state.availableSum) {
      return 'Недостаточно средств';
    }

    return null;
  };

  submitForm = () => {
    if (this.props.fetching) {
      return;
    }

    this.setState(
      {
        sumError: this.validateSumm(),
        accountFromError: this.validateAccoutFrom(),
        accountToError: this.validateAccoutTo(),
      },
      () => {
        if (this.state.sumError || this.state.accountFromError || this.state.accountToError) {
          return;
        }

        this.props.dispatch(submitForm(this.state.confirmMethod));
      },
    );
  };

  submitHasDisabled = () => {
    const {
      fetching, loanAccount, debitAccount, transferSum,
    } = this.props;
    const { sumError } = this.state;
    return !!(fetching || !loanAccount || !debitAccount || !transferSum || sumError);
  };

  renderLatePaymentData = () => {
    const { latePayment, currency } = this.state;

    if (latePayment && latePayment > 0) {
      return (
        <p className="loan-transfer-form__info">
          <span className="loan-transfer-form__info--danger">Внимание!</span>
          <span> По данному займу имеется просроченная задолженность </span>
          <strong>{getMoneyString(latePayment, currency)}</strong>
          <span>, которая будет списана из суммы досрочного погашения</span>
        </p>
      );
    }
    return null;
  };

  renderSecondNearestPaymentData = () => {
    const paymentData = this.props.secondNearestPayment;
    if (paymentData) {
      const { date, sum } = paymentData;

      return (
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>
              Расчетный ежемесячный платеж после погашения
            </FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>
                Начиная с {moment(date).format('DD.MM.YYYY')}:
              </BlockLikeInput.RegularText>{' '}
              <BlockLikeInput.BoldText>
                {getMoneyString(sum, this.state.currency)}
              </BlockLikeInput.BoldText>
            </BlockLikeInput>
          </FormLineColumn>
          <FormLineColumn size="12">
            <SetMaxSum
              onClick={() => {
                if (this.modal) {
                  this.modal.openModal();
                }
              }}
              label="Посмотреть полный график платежей"
            />
          </FormLineColumn>
        </FormLine>
      );
    }

    return null;
  };

  renderScheduleTable = () => (
    <div>
      <Table
        type="shedule"
        data={{
          shedule: this.props.shedule
            .map((item, index) => ({
              ...item,
              id: index,
              currency: this.state.currency,
            }))
            .slice((this.state.page - 1) * 10, 10 * this.state.page),
        }}
      />
      <Pagination
        key="pagination"
        items={this.props.shedule}
        current={this.state.page}
        limit={10}
        totalCount={this.props.shedule.length}
        onChangePage={page =>
          this.setState({
            page,
          })
        }
      />
    </div>
  );

  renderMaxSumLabel = () => (
    <span>
      {this.state.isFullPaymentAvailable
        ? 'Полное досрочное погашение'
        : 'Максимальное частично-досрочное погашение'}{' '}
      <Typography.Link>
        {getMoneyString(this.state.availableSum, this.state.currency)}
      </Typography.Link>
    </span>
  );

  render() {
    return (
      <div className={cx('transfer-form', { [this.props.className]: this.props.className })}>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>Выберите заем для погашения</FormControl.FormLabel>
            <SelectBox
              className="transfer-form__accounts-select"
              options={this.props.accounts.map(item => ({
                value: item.id,
                label: this.getAccountLabelLoan(item),
                account: item,
              }))}
              name="accountType"
              clearable={false}
              searchable={false}
              onChange={({ value }) => this.changeAccount(value, 'loan')}
              value={this.props.loanAccount}
              placeholder="Выберите счет"
              error={!!this.state.accountFromError}
            />
            <FormControl.FormError>{this.state.accountFromError}</FormControl.FormError>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>Выберите счет для оплаты</FormControl.FormLabel>
            <SelectBox
              className="transfer-form__accounts-select"
              options={this.getDebitAccounts()}
              name="accountType"
              clearable={false}
              searchable={false}
              onChange={({ value }) => this.changeAccount(value, 'debit')}
              value={this.props.debitAccount}
              placeholder="Выберите счет"
              error={!!this.props.loanAccount && !!this.state.accountToError}
            />
            <FormControl.FormError>
              {!!this.props.loanAccount && this.state.accountToError}
            </FormControl.FormError>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="9">
            <FormControl
              type="money"
              name="transferSum"
              id="transferSum"
              label="Сумма погашения"
              value={this.props.transferSum}
              onChange={this.handleChangetransferSum}
              disabled={!this.props.loanAccount || !this.props.debitAccount}
              error={(this.props.loanAccount || this.props.debitAccount) && this.state.sumError}
              onFocus={this.resetErrors}
            />
          </FormLineColumn>
          <FormLineColumn size="3">
            <FormControl.FormLabel>Валюта</FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>{this.state.currency}</BlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
          {this.state.availableSum > 0 &&
            this.props.debitAccount && (
              <FormLineColumn size="12">
                <SetMaxSum
                  onClick={() => this.setTransferSum(this.state.availableSum)}
                  label={this.renderMaxSumLabel()}
                />
              </FormLineColumn>
            )}
        </FormLine>
        {this.renderLatePaymentData()}
        {this.renderSecondNearestPaymentData()}
        <ConfirmMethodControls
          changeHandler={this.handleChangeConfirmMethod}
          selectedMethod={this.state.confirmMethod}
        />
        {(this.props.transferFormError || this.props.sheduleError) && (
          <p className="form-control__error">
            {this.props.transferFormError || this.props.sheduleError}
          </p>
        )}
        <div className="transfer-form__line transfer-form__line--transfer-submit">
          <Button
            type="primary"
            onClick={this.submitForm}
            fakeDisabled={this.submitHasDisabled()}
            block
          >
            {this.props.fetching ? 'Подождите...' : 'Подтвердить операцию'}
          </Button>
        </div>
        <Modal
          ref={(element) => {
            this.modal = element;
          }}
        >
          {this.renderScheduleTable()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.transfersReducer.fetching,
  accounts: state.transfersReducerLoan.accounts,
  loanAccount: state.transfersReducerLoan.loanAccount,
  debitAccount: state.transfersReducerLoan.debitAccount,
  transferSum: state.transfersReducerLoan.transferSum,
  secondNearestPayment: state.transfersReducerLoan.shedule[1] || null,
  shedule: state.transfersReducerLoan.shedule,
  sheduleError: state.transfersReducerLoan.sheduleError,
  transferFormError: state.transfersReducerLoan.transferFormError,
});

export default connect(mapStateToProps)(TransferFormLoan);
