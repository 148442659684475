import { getCookie } from '../../../utils';
import { SUCCESS_RESET_PARAM } from '../../../constants';

const initialState = {
  title: 'MFond',
  isAuthenticated: !!getCookie('access_token') || false,
  authError: null,
  fetching: false,
  userFetching: false,
  user: {
    address: null,
    birthday: null,
    email: null,
    number: null,
    phone: null,
    name: null,
    login: null,
    phoneCountryId: null,
    phoneNumber: null,
  },
  userError: null,
  sessionExpired: false,
  settingsError: null,
  settingsChanged: false,
  showRecoveryForm: false,
  recoveryBySms: false,
  recoverySuccess: false,
  recoveryError: null,
  recoveryCodeError: null,
  isTempPassword: false,
  tempPasswordError: null,
  userSettingsSaved: false,
  globalExchangeRates: [],
  globalExchangeRatesFetching: false,
  globalExchangeRatesError: null,
  limitInfo: {
    IsActivated: false,
    IsAccepted: false,
    Description: '',
  },
  countries: [],
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_SUCCESS':
      return {
        ...state,
        isTempPassword: action.payload || true,
        isAuthenticated: true,
        fetching: false,
        sessionExpired: false,
        settingsChanged: false,
        userError: null,
        settingsError: null,
      };

    case 'AUTH_FAIL':
      return {
        ...state,
        authError: action.payload,
        isAuthenticated: false,
        fetching: false,
      };

    case 'FETCH_START':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_STOP':
      return {
        ...state,
        fetching: false,
      };

    case 'USER_FETCH_START':
      return {
        ...state,
        userFetching: true,
      };

    case 'USER_FETCH_SUCCESS':
      return {
        ...state,
        user: action.payload,
        userFetching: false,
        userError: null,
        settingsChanged: false,
      };

    case 'USER_FETCH_ERROR':
      return {
        ...state,
        userError: action.payload,
      };

    case 'SESSION_WAS_EXPIRED':
      return {
        ...state,
        sessionExpired: true,
      };

    case 'USER_SETTINGS_FAIL':
      return {
        ...state,
        settingsError: action.payload,
        settingsChanged: false,
      };

    case 'CHANGED_USER_ACCOUNT_SETTINGS':
      return {
        ...state,
        settingsChanged: true,
        settingsError: null,
      };

    case 'SHOW_RECOVERY_FORM':
      return {
        ...state,
        showRecoveryForm: true,
      };

    case 'RECOVERY_PASSWORD_BY_SMS':
      return {
        ...state,
        recoveryBySms: true,
        recoveryError: null,
      };

    case 'RECOVERY_SUCCESS':
      return {
        ...state,
        recoverySuccess: true,
        recoveryError: null,
        recoveryCodeError: null,
      };

    case 'RECOVERY_DONE':
      return {
        ...state,
        recoverySuccess: false,
        showRecoveryForm: false,
        recoveryBySms: false,
        recoveryError: null,
        recoveryCodeError: null,
      };

    case 'RECOVERY_BACK_TO_STEP_1':
      return {
        ...state,
        recoverySuccess: false,
        recoveryBySms: false,
      };

    case 'RECOVERY_PASSWORD_ERROR':
      return {
        ...state,
        recoveryError: action.payload,
      };

    case 'RECOVERY_CODE_ERROR':
      return {
        ...state,
        recoveryCodeError: action.payload,
      };

    case 'CHANGE_TEMP_PASSWORD':
      return {
        ...state,
        isTempPassword: false,
        tempPasswordError: null,
      };

    case 'CHANGE_TEMP_PASSWORD_ERROR':
      return {
        ...state,
        tempPasswordError: action.payload,
      };

    case 'USER_SETTINGS_SAVED':
      return {
        ...state,
        userSettingsSaved: true,
      };

    case 'USER_SETTINGS_NOT_SAVED':
      return {
        ...state,
        userSettingsSaved: false,
      };

    case 'SESSION_WAS_EXTENDED':
      return {
        ...state,
        sessionExpired: false,
      };

    case 'RESET_ERRORS':
      return {
        ...state,
        recoveryError: null,
        recoveryCodeError: null,
      };

    case 'LOGOUT':
      return {
        ...initialState,
        isAuthenticated: false,
      };

    case '@@router/LOCATION_CHANGE':
      if (action.payload.pathname === '/' && action.payload.search === SUCCESS_RESET_PARAM) {
        return {
          ...state,
          recoverySuccess: true,
          showRecoveryForm: true,
          recoveryBySms: true,
          recoveryError: null,
          recoveryCodeError: null,
        };
      }

      return state;

    case 'GLOBAL_EXCHANGE_RATE_FETCH_START':
      return {
        ...state,
        globalExchangeRatesFetching: true,
        globalExchangeRatesError: initialState.globalExchangeRatesError,
      };

    case 'GLOBAL_EXCHANGE_RATE_FETCH_SUCCESS':
      return {
        ...state,
        globalExchangeRates: action.payload,
        globalExchangeRatesError: initialState.globalExchangeRatesError,
        globalExchangeRatesFetching: false,
      };

    case 'GLOBAL_EXCHANGE_RATE_FETCH_FAIL':
      return {
        ...state,
        globalExchangeRatesError: action.payload,
        globalExchangeRatesFetching: false,
      };

    case 'CHECK_LIMIT_INFO_SUCCESS':
      return {
        ...state,
        limitInfo: {
          ...state.limitInfo,
          ...action.payload,
        },
      };

    case 'GET_COUNTRIES_SUCCESS':
      return {
        ...state,
        countries: action.payload,
      };

    default:
      return state;
  }
};

export default appReducer;
