const initialState = {
  accounts: [],
  accountFrom: null,
  accountTo: null,
  transferSum: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'innertTransfer/ACCOUNTS_LOADED':
      return {
        ...state,
        accounts: action.payload,
      };

    case 'innertTransfer/CHANGE_FROM_ACCOUNT':
      return {
        ...state,
        accountFrom: action.payload,
        transferSum: 0,
      };

    case 'innertTransfer/CHANGE_TO_ACCOUNT':
      return {
        ...state,
        accountTo: action.payload,
      };

    case 'innertTransfer/CHANGE_TRANSFER_SUM':
      return {
        ...state,
        transferSum: action.payload,
      };

    case 'CANCEL_TRANSFER':
      return initialState;

    default:
      return state;
  }
};
