import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';


const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.arrayOf(PropTypes.element),
]);

const propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes,
};

const defaultProps = {
  className: null,
  children: null,
};

const Heading = ({ className, children }) => (
  <h1
    className={cx(
      'page__heading',
      'page__heading--primary',
      { [className]: className },
    )}
  >
    {children}
  </h1>
);

Heading.propTypes = propTypes;
Heading.defaultProps = defaultProps;

const SubHeading = ({ className, children }) => (
  <div
    className={cx(
      'page__heading',
      'page__heading--sub',
      { [className]: className },
    )}
  >
    {children}
  </div>
);

SubHeading.propTypes = propTypes;
SubHeading.defaultProps = defaultProps;

const Content = ({ className, children }) => (
  <div
    className={cx(
      'page__content',
      { [className]: className },
    )}
  >
    {children}
  </div>
);

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default class Page extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  static Heading = Heading;
  static Content = Content;
  static SubHeading = SubHeading;

  render() {
    const { className, children } = this.props;
    return (
      <div
        className={cx(
          'page',
          { [className]: className },
        )}
      >
        {children}
      </div>
    );
  }
}
