import * as React from 'react';
import styled, { css } from 'styled-components';

export type ColumnSizeType =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

export interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  size: ColumnSizeType;
}

const ColumnView: React.SFC<ColumnProps> = ({ size = '12', ...props }) => <div {...props} />;

export const FormLineColumn = styled(ColumnView)`
  padding: 0 5px;
  width: ${({ size }) => `${(parseInt(size, 10) / 12) * 100}%`};
`;

export interface FromLineProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  center?: boolean;
}

const FormLineView: React.SFC<FromLineProps> = ({ center, ...props }) => <div {...props} />;

const FormLine = styled(FormLineView)`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -5px 20px;

  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

export default FormLine;
