import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from '../../../../commonComponents/Link';
import './assets/styles/styles.scss';

const SuccessMessage = ({ className, transferType }) => (
  <div className={cx('success-message', { [className]: className })}>
    <span className="success-message__icon" />
    <p className="success-message__text">
      {transferType === 'currency' && (
        <span>
          <span>
            Операция обмена валюты подтверждена и отправлена на исполнение в банк.
            <br />
            Средства будут зачислены на текущий счет в течение нескольких минут.
          </span>
        </span>
      )}
      {(transferType === 'inner' ||
        transferType === 'loan' ||
        transferType === 'deposit' ||
        transferType === 'to-partner') && <span>Ваш перевод успешно выполнен</span>}
      {transferType === 'salary' && (
        <span>
          <span>Заявка на перевод денежных средств на вашу банковскую карту принят.</span>
          <br />
          <span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            Проверить статус заявки можно в разделе <Link to="/applications">Мои распоряжения</Link>
          </span>
        </span>
      )}
    </p>
  </div>
);

export default SuccessMessage;

SuccessMessage.propTypes = {
  className: PropTypes.string,
  transferType: PropTypes.string,
};

SuccessMessage.defaultProps = {
  className: null,
  transferType: null,
};
