import { push } from 'react-router-redux';
import { getCookie, resultDataToCamelCase } from '../../../utils';
import { BASE_API_URL } from '../../../constants';

export const fetchStart = () => ({
  type: 'transfer/FETCH_START',
});

export const fetchStop = () => ({
  type: 'transfer/FETCH_STOP',
});

export const fetchFail = errorMessage => ({
  type: 'transfer/FETCH_FAIL',
  payload: errorMessage,
});

const getUrlForConfirmCode = (transferType) => {
  switch (transferType) {
    case 'salary':
      return `${BASE_API_URL}/transfer`;

    case 'loan':
      return `${BASE_API_URL}/transfer/loan`;

    case 'deposit':
      return `${BASE_API_URL}/transfer/deposit`;

    case 'currency':
      return `${BASE_API_URL}/transfer/currency`;

    case 'to-partner':
      return `${BASE_API_URL}/transfer/outer`;

    default:
      return null;
  }
};

const getUrlForReFetchCode = (transferType, codeType) => {
  switch (transferType) {
    case 'salary':
      return `${BASE_API_URL}/transfer/request-code`;

    case 'loan':
      return `${BASE_API_URL}/transfer/loan/request-code`;

    case 'deposit':
      return `${BASE_API_URL}/transfer/deposit/request-code`;

    case 'to-partner':
      return `${BASE_API_URL}/transfer/outer/request-code/${codeType}`;

    default:
      return null;
  }
};

export const cancelTransfer = () => (dispatch) => {
  dispatch({
    type: 'CANCEL_TRANSFER',
  });
};

export const submitConfirmCode = (code, { transferType, transferData }) => (dispatch) => {
  const token = getCookie('access_token');
  const apiUrl = getUrlForConfirmCode(transferType);
  if (!code || !apiUrl) {
    return;
  }

  dispatch(fetchStart());

  fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...transferData, Code: code }),
  })
    .then((response) => {
      if (response.ok) {
        const contentLength = parseInt(response.headers.get('content-length'), 10);
        if (contentLength) {
          return response.json();
        }
        return Promise.resolve();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(() => {
      dispatch(fetchStop());
      dispatch(push('/transfers/success', {
        transferType,
        accountId:
            transferType === 'currency' && transferData
              ? resultDataToCamelCase(transferData).toBill
              : null,
      }));
      dispatch(cancelTransfer());
    })
    .catch((error) => {
      dispatch({
        type: 'TRANSFER_CONFIRM_CODE_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const reFetchConfirmCode = ({ transferType, transferData }) => (dispatch) => {
  const url = getUrlForReFetchCode(transferType, transferData.CodeType);
  const token = getCookie('access_token');

  if (!token || !url) {
    return;
  }
  dispatch(fetchStart());
  fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...transferData }),
  })
    .then((response) => {
      if (response.ok) {
        return response;
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(() => {
      dispatch(fetchStop());
      dispatch({
        type: 'RE_FETCH_CODE_SUCCESS',
      });
    })
    .catch((error) => {
      dispatch({
        type: 'RE_FETCH_CODE_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};
