import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './assets/styles/styles.scss';
import { recoveryDone } from '../../actions';

const getText = (typeSms) => {
  if (typeSms) {
    return (
      <p className="recovery-success__text recovery-success__text--sms">
        Пароль успешно сброшен, новый пароль отправлен на e-mail и в sms
      </p>
    );
  }
  return (
    <p className="recovery-success__text">
      Если вы ввели правильные данные,<br />
      на ваш e-mail отправлена ссылка<br />
      для сброса пароля
    </p>
  );
};

const RecoverySuccess = ({ recoveryBySms, dispatch }) => (
  <div className="recovery-success">
    <div
      className={`recovery-success__icon ${recoveryBySms ? 'recovery-success__icon--sms' : 'recovery-success__icon--email'}`}
    />
    {getText(recoveryBySms)}
    {recoveryBySms &&
      <button
        className="button recovery-success__button"
        onClick={() => dispatch(recoveryDone())}
      >
        Войти с новым паролем
      </button>
    }
  </div>
);

const mapStateToProps = state => ({
  recoveryBySms: state.appReducer.recoveryBySms,
});

export default connect(mapStateToProps)(RecoverySuccess);

RecoverySuccess.propTypes = {
  recoveryBySms: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};
