import React from 'react';
import { NavLink } from 'react-router-dom';
import './assets/styles/styles.scss';
import Wrapper from '../../../../commonComponents/Wrapper';
import SiteLogo from '../../../../commonComponents/SiteLogo';
import MainMenu from '..//MainMenu';
import UserData from '../UserData';
import HeaderNews from '../HeaderNews';
import ExchangeRates from '../ExchangeRates';

const Header = () => (
  <header className="header">
    <div className="header__top">
      <Wrapper className="header__wrapper">
        <HeaderNews />
        <UserData className="header__user" />
      </Wrapper>
    </div>
    <Wrapper className="header__wrapper">
      <div className="header__column">
        <div className="header__logo">
          <NavLink to="/">
            <SiteLogo />
          </NavLink>
        </div>
        <MainMenu className="header__main-menu" />
      </div>
      <div className="header__column">
        <div />
        <div className="header__exchange-rates">
          <ExchangeRates />
        </div>
      </div>
    </Wrapper>
  </header>
);

export default Header;
