import { getCookie, resultDataToCamelCase } from '../../../../utils';
import PromiseHelper from '../../../../utils/PromiseHelper';
import { BASE_API_URL } from '../../../../constants';

export const promiseHelper = new PromiseHelper();

const getRate = async () => {
  const token = getCookie('access_token');

  if (!token) {
    return null;
  }

  promiseHelper.cancel('changeRate');

  const request = await fetch(`${BASE_API_URL}/currency/partner`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  return promiseHelper
    .request(request, 'changeRate', 300)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(resultDataToCamelCase);
};

export default getRate;
