import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import './assets/styles/styles.scss';

class MainMenu extends React.Component {
  componentDidMount() {
    const activeLink = this.getActiveLinkNode();
    if (activeLink) {
      this.roller.style.setProperty(
        'left',
        `${activeLink.offsetLeft + activeLink.offsetWidth / 2}px`,
      );
    } else {
      this.roller.style.setProperty('display', 'none');
    }
  }

  getActiveLinkNode() {
    const links = [this.link1, this.link3, this.link4];
    return links.find(item => item.classList.contains('main-menu__item--active'));
  }

  render() {
    return (
      <nav className={cx('main-menu', this.props.className)}>
        <NavLink
          innerRef={(element) => {
            this.link1 = element;
          }}
          activeClassName="main-menu__item--active"
          className="main-menu__item"
          to="/accounts"
        >
          Счета
        </NavLink>
        <NavLink
          innerRef={(element) => {
            this.link4 = element;
          }}
          activeClassName="main-menu__item--active"
          className="main-menu__item"
          to="/transfers"
        >
          Платежи и переводы
        </NavLink>
        {/* <NavLink innerRef={(element) => { this.link2 = element; }} activeClassName="main-menu__item--active" className="main-menu__item" to="/currency-exchange">Обмен валюты</NavLink> */}
        <NavLink
          innerRef={(element) => {
            this.link3 = element;
          }}
          activeClassName="main-menu__item--active"
          className="main-menu__item"
          to="/applications"
        >
          Мои распоряжения
        </NavLink>
        <div
          ref={(element) => {
            this.roller = element;
          }}
          className="main-menu__roller"
        />
      </nav>
    );
  }
}

export default withRouter(MainMenu);

MainMenu.propTypes = {
  className: PropTypes.string,
};

MainMenu.defaultProps = {
  className: '',
};
