import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import './assets/styles/styles.scss';
import { getNotices, changeSorting } from '../../actions';
import NoticesList from '../../components/NoticesList';
import Throbber from '../../../../commonComponents/Throbber';
import Pagination from '../../../../commonComponents/Pagination';

const PAGINATION_LIMIT = 10;

class Notices extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sorting: PropTypes.oneOf([
      'asc',
      'desc',
    ]).isRequired,
    notices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      preview: PropTypes.string,
      date: PropTypes.string,
      isUnread: PropTypes.bool,
    })).isRequired,
    fetching: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        section: PropTypes.oneOf([
          'new',
          'archive',
        ]).isRequired,
      }).isRequired,
    }).isRequired,
    noticesCount: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);

    this.sectionLinks = [
      {
        title: 'Актуальные',
        link: '/notices/new',
      },
      {
        title: 'Все',
        link: '/notices/archive',
      },
    ];

    this.state = {
      page: 1,
    };

    this.section = props.match.params.section;
  }

  componentDidMount() {
    if (document) {
      document.title = 'Уведомления';
    }

    this.props.dispatch(getNotices(
      this.props.sorting,
      this.section,
      {
        page: this.state.page,
        limit: PAGINATION_LIMIT,
      },
    ));
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.sorting !== nextProps.sorting) {
      nextProps.dispatch(getNotices(
        nextProps.sorting,
        this.section,
        {
          page: nextState.page,
          limit: PAGINATION_LIMIT,
        },
      ));
    }

    if (this.props.match.params.section !== nextProps.match.params.section) {
      nextProps.dispatch(getNotices(
        nextProps.sorting,
        this.section,
        {
          page: nextState.page,
          limit: PAGINATION_LIMIT,
        },
      ));
    }

    if (nextState.page && this.state.page !== nextState.page) {
      nextProps.dispatch(getNotices(
        nextProps.sorting,
        this.section,
        {
          page: nextState.page,
          limit: PAGINATION_LIMIT,
        },
      ));
    }
  }

  renderSortingButton() {
    const sortingLabel = {
      desc: 'от новых к старым',
      asc: 'от старых к новым',
    };
    return (
      <button
        className={cx(
          'notices__sorting-button',
          `notices__sorting-button--${this.props.sorting}`,
        )}
        onClick={() => this.props.dispatch(changeSorting())}
      >
        {sortingLabel[this.props.sorting]}
      </button>
    );
  }

  render() {
    return (
      <div className="notices">
        <div className="notices__header">
          <h1 className="notices__heading">Уведомления</h1>
          <div className="notices__navigation">
            {this.sectionLinks.map(item => (
              <NavLink
                key={item.link}
                to={item.link}
                className="inline-button notices__link"
                activeClassName="notices__link--active"
              >
                {item.title}
              </NavLink>
            ))}
          </div>
          <div className="notices__controls">
            <div className="notices__sorting">
              Сортировка по дате: {this.renderSortingButton()}
            </div>
          </div>
        </div>
        {
          (this.props.fetching && !this.props.notices.length) ?
            <Throbber inPanel /> :
            [
              <NoticesList key="list" data={this.props.notices} />,
              <Pagination
                key="pagination"
                totalCount={this.props.noticesCount}
                current={this.state.page}
                limit={PAGINATION_LIMIT}
                changePage={page => this.setState({
                  page,
                })}
              />,
            ]
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  notices: ownProps.match.params.section === 'new' ? state.noticesReducer.noticesNew : state.noticesReducer.notices,
  noticesCount: ownProps.match.params.section === 'new' ? state.noticesReducer.countNew : state.noticesReducer.count,
  sorting: state.noticesReducer.sorting,
  fetching: state.noticesReducer.noticesFetching,
});

export default connect(mapStateToProps)(Notices);
