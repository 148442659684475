import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { FormControl } from '../../../../commonComponents/FormControl';
import SelectBox from '../../../../commonComponents/SelectBox';
import { getAccountLabelTransfer, getMoneyString } from '../../../../utils';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import Button from '../../../../commonComponents/Button';
import Typography from '../../../../commonComponents/Typography';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import SetMaxSum from '../SetMaxSum';
import {
  getAccounts,
  changeAccount,
  changeTransferSum,
  submitTransferForm,
} from '../../actions/innerTransfer';
import { DEFAULT_CURRENCY_LABEL } from '../../../../constants';

class TransferFormInner extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
    accountFrom: PropTypes.number,
    accountTo: PropTypes.number,
    transferSum: PropTypes.number.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    transferError: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: null,
    accountFrom: null,
    accountTo: null,
    transferError: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      sumError: null,
      accountFromError: null,
      accountToError: null,
      currency: DEFAULT_CURRENCY_LABEL,
      availableSum: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(getAccounts());
  }

  componentWillReceiveProps(nextProps) {
    const selectedAccountFrom = nextProps.accounts.find(item => item.id === nextProps.accountFrom);

    if (selectedAccountFrom) {
      this.setState({
        currency: selectedAccountFrom.currencyName,
        availableSum: selectedAccountFrom.balance,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.accounts.length && prevProps.accounts.length) {
      this.props.dispatch(getAccounts());
    }
  }

  getavailableSum = () => getMoneyString(this.state.availableSum, this.state.currency);

  getAccountFromOptions = () => {
    const selectedToAccount = this.props.accounts.find(item => item.id === this.props.accountFrom);

    if (selectedToAccount) {
      return selectedToAccount.avaliableAccounts.map(item => ({
        value: item.id,
        label: `"${item.accountName}", ${item.currencyName}`,
        account: item,
      }));
    }
    return null;
  };

  setMaxSum = () => {
    this.props.dispatch(changeTransferSum(this.state.availableSum));
  };

  changeFromAccount = ({ value: id }) => {
    this.resetErrors(() => {
      this.props.dispatch(changeAccount(id, 'from'));
    });
  };

  changeToAccount = ({ value: id }) => {
    this.resetErrors(() => {
      this.props.dispatch(changeAccount(id, 'to'));
    });
  };

  handleChangetransferSum = (value) => {
    this.props.dispatch(changeTransferSum(value));
  };

  resetErrors = (callback) => {
    this.setState(
      {
        sumError: null,
        accountFromError: null,
        accountToError: null,
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    );
  };

  validateAccoutFrom = () => {
    const account = this.props.accountFrom;

    if (!account) {
      return 'Выберите счет списания';
    }

    return null;
  };

  validateAccoutTo = () => {
    const account = this.props.accountTo;

    if (!account) {
      return 'Выберите счет зачисления';
    }

    return null;
  };

  validateSumm = () => {
    const sum = parseFloat(this.props.transferSum);

    if (sum <= 0) {
      return 'Введите сумму перевода';
    }

    if (sum > this.state.availableSum) {
      return 'Недостаточно средств';
    }

    return null;
  };

  submitForm = () => {
    if (this.props.fetching) {
      return;
    }

    this.setState(
      {
        sumError: this.validateSumm(),
        accountFromError: this.validateAccoutFrom(),
        accountToError: this.validateAccoutTo(),
      },
      () => {
        if (this.state.sumError || this.state.accountFromError || this.state.accountToError) {
          return;
        }

        this.props.dispatch(submitTransferForm());
      },
    );
  };

  isSubmitDisabled = () => {
    const { fetching, accountFrom, accountTo } = this.props;
    const { sumError } = this.state;
    return !!(fetching || !accountFrom || !accountTo || sumError);
  };

  render() {
    return (
      <div className={cx('transfer-form', { [this.props.className]: this.props.className })}>
        <FormLine>
          <FormLineColumn size="12">
            <p className="form-control__label">Счет снятия</p>
            <SelectBox
              className="transfer-form__accounts-select"
              options={this.props.accounts.map(item => ({
                value: item.id,
                label: getAccountLabelTransfer(item),
              }))}
              name="accountType"
              clearable={false}
              searchable={false}
              onChange={this.changeFromAccount}
              value={this.props.accountFrom}
              placeholder="Выберите счет"
              error={!!this.state.accountFromError}
            />
            <FormControl.FormError>{this.state.accountFromError}</FormControl.FormError>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <div className="form-control">
              <p className="form-control__label">Счет зачисления</p>
              <SelectBox
                className="transfer-form__accounts-select"
                options={this.getAccountFromOptions()}
                name="accountType"
                clearable={false}
                searchable={false}
                onChange={this.changeToAccount}
                value={this.props.accountTo}
                placeholder="Выберите счет"
                disabled={!this.props.accountFrom}
                error={!!this.props.accountFrom && !!this.state.accountToError}
              />
              <FormControl.FormError>
                {!!this.props.accountFrom && this.state.accountToError}
              </FormControl.FormError>
            </div>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="8">
            <FormControl.FormLabel>Доступно для снятия</FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>{this.getavailableSum()}</BlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
          <FormLineColumn size="4">
            <FormControl.FormLabel>Валюта</FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>
                {this.state.currency || '---'}
              </BlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl
              type="money"
              name="transferSum"
              id="transferSum"
              label="Сумма перевода"
              value={this.props.transferSum}
              onChange={this.handleChangetransferSum}
              error={this.props.accountFrom && this.state.sumError}
              disabled={!this.props.accountFrom}
              onFocus={this.resetErrors}
            />
          </FormLineColumn>
          {this.state.availableSum > 0 && (
            <FormLineColumn size="12">
              <SetMaxSum
                onClick={this.setMaxSum}
                label={
                  <span>
                    Перевести все{' '}
                    <Typography.Link>
                      {getMoneyString(this.state.availableSum, this.state.currency)}
                    </Typography.Link>
                  </span>
                }
              />
            </FormLineColumn>
          )}
        </FormLine>
        <FormLine>
          {this.props.transferError && (
            <FormLineColumn size="12">
              <p className="form-control__error">{this.props.transferError}</p>
            </FormLineColumn>
          )}
          <FormLineColumn size="12">
            <Button
              type="primary"
              fakeDisabled={this.isSubmitDisabled()}
              onClick={this.submitForm}
              block
            >
              {this.props.fetching ? 'Подождите...' : 'Подтвердить операцию'}
            </Button>
          </FormLineColumn>
        </FormLine>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.transfersReducerInner.accounts,
  accountFrom: state.transfersReducerInner.accountFrom,
  accountTo: state.transfersReducerInner.accountTo,
  transferSum: state.transfersReducerInner.transferSum,
  transferError: state.transfersReducer.transferError,
  fetching: state.appReducer.fetching,
});

export default connect(mapStateToProps)(TransferFormInner);
