import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import appReducer from '../modules/App/reducers';
import accountsReducer from '../modules/Accounts/reducers';
import transfersReducer from '../modules/Transfers/reducers';
import transfersReducerLoan from '../modules/Transfers/reducers/loanTransfes';
import transfersReducerInner from '../modules/Transfers/reducers/innerTransfer';
import transfersReducerPartner from '../modules/Transfers/reducers/partnerTransfer';
import transfersReducerSalary from '../modules/Transfers/reducers/salaryTransfer';
import transfersReducerDeposit from '../modules/Transfers/reducers/depositTransfer';
import transfersReducerCurrency from '../modules/Transfers/reducers/currencyTransfer';
import applicationsReducer from '../modules/Applications/reducers';
import applicationsFormsReducer from '../modules/Applications/reducers/forms';
import newsReducer from '../modules/News/reducers';
import dashboardReducer from '../modules/Dashboard/reducers';
import noticesReducer from '../modules/Notices/reducers';

const rootReducer = combineReducers({
  appReducer,
  accountsReducer,
  transfersReducer,
  transfersReducerLoan,
  transfersReducerInner,
  transfersReducerPartner,
  transfersReducerSalary,
  transfersReducerDeposit,
  transfersReducerCurrency,
  applicationsReducer,
  applicationsFormsReducer,
  noticesReducer,
  newsReducer,
  dashboardReducer,
  router: routerReducer,
});

export default rootReducer;
