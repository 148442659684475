import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import renderHTML from 'react-render-html';
import Page from '../../../../commonComponents/Page';
import Throbber from '../../../../commonComponents/Throbber';
import Button from '../../../../commonComponents/Button';
import Icon from '../../../../commonComponents/Icon';
import { history } from '../../../../store';
import { getSingleNews } from '../../actions';
import { getDateString } from '../../../../utils';

import './assets/styles/styles.scss';

export default class SingleNews extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        newsId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.newsId = props.match.params.newsId;

    this.state = {
      newsFetching: false,
      news: null,
      error: null,
    };
  }

  componentDidMount() {
    if (document) {
      document.title = 'Новости';
    }

    this.getNews();
  }

  getNews = () => {
    this.setState({
      newsFetching: true,
    });
    getSingleNews(this.newsId)
      .then(news => this.setState({ news, newsFetching: false }))
      .catch(error => this.setState({ error, newsFetching: false }));
  }

  getBackButton = () => {
    if (history.action === 'PUSH') {
      return (
        <p>
          <Button onClick={history.goBack} type="inline">
            <Icon name="chevron-left" />
            {' '}
            Назад
          </Button>
        </p>
      );
    }

    return null;
  }

  render() {
    if (!this.newsId || this.state.error) {
      return <Redirect to="/" />;
    }

    if (this.state.newsFetching) {
      return <Throbber inPanel />;
    }

    if (!this.state.news) {
      return null;
    }

    return (
      <div>
        {this.getBackButton()}
        <Page>
          <Page.SubHeading>{getDateString(this.state.news.startDate)}</Page.SubHeading>
          <Page.Heading>{this.state.news.title}</Page.Heading>
          <Page.Content>
            {renderHTML(this.state.news.content)}
          </Page.Content>
        </Page>
      </div>
    );
  }
}
