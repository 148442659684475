import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getGlobalExchangeRates } from '../../actions';
import './assets/styles/styles.scss';
import Throbber from '../../../../commonComponents/Throbber';

const groupRates = (ratesList) => {
  const rates = {
    USD: null,
    EUR: null,
  };

  ratesList.forEach((item) => {
    if (item.currencyName === 'cbUSD') {
      rates.USD = item;
    }

    if (item.currencyName === 'cbEUR') {
      rates.EUR = item;
    }
  });

  return rates;
};

class ExchangeRates extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    ratesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  componentDidMount() {
    this.props.dispatch(getGlobalExchangeRates());
  }

  render() {
    if (this.props.loading && !this.props.ratesList.length) {
      return <Throbber inline />;
    }

    const rates = groupRates(this.props.ratesList);

    return (
      <table className="global-exchange-rates">
        <thead>
          <tr>
            <td />
            <td className="global-exchange-rates__cell global-exchange-rates__cell--head">ЦБ РФ</td>
            {/*
              <td className="global-exchange-rates__cell global-exchange-rates__cell--head">
                М-Фонд - Покупка
              </td>
            */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="global-exchange-rates__cell global-exchange-rates__cell--head">$</td>
            {rates.USD && (
              <td className="global-exchange-rates__cell global-exchange-rates__cell--value">
                {typeof rates.USD.value === 'number' &&
                  rates.USD.value.toLocaleString('ru-RU', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}{' '}
                <sup>руб.</sup>
              </td>
            )}
            {/*
              <td className="global-exchange-rates__cell global-exchange-rates__cell--value">
                <span className="global-exchange-rates__link">курс ММВБ</span>
              </td>
            */}
          </tr>
          <tr>
            <td className="global-exchange-rates__cell global-exchange-rates__cell--head">€</td>
            {rates.EUR && (
              <td className="global-exchange-rates__cell global-exchange-rates__cell--value">
                {typeof rates.EUR.value === 'number' &&
                  rates.EUR.value.toLocaleString('ru-RU', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}{' '}
                <sup>руб.</sup>
              </td>
            )}
            {/*
              <td className="global-exchange-rates__cell global-exchange-rates__cell--value">
                <span className="global-exchange-rates__link">курс ММВБ</span>
              </td>
            */}
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = state => ({
  ratesList: state.appReducer.globalExchangeRates,
  loading: state.appReducer.globalExchangeRatesFetching,
});

export default connect(mapStateToProps)(ExchangeRates);
