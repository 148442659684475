import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';

const Pagination = (props) => {
  const pages = [];

  for (let i = 0; i < Math.ceil(props.totalCount / props.limit); i += 1) {
    pages.push(i + 1);
  }

  if (pages.length < 2) {
    return null;
  }
  return (
    <div className={cx('pagination', { [props.className]: props.className })}>
      {pages.map(item => (
        <button
          onClick={() => {
            // TODO: changePage - deprecated;
            if (props.changePage) {
              console.warn('Prop changePage id deprecated');
              props.changePage(item);
            }
            props.onChangePage(item);
          }}
          className={cx(
            'inline-button',
            'pagination__link',
            {
              'pagination__link--current': props.current && props.current === item,
            },
          )}
          key={item}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Pagination.defaultProps = {
  className: null,
};

export default Pagination;
