import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import renderHTML from 'react-render-html';
import Page from '../../../../commonComponents/Page';
import Throbber from '../../../../commonComponents/Throbber';
import Button from '../../../../commonComponents/Button';
import Icon from '../../../../commonComponents/Icon';
import { getNotice } from '../../actions';
import { getDateString } from '../../../../utils';

class SingleNotice extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        noticeId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    notice: PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  static defaultProps = {
    notice: null,
    error: null,
  }

  componentDidMount() {
    if (document) {
      document.title = 'Уведомления';
    }

    const { noticeId } = this.props.match.params;
    this.props.dispatch(getNotice(noticeId));
  }

  getBackButton = () => (
    <p>
      <Button onClick={() => this.props.dispatch(goBack())} type="inline">
        <Icon name="chevron-left" />
        {' '}
        Назад
      </Button>
    </p>
  );

  render() {
    if (this.props.fetching) {
      return <Throbber inPanel />;
    }

    return (
      <div>
        {this.getBackButton()}
        {this.props.error && (
          <Page>
            <Page.Heading>Произошла ошибка</Page.Heading>
            <Page.SubHeading>Попробуйте обновить страницу</Page.SubHeading>
            <Page.Content>{this.props.error}</Page.Content>
          </Page>
        )}
        {this.props.notice && (
          <Page>
            <Page.SubHeading>{getDateString(this.props.notice.date)}</Page.SubHeading>
            <Page.Heading>{this.props.notice.title}</Page.Heading>
            <Page.Content>
              {renderHTML(this.props.notice.content)}
            </Page.Content>
          </Page>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notice: state.noticesReducer.notice,
  fetching: state.noticesReducer.noticeFetching,
  error: state.noticesReducer.singleNoticeError,
});

export default connect(mapStateToProps)(SingleNotice);
