import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from '../../../../commonComponents/DataTable';
import AccountActions from '../../components/Actions';
import { getMoneyString } from '../../../../utils';
import './assets/styles/styles.scss';

const CurrentTable = props => (
  <div>
    <DataTable>
      <DataTable.Head>
        <DataTable.Row>
          <DataTable.Cell className="current-table__cell current-table__cell--title">
            Наименовая счета
          </DataTable.Cell>
          <DataTable.Cell className="current-table__cell current-table__cell--balance">
            Баланс счета
          </DataTable.Cell>
          <DataTable.Cell className="current-table__cell current-table__cell--actions">
            Операции
          </DataTable.Cell>
        </DataTable.Row>
      </DataTable.Head>
      <DataTable.Body>
        {props.accounts.map(item => (
          <DataTable.Row key={item.id}>
            <DataTable.Cell className="current-table__cell current-table__cell--title">
              {item.title}
            </DataTable.Cell>
            <DataTable.Cell className="current-table__cell current-table__cell--balance">
              {getMoneyString(item.balance, item.currency)}
            </DataTable.Cell>
            <DataTable.Cell className="current-table__cell current-table__cell--actions">
              <AccountActions>
                {/* item.currency !== 'RUB' && <AccountActions.CurrencyTransfer accountId={item.id} /> */}
                <AccountActions.LinkToTransfer accountType="salary" accountId={item.id} />
                <AccountActions.LinkToDetails
                  accountId={item.id}
                  dataType="current"
                  dateOfCreate={item.dateOfCreate}
                />
              </AccountActions>
            </DataTable.Cell>
          </DataTable.Row>
        ))}
      </DataTable.Body>
    </DataTable>
  </div>
);

CurrentTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    balance: PropTypes.number,
  })).isRequired,
};

const mapStateToProps = state => ({
  accounts: state.accountsReducer.accounts.current,
});

export default connect(mapStateToProps)(CurrentTable);
