import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './assets/styles/styles.scss';
import { logout } from '../../actions';


class UserData extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    number: PropTypes.string,
    name: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    hasNewNotice: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    className: null,
    number: null,
    name: null,
  }

  constructor(props) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);

    this.state = {
      open: false,
    };
  }

  toggleDropdown() {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  render() {
    if (!this.props.number) {
      return null;
    }
    return (
      <div className={cx('user', { [this.props.className]: this.props.className })}>
        <div className="user__data">
          <div className="user__name">{this.props.name}</div>
          <div className="user__number">Номер пайщика: {this.props.number}</div>
        </div>
        <div
          className={cx('user__button', { 'user__button--open': this.state.open })}
          onClick={this.toggleDropdown}
          onKeyPress={() => {}}
          role="presentation"
          tabIndex="-1"
        />
        <div
          className={cx('user__dropdown', { 'user__dropdown--open': this.state.open })}
        >
          <div className="user__menu">
            <NavLink className="user__link" to="/settings">Настройки</NavLink>
            <NavLink
              to="/notices/new"
              className={cx(
                'user__link user__link--notify',
                {
                  'user__link--has-new': this.props.hasNewNotice,
                },
              )}
            >
              Уведомления
            </NavLink>
            <a
              className="user__link user__link--logout"
              href="/"
              onClick={(event) => {
                event.preventDefault();
                this.props.dispatch(logout());
              }}
            >
              Выход
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  number: state.appReducer.user.number,
  name: state.appReducer.user.name,
  hasNewNotice: !!state.noticesReducer.noticesNew.length,
});

export default connect(mapStateToProps)(UserData);
