import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';


const Inner = ({ children, className }) => (
  <span
    className={cx(
      'block-like-input__inner',
      { [className]: className },
    )}
  >
    {children}
  </span>
);

const RegularText = ({ children, className }) => (
  <Inner className={cx('block-like-input__inner--regular', { [className]: className })}>
    {children}
  </Inner>
);

const BoldText = ({ children, className }) => (
  <Inner className={cx('block-like-input__inner--bold', { [className]: className })}>
    {children}
  </Inner>
);

const CustomText = ({ children, className }) => (
  <Inner className={cx({ [className]: className })}>
    {children}
  </Inner>
);

const innerPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
};

const innerDefaultProps = {
  children: null,
  className: null,
};

RegularText.propTypes = innerPropTypes;
BoldText.propTypes = innerPropTypes;
CustomText.propTypes = innerPropTypes;
Inner.propTypes = innerPropTypes;

RegularText.defaultProps = innerDefaultProps;
BoldText.defaultProps = innerDefaultProps;
CustomText.defaultProps = innerDefaultProps;
Inner.defaultProps = innerDefaultProps;


export default class BlockLikeInput extends React.Component {
  static RegularText = RegularText;
  static BoldText = BoldText;
  static CustomText = CustomText;

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.arrayOf(PropTypes.element),
    ]),
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    children: null,
  };

  render() {
    return (
      <div
        className={cx(
          'block-like-input',
          { [this.props.className]: this.props.className },
        )}
      >
        {this.props.children}
      </div>
    );
  }
}
