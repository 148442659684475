import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import './assets/styles/styles.scss';
import Throbber from '../Throbber';

class SelectBoxOption extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    option: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])).isRequired,
    isFocused: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {
    return (
      <div
        className={cx('selectbox__option', { 'selectbox__option--focused': this.props.isFocused })}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
        role="option"
        tabIndex="-1"
        aria-selected="true"
      >
        {this.props.children}
      </div>
    );
  }
}

const SelectBox = ({
  noResultsText, className, error, loading, disabled, ...props
}) => (
  <div className="selectbox__container">
    <Select
      className={cx('selectbox', {
        [className]: className,
        'selectbox--error': error,
      })}
      optionComponent={SelectBoxOption}
      noResultsText={noResultsText}
      disabled={disabled || loading}
      {...props}
    />
    {loading && (
      <div className="selectbox__throbber">
        <Throbber inline />
      </div>
    )}
  </div>
);

export default SelectBox;

SelectBox.propTypes = {
  className: PropTypes.string,
  noResultsText: PropTypes.string,
  error: PropTypes.bool,
};

SelectBox.defaultProps = {
  className: '',
  noResultsText: 'нет доступных счетов...',
  error: false,
};
