import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import './assets/styles/styles.scss';
import { MENU } from '../../../../constants';

/**
 * Получение массива элементов меню второго уровня из константной схемы меню
 * @param {string} path - текущий путь роутера
 */
const getSubmenus = (path) => {
  switch (path) {
    case '/accounts/:accountType':
      return MENU.accounts.childrens;

    case '/transfers/:transferType(to-salary|to-account|loan|deposit|to-partner)':
      return MENU.transfers.childrens;

    default:
      return null;
  }
};

/**
 * Компонент меню второго уровня
 */
const SubMenu = props => (
  <div className="submenu">
    {getSubmenus(props.match.path) &&
      getSubmenus(props.match.path).map(item => (
        <NavLink
          key={item.name}
          className="submenu__item"
          activeClassName="submenu__item--active"
          to={item.path}
        >
          {item.title}
        </NavLink>
      ))}
  </div>
);

export default withRouter(SubMenu);

SubMenu.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};
