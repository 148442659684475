const initialState = {
  fetching: false,
  transferCodeError: null,
  fetchError: null,
};

const transfersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'transfer/FETCH_START':
      return {
        ...state,
        fetching: true,
        fetchError: null,
      };

    case 'transfer/FETCH_STOP':
      return {
        ...state,
        fetching: false,
      };

    case 'transfer/FETCH_FAIL':
      return {
        ...state,
        fetchError: action.payload,
      };

    case 'RE_FETCH_CODE_FAIL':
    case 'TRANSFER_CONFIRM_CODE_FAIL':
      return {
        ...state,
        transferCodeError: action.payload,
      };

    case 'CONFIRM_CODE_SUCCESS':
    case 'RE_FETCH_CODE_SUCCESS':
    case 'CANCEL_TRANSFER':
      return initialState;

    default:
      return state;
  }
};

export default transfersReducer;
