import { push } from 'react-router-redux';
import { resultDataToCamelCase, getCookie } from '../../../utils';
import { BASE_API_URL, CONFIRM_TYPES } from '../../../constants';
import { fetchStart, fetchStop } from './';


export const setAccount = (id, field) => ({
  type: 'depositTransfer/SET_ACCOUNT',
  payload: {
    [`${field}AccountId`]: id,
  },
});

export const getAccounts = initialDepositId => (dispatch) => {
  const token = getCookie('access_token');
  if (!token) {
    return;
  }

  dispatch(fetchStart());

  fetch(`${BASE_API_URL}/transfer/deposit`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then((json) => {
      dispatch(fetchStop());
      dispatch({
        type: 'depositTransfer/ACCOUNTS_LOADED',
        payload: resultDataToCamelCase(json),
      });
      if (initialDepositId) {
        dispatch(setAccount(initialDepositId, 'deposit'));
      }
    })
    .catch((error) => {
      dispatch({
        type: 'depositTransfer/ACCOUNTS_LOADING_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const setTransferSum = sum => ({
  type: 'depositTransfer/SET_TRANSFER_SUM',
  payload: sum,
});

export const submitTransferForm = confirmMethod => (dispatch, getState) => {
  const {
    depositAccountId, debitAccountId, transferSum,
  } = getState().transfersReducerDeposit;
  const token = getCookie('access_token');

  if (!token || !CONFIRM_TYPES[confirmMethod]) {
    return;
  }

  const requestData = {
    CodeType: CONFIRM_TYPES[confirmMethod], // SMS
    FromBill: debitAccountId,
    ToBill: depositAccountId,
    Sum: transferSum,
  };
  dispatch(fetchStart());
  fetch(`${BASE_API_URL}/transfer/deposit/request-code`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (response.ok) {
        dispatch(fetchStop());
        dispatch(push('/transfers/confirm', {
          transferType: 'deposit',
          transferData: requestData,
        }));
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .catch((error) => {
      dispatch({
        type: 'depositTransfer/SUBMIT_FORM_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const getIncome = () => (dispatch, getState) => {
  const {
    depositAccountId, transferSum,
  } = getState().transfersReducerDeposit;
  const token = getCookie('access_token');

  if (!token) {
    return;
  }

  dispatch({
    type: 'depositTransfer/INCOME_LOAD_START',
  });
  fetch(`${BASE_API_URL}/transfer/deposit/income?id=${depositAccountId}&sum=${transferSum}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(json => dispatch({
      type: 'depositTransfer/INCOME_LOAD_SUCCESS',
      payload: resultDataToCamelCase(json).sum,
    }))
    .catch(error => dispatch({
      type: 'depositTransfer/INCOME_LOAD_FAIL',
      payload: error.message,
    }));
};
