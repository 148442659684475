import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { BASE_API_URL } from '../../../../constants';
import { getCookie } from '../../../../utils';
import './assets/styles/styles.scss';
import Icon from '../../../../commonComponents/Icon';

const LinkToTransfer = ({
  accountType,
  accountId,
  collapsed,
}) => (
  <NavLink
    className={cx(
      'account-action',
      { 'account-action--collapse': collapsed },
    )}
    key="transaction"
    to={{
      pathname: `/transfers/to-${accountType}`,
      state: {
        accountId,
      },
    }}
  >
    <span
      className="account-action__icon account-action__icon--transaction"
      title="Перевод на карту"
    />
    Перевод на карту
  </NavLink>
);

LinkToTransfer.propTypes = {
  accountType: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  collapsed: PropTypes.bool,
};

LinkToTransfer.defaultProps = {
  collapsed: false,
};

const LinkToDetails = props => (
  <NavLink
    className={cx(
      'account-action',
      { 'account-action--collapse': props.collapsed },
    )}
    to={{
      pathname: '/details',
      state: {
        dataType: props.dataType,
        accountId: props.accountId,
        dateOfCreate: props.dateOfCreate,
      },
    }}
  >
    <span
      className="account-action__icon account-action__icon--details"
      title="Выписки"
    />
    Выписки
  </NavLink>
);

LinkToDetails.propTypes = {
  dataType: PropTypes.string.isRequired,
  accountId: PropTypes.number.isRequired,
  dateOfCreate: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
};

LinkToDetails.defaultProps = {
  collapsed: false,
};

const Print = props => (
  <a
    className={cx(
      'account-action',
      { 'account-action--collapse': props.collapsed },
    )}
    href={`${BASE_API_URL}/accounts/${props.accountId}/details/print?token=${getCookie('access_token')}`}
    target="_blank"
  >
    <span
      className="account-action__icon account-action__icon--print"
      title="Печать выписки"
    />
    Печать выписки
  </a>
);

Print.propTypes = {
  accountId: PropTypes.number.isRequired,
  collapsed: PropTypes.bool,
};

Print.defaultProps = {
  collapsed: false,
};

const CurrencyTransfer = ({ collapsed, ...rest }) => (
  <NavLink
    className={cx(
      'account-action',
      { 'account-action--collapse': collapsed },
    )}
    to={{
      pathname: '/currency-exchange',
      state: rest,
    }}
    title="Конвертация валюты"
  >
    <span className="account-action__icon-wrapper">
      <Icon name="exchange-alt" />
    </span>
    {' '}
    Конвертация валюты
  </NavLink>
);

CurrencyTransfer.propTypes = {
  collapsed: PropTypes.bool,
};

CurrencyTransfer.defaultProps = {
  collapsed: false,
};

export default class AccountActions extends React.Component {
  static LinkToTransfer = LinkToTransfer;
  static LinkToDetails = LinkToDetails;
  static Print = Print;
  static CurrencyTransfer = CurrencyTransfer;

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  };

  static defaultProps = {
    children: null,
  };

  render() {
    return (
      <div className="account-actions">
        {this.props.children}
      </div>
    );
  }
}
