import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get, concat, take, orderBy, isEmpty } from 'lodash';

import Page from '../../../../commonComponents/Page';
import Throbber from '../../../../commonComponents/Throbber';
import Table from '../Table';
import { getCurrencyTitle } from '../../../../utils';
import { EMPTY_TABLE_TEXT } from '../../../../constants';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    Current: PropTypes.arrayOf(PropTypes.shape({})),
    Share: PropTypes.arrayOf(PropTypes.shape({})),
    Salary: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

const defaultProps = {
  data: {},
};

class AvailableFunds extends PureComponent {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  render() {
    const { data, loading } = this.props;
    if (loading) {
      return <Throbber inPanel/>;
    }
    const Current = get(data, 'Current', []);
    const Share = get(data, 'Share', []);
    const Salary = get(data, 'Salary', []);
    const result = take(orderBy(concat(Current, Share, Salary), ['Balance'], ['desc']), 3);
    return (
      <Page className="h-100">
        <Page.Heading>Доступные средства:</Page.Heading>
        <Page.Content className="table-no-padding">
          {!isEmpty(result) && <Fragment>
            <Table withBottomDevider>
              {result.map(item => (
                <Table.Row key={item.Id}>
                  <Table.Col>
                    {item.Title}
                    <Table.SubCol>Валюта: {getCurrencyTitle(item.Currency)}
                    </Table.SubCol>
                  </Table.Col>
                  <Table.Col>
                    {item.Balance.toLocaleString('ru-RU', {
                      style: 'currency',
                      currency: item.Currency,
                    })}
                  </Table.Col>
                </Table.Row>
              ))}
            </Table>
            <div className="p-3">
              <Link to="/accounts/current" className="link">Показать все счета   &gt; </Link>
            </div>
          </Fragment>}
          {isEmpty(result) && <div className="p-4">{EMPTY_TABLE_TEXT.availableFunds}</div>}
        </Page.Content>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  data: state.dashboardReducer.accounts.data,
  loading: state.dashboardReducer.accounts.loading,
});

export default connect(mapStateToProps)(AvailableFunds);
