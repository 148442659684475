import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';

const Wrapper = props => (
  <div className={cx('wrapper', props.className)}>
    {props.children}
  </div>
);

Wrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Wrapper.defaultProps = {
  className: null,
};

export default Wrapper;
