import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';

const Cell = ({
  children, className, parentType, colSpan,
}) => (
  <td
    colSpan={colSpan}
    className={cx('table__cell', {
      [`table__cell--${parentType}`]: parentType,
      [className]: className,
    })}
  >
    {children}
  </td>
);

Cell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  parentType: PropTypes.oneOf(['head', 'body']),
  colSpan: PropTypes.number,
};

Cell.defaultProps = {
  children: null,
  className: null,
  parentType: null,
  colSpan: 1,
};

const Row = ({ children, parentType }) => (
  <tr className={cx('table__row', { [`table__row--${parentType}`]: parentType })}>
    {React.Children.map(children, child => React.cloneElement(child, { parentType }))}
  </tr>
);

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  parentType: PropTypes.oneOf(['head', 'body']),
};

Row.defaultProps = {
  children: null,
  parentType: null,
};

const Head = ({ children }) => (
  <thead className="table__head">
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        parentType: 'head',
      }))}
  </thead>
);

Head.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

Head.defaultProps = {
  children: null,
};

const Body = ({ children, notFoundMessage, columnsCount }) => (
  <tbody className="table__body">
    {React.Children.count(children) === 0 &&
      notFoundMessage && (
        <Row>
          <Cell colSpan={columnsCount} className="table__cell--not-found">
            {notFoundMessage}
          </Cell>
        </Row>
      )}
    {React.Children.map(children, child => React.cloneElement(child, { parentType: 'body' }))}
  </tbody>
);

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  notFoundMessage: PropTypes.string,
  columnsCount: PropTypes.number,
};

Body.defaultProps = {
  children: null,
  notFoundMessage: null,
  columnsCount: 1,
};

/**
 * Компонент табличного представления данных, в зависимости от типа данных, выводящий разные колонки
 */
export default class DataTable extends React.Component {
  static Cell = Cell;
  static Row = Row;
  static Head = Head;
  static Body = Body;

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    className: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    className: null,
  };

  render() {
    const { children, className } = this.props;

    return <table className={cx('table', { [className]: className })}>{children}</table>;
  }
}
