import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import SuccessMessage from '../../components/SuccessMessage';
import './assets/styles/styles.scss';
import Typography from '../../../../commonComponents/Typography';

export default class TransferSuccess extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        transferType: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    location: {
      state: {},
    },
  };

  componentDidMount() {
    document.title = 'Платежи и переводы';
  }

  getHeading = () => {
    const transferType = this.props.location.state ? this.props.location.state.transferType : null;
    switch (transferType) {
      case 'salary':
        return 'Перевод средств на зарплатную карту';

      case 'inner':
        return 'Перевод между своими счетами';

      case 'loan':
        return 'Досрочное погашение займа';

      case 'deposit':
        return 'Пополнение вклада';

      case 'currency':
        return 'Конвертация валюты';

      case 'to-partner':
        return 'Перевод другому пайщику кооператива';

      default:
        return '---';
    }
  };

  render() {
    const locationState = this.props.location.state || null;
    if (!locationState) {
      return <Redirect to="/transfers" />;
    }
    const transferType = locationState ? locationState.transferType : null;

    if (!transferType) {
      return <Redirect to="/transfers" />;
    }

    return (
      <div className="transfers-page">
        <h1 key="heading" className="transfers-page__heading">
          {this.getHeading()}
        </h1>
        <div key="content" className="transfers-page__content">
          <SuccessMessage
            className="transfers-page__success-message"
            transferType={transferType}
            accountId={locationState.accountId}
          />
        </div>
        <div className="transfers-page__footer">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to="/accounts/current">
            <Typography.Link>На главную</Typography.Link>
          </Link>
        </div>
      </div>
    );
  }
}
