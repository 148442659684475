import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import SelectBox from '../../../../commonComponents/SelectBox';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import Button from '../../../../commonComponents/Button';
import Throbber from '../../../../commonComponents/Throbber';
import { FormControl } from '../../../../commonComponents/FormControl';
import { getCurrencyTitle, declOfNum } from '../../../../utils';
import { submitForm, sendRequest, resetFormData } from '../../actions/forms';


const CURRENCIES = [
  'RUR',
  'EUR',
  'USD',
];

const DEPOSIT_TERMS = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => ({
  value: item,
  label: `${item} ${declOfNum(item, ['месяц', 'месяца', 'месяцев'])}`,
}));

class DepositForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    income: PropTypes.number.isRequired,
    error: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      currency: 'RUR',
      term: 6,
      sum: 0,
    };
  }

  handleChangeCurrency = (value) => {
    this.setState({ currency: value });
    this.props.dispatch(resetFormData());
  };
  handleChangeSum = (value) => {
    this.setState({ sum: value });
    this.props.dispatch(resetFormData());
  };
  handleChangeTerm = (value) => {
    this.setState({ term: value });
    this.props.dispatch(resetFormData());
  };
  isSubmitDisabled = () => !this.state.sum || this.props.fetching;

  submitForm = () => {
    const requestData = {
      formType: 'deposit',
      currency: this.state.currency,
      term: this.state.term,
      sum: this.state.sum,
    };

    if (!this.props.income) {
      return this.props.dispatch(submitForm(requestData));
    }

    return this.props.dispatch(sendRequest(requestData));
  };

  render() {
    return (
      <div className="application-form">
        <FormLine>
          <FormLineColumn size="8">
            <FormControl
              type="money"
              name="sum"
              id="sum"
              label="Сумма размещения"
              value={this.state.sum}
              onChange={this.handleChangeSum}
            />
          </FormLineColumn>
          <FormLineColumn size="4">
            <div className="form-control">
              <p className="form-control__label">Валюта</p>
              <SelectBox
                className="transfer-form__accounts-select"
                options={CURRENCIES.map(item => ({
                  value: item,
                  label: getCurrencyTitle(item),
                }))}
                name="currency"
                clearable={false}
                searchable={false}
                onChange={({ value }) => this.handleChangeCurrency(value)}
                value={this.state.currency}
                placeholder="Выберите валюту"
              />
            </div>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <div className="form-control">
              <FormControl.FormLabel>
                Срок размещения
              </FormControl.FormLabel>
              <SelectBox
                className="transfer-form__accounts-select"
                options={DEPOSIT_TERMS}
                name="term"
                clearable={false}
                searchable={false}
                onChange={({ value }) => this.handleChangeTerm(value)}
                value={this.state.term}
                placeholder="Выберите срок"
              />
            </div>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>
              Доход за период
            </FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>
                {
                  this.props.fetching ?
                    <Throbber inline /> :
                    this.props.income
                }
              </BlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          {this.props.error && (
            <FormLineColumn size="12">
              <p className="form-control__error">{this.props.error}</p>
            </FormLineColumn>
          )}
          <FormLineColumn size="12">
            <Button
              type="primary"
              onClick={this.submitForm}
              disabled={this.isSubmitDisabled()}
              block
            >
              {this.props.income ? 'Отправить заявку' : 'Рассчитать'}
            </Button>
          </FormLineColumn>
        </FormLine>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  income: state.applicationsFormsReducer.depositIncome,
  error: state.applicationsFormsReducer.depositIncomeError ||
    state.applicationsFormsReducer.requestError,
  fetching: state.applicationsFormsReducer.fetching,
});

export default connect(mapStateToProps)(DepositForm);
