import { push } from 'react-router-redux';
import { BASE_API_URL } from '../../../constants';
import {
  resultDataToCamelCase,
  setCookie,
  getCookie,
} from '../../../utils';
import { getNotices } from '../../Notices/actions';
import * as UserApi from '../api/UserApi.ts';

const modulueBaseApi = `${BASE_API_URL}/user`;

const authSuccess = isTempPassword => ({
  type: 'AUTH_SUCCESS',
  payload: isTempPassword,
});

export const authFail = error => (dispatch) => {
  dispatch({
    type: 'AUTH_FAIL',
    payload: error,
  });
};

export const fetchStart = () => ({
  type: 'FETCH_START',
});

export const fetchStop = () => ({
  type: 'FETCH_STOP',
});

export const logout = () => (dispatch) => {
  const token = getCookie('access_token');
  fetch(`${modulueBaseApi}/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  setCookie('access_token', '', {
    expires: -1,
    path: '/',
  });
  setCookie('refresh_token', '', {
    expires: -1,
    path: '/',
  });
  dispatch({
    type: 'LOGOUT',
  });
  dispatch(push('/'));
};

export const extendSession = () => (dispatch) => {
  UserApi.refreshToken()
    .then((tokenSet) => {
      setCookie('access_token', tokenSet.access_token, {
        expires: tokenSet.expires_in,
        path: '/',
      });
      setCookie('refresh_token', tokenSet.refresh_token, {
        path: '/',
      });
      dispatch({
        type: 'SESSION_WAS_EXTENDED',
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch(logout());
    });
};

export const getUser = () => async (dispatch, getState, promiseHelper) => {
  const token = getCookie('access_token');

  if (!token || getState().userFetching) {
    return;
  }

  dispatch({
    type: 'USER_FETCH_START',
  });
  const request = fetch(modulueBaseApi, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!promiseHelper) {
    return;
  }

  promiseHelper.request(request, 'getUser', 3000).then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }).then((json) => {
    dispatch({
      type: 'USER_FETCH_SUCCESS',
      payload: resultDataToCamelCase(json),
    });
    dispatch(extendSession());
    dispatch(getNotices(
      'desc',
      'new',
      {
        page: 1,
        limit: 10,
      },
    ));
  }).catch(() => {});
};

export const login = loginData => (dispatch) => {
  if (loginData.login.length === 0 || loginData.password.length === 0) {
    return;
  }
  dispatch(fetchStart());
  fetch(`${modulueBaseApi}/authorize`, {
    method: 'POST',
    body: JSON.stringify({
      Login: loginData.login,
      Password: loginData.password,
    }),
    headers: {
      'content-type': 'application/json',
    },
  }).then((response) => {
    dispatch(fetchStop());
    if (response.ok) {
      return response.json();
    }
    /** Если не ОК, рэйзим ошибку из ответа с сервака */
    return response.json().then((json) => {
      throw new Error(resultDataToCamelCase(json).message);
    });
  }).then((json) => {
    setCookie('access_token', json.access_token, {
      expires: json.expires_in,
      path: '/',
    });
    setCookie('refresh_token', json.refresh_token, {
      path: '/',
    });
    const { isTempPassword } = json;
    dispatch(authSuccess(isTempPassword));
    if (isTempPassword) {
      dispatch(push('/change-password'));
    }
  }).catch(error => dispatch(authFail(error)));
};

export const sessionWasExpired = () => ({
  type: 'SESSION_WAS_EXPIRED',
});

export const userSettingsFail = data => ({
  type: 'USER_SETTINGS_FAIL',
  payload: data,
});

export const changeUserSettings = data => (dispatch) => {
  const userData = data;
  const token = getCookie('access_token');
  dispatch({
    type: 'USER_SETTINGS_NOT_SAVED',
  });

  fetch(`${modulueBaseApi}`, {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    /** Если не ОК, рэйзим ошибку из ответа с сервака */
    return response.json().then((json) => {
      throw new Error(resultDataToCamelCase(json).message);
    });
  }).then((json) => {
    dispatch({
      type: 'USER_FETCH_SUCCESS',
      payload: resultDataToCamelCase(json),
    });
    dispatch({
      type: 'USER_SETTINGS_SAVED',
    });
  }).catch(error => dispatch(userSettingsFail(error.message)));
};

export const changeUserAccountSettings = data => (dispatch) => {
  const userData = data;
  const token = getCookie('access_token');

  fetch(`${modulueBaseApi}/auth`, {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return dispatch({
        type: 'CHANGED_USER_ACCOUNT_SETTINGS',
      });
    }

    return response.json().then(json => dispatch(userSettingsFail(json)));
  }).catch(error => dispatch(userSettingsFail(error.message)));
};

export const showRecoveryForm = () => (dispatch) => {
  dispatch({
    type: 'SHOW_RECOVERY_FORM',
  });
  dispatch(push('/password-recovery'));
};

const recoveryPasswordErrorHandler = error => ({
  type: 'RECOVERY_PASSWORD_ERROR',
  payload: error,
});

const recoveryCodeErrorHandler = error => ({
  type: 'RECOVERY_CODE_ERROR',
  payload: error,
});

export const recoveryPasswordStep1 = data => (dispatch) => {
  const recoveryData = {
    recoveryInfo: data.user,
    type: data.recoveryMethod,
  };
  dispatch({
    type: 'RESET_ERRORS',
  });
  dispatch(fetchStart());
  fetch(`${modulueBaseApi}/recoveryrequest`, {
    method: 'POST',
    body: JSON.stringify(recoveryData),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    dispatch(fetchStop());
    if (response.ok) {
      if (data.recoveryMethod === '2') {
        return dispatch({
          type: 'RECOVERY_PASSWORD_BY_SMS',
        });
      }
      return dispatch({
        type: 'RECOVERY_SUCCESS',
      });
    }
    return response.json().then((json) => {
      throw new Error(resultDataToCamelCase(json).message);
    });
  }).catch(error => dispatch(recoveryPasswordErrorHandler(error.message)));
};

export const recoveryBackToStep1 = () => ({
  type: 'RECOVERY_BACK_TO_STEP_1',
});

export const confirmRecoveryCode = (code, user) => (dispatch) => {
  dispatch(fetchStart());
  dispatch({
    type: 'RESET_ERRORS',
  });
  fetch(`${modulueBaseApi}/recover`, {
    method: 'POST',
    body: JSON.stringify({
      recoveryInfo: user,
      code,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    dispatch(fetchStop());
    if (response.ok) {
      return dispatch({
        type: 'RECOVERY_SUCCESS',
      });
    }
    return response.json().then((json) => {
      throw new Error(resultDataToCamelCase(json).message);
    });
  }).catch(error => dispatch(recoveryCodeErrorHandler(error.message)));
};

export const recoveryDone = () => (dispatch) => {
  dispatch(push('/'));
  dispatch({
    type: 'RECOVERY_DONE',
  });
};

const changeTempPasswordErrorHandler = error => ({
  type: 'CHANGE_TEMP_PASSWORD_ERROR',
  payload: error,
});

export const changeTempPassword = data => (dispatch) => {
  const requestData = {
    login: data.login,
    new: data.password,
    confirm: data.passwordConfirm,
  };
  const token = getCookie('access_token');

  dispatch(fetchStart());
  fetch(`${modulueBaseApi}/first-change`, {
    method: 'POST',
    body: JSON.stringify(requestData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    dispatch(fetchStop());
    if (response.ok) {
      return dispatch({
        type: 'CHANGE_TEMP_PASSWORD',
      });
    }
    return response.json().then((json) => {
      dispatch(changeTempPasswordErrorHandler(json));
    });
  });
};

export const getGlobalExchangeRates = () => async (dispatch, getState, promiseHelper) => {
  const token = getCookie('access_token');
  const rates = getState().appReducer.globalExchangeRates;

  if (!token || rates.length) {
    return;
  }

  const request = await fetch(`${BASE_API_URL}/currency`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  promiseHelper.cancel('changeRate');
  dispatch({
    type: 'GLOBAL_EXCHANGE_RATE_FETCH_START',
  });

  promiseHelper
    .request(request, 'GlobalExchangeRates', 300)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(json => dispatch({
      type: 'GLOBAL_EXCHANGE_RATE_FETCH_SUCCESS',
      payload: resultDataToCamelCase(json).currenciesList,
    }))
    .catch(error => dispatch({
      type: 'GLOBAL_EXCHANGE_RATE_FETCH_FAIL',
      payload: error.message,
    }));
};

export const checkLimitInfo = () => async (dispatch, getState, promiseHelper) => {
  const token = getCookie('access_token');

  if (!token || getState().userFetching) {
    return;
  }

  const request = fetch(`${modulueBaseApi}/check-limit-info`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!promiseHelper) {
    return;
  }

  promiseHelper.request(request, 'checkLimitInfo', 3000).then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }).then((json) => {
    dispatch({
      type: 'CHECK_LIMIT_INFO_SUCCESS',
      payload: json,
    });
  }).catch(() => {});
};

export const acceptLimitInfo = () => async (dispatch, getState, promiseHelper) => {
  const token = getCookie('access_token');

  if (!token || getState().userFetching) {
    return;
  }

  const request = fetch(`${modulueBaseApi}/accept-limit-info`, {
    method: 'POST',
    body: JSON.stringify({
      IsAccepted: true,
      AcceptedFrom: 1,
    }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!promiseHelper) {
    return;
  }

  promiseHelper.request(request, 'acceptLimitInfo', 3000).then((response) => {
    if (response.ok) {
      dispatch({
        type: 'CHECK_LIMIT_INFO_SUCCESS',
        payload: {
          IsAccepted: true,
        },
      });
      return response.json();
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }).then((json) => {
    dispatch({
      type: 'CHECK_LIMIT_INFO_SUCCESS',
      payload: {
        IsAccepted: true,
      },
    });
  }).catch(() => {});
};

export const getCounties = () => async (dispatch, getState, promiseHelper) => {
  const request = fetch(`${BASE_API_URL}/region/countries`, {
    method: 'GET',
  });

  if (!promiseHelper) {
    return;
  }

  promiseHelper.request(request, 'getCounties', 3000).then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }).then((json) => {
    dispatch({
      type: 'GET_COUNTRIES_SUCCESS',
      payload: json.map((country) => ({
        id: country.Value,
        name: country.Text,
        regions: [],
        iso2: country.CountryCode ? country.CountryCode.toLowerCase() : '',
        dialCode: country.PhoneCode,
      })),
    });
  }).catch(() => {});
};
