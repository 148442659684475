import { push } from 'react-router-redux';
import { BASE_API_URL } from '../../../constants';
import { getCookie, resultDataToCamelCase } from '../../../utils';

const fetchStart = () => ({
  type: 'applications/FETCH_START',
});

const fetchStop = () => ({
  type: 'applications/FETCH_STOP',
});

export const submitForm = ({
  formType, sum, currency, term,
}) => (dispatch) => {
  const token = getCookie('access_token');

  if (!token || !sum || !currency || !term || !formType) {
    return;
  }

  const requestData = {
    Sum: sum,
    Currency: currency,
    Period: formType === 'deposit' ? term * 30 : term,
  };
  const requestUrl = `${BASE_API_URL}/requests/${formType}`;

  dispatch(fetchStart());
  fetch(requestUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then((json) => {
      if (formType === 'deposit') {
        dispatch({
          type: 'applications/DEPOSIT_INCOME_LOAD_SUCCESS',
          payload: json,
        });
      }

      if (formType === 'loan') {
        dispatch({
          type: 'applications/SCHEDULE_LOAD_SUCCESS',
          payload: resultDataToCamelCase(json),
        });
      }
      dispatch(fetchStop());
    })
    .catch((error) => {
      if (formType === 'deposit') {
        dispatch({
          type: 'applications/DEPOSIT_INCOME_LOAD_FAIL',
          payload: error.message,
        });
      }

      if (formType === 'loan') {
        dispatch({
          type: 'applications/SCHEDULE_LOAD_FAIL',
          payload: error.message,
        });
      }
      dispatch(fetchStop());
    });
};

export const sendRequest = ({
  formType, sum, currency, term,
}) => (dispatch) => {
  const token = getCookie('access_token');

  if (!token || !sum || !currency || !term || !formType) {
    return;
  }

  const requestData = {
    Sum: sum,
    Currency: currency,
    Period: formType === 'deposit' ? term * 30 : term,
    BillType: formType === 'deposit' ? 'Deposit' : 'Loan',
  };
  const requestUrl = `${BASE_API_URL}/requests/sendrequest`;

  dispatch(fetchStart());
  fetch(requestUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (response.ok) {
        return Promise.resolve();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(() => {
      dispatch(push('/applications/request'));
      dispatch(fetchStop());
    })
    .catch((error) => {
      dispatch({
        type: 'applications/REQUEST_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const resetFormData = () => ({
  type: 'applications/RESET_FORM_DATA',
});
