const initialState = {
  accounts: [],
  depositAccountId: null,
  debitAccountId: null,
  accountsLoadingError: null,
  transferFormError: null,
  transferSum: 0,
  incomeAfterRefill: null,
  incomeFetching: false,
  incomeError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'depositTransfer/ACCOUNTS_LOADED':
      return {
        ...initialState,
        accounts: action.payload,
        transferFormError: initialState.transferFormError,
      };

    case 'depositTransfer/ACCOUNTS_LOADING_FAIL':
      return {
        ...initialState,
        transferFormError: action.payload,
      };

    case 'depositTransfer/SET_ACCOUNT':
      return {
        ...state,
        transferFormError: initialState.transferFormError,
        transferSum: initialState.transferSum,
        incomeFetching: initialState.incomeFetching,
        incomeAfterRefill: initialState.incomeAfterRefill,
        incomeError: initialState.incomeError,
        ...action.payload,
      };

    case 'depositTransfer/SET_TRANSFER_SUM':
      return {
        ...state,
        transferFormError: initialState.transferFormError,
        transferSum: action.payload,
      };

    case 'depositTransfer/SUBMIT_FORM_FAIL':
      return {
        ...state,
        transferFormError: action.payload,
      };

    case 'depositTransfer/INCOME_LOAD_START':
      return {
        ...state,
        incomeFetching: true,
        incomeError: initialState.incomeError,
      };

    case 'depositTransfer/INCOME_LOAD_SUCCESS':
      return {
        ...state,
        incomeFetching: false,
        incomeAfterRefill: action.payload,
        incomeError: initialState.incomeError,
      };

    case 'depositTransfer/INCOME_LOAD_FAIL':
      return {
        ...state,
        incomeFetching: false,
        incomeAfterRefill: initialState.incomeAfterRefill,
        incomeError: action.payload,
      };

    case 'CANCEL_TRANSFER':
      return initialState;

    default:
      return state;
  }
};
