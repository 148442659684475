import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap-grid.min.css';

import Throbber from '../../../../commonComponents/Throbber';
import AvailableFunds from '../../components/AvailableFunds';
import SavingAccounts from '../../components/SavingAccounts';
import FastOperations from '../../components/FastOperations';
import LoanBalance from '../../components/LoanBalance';
import LastOperations from '../../components/LastOperations';
import { getAccounts, getLastOperations } from '../../actions';


export default class Dashboard extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
  };

  componentDidMount() {
    this.fetchAccaunts();
    this.fetchLastOperations();
  }

  getUserName() {
    const { user: { name } } = this.props;
    return `Добро пожаловать, ${name}!`;
  }

  fetchAccaunts() {
    this.props.dispatch(getAccounts());
  }

  fetchLastOperations() {
    this.props.dispatch(getLastOperations(6));
  }

  render() {
    const { loading, history } = this.props;
    if (loading) {
      return <Throbber inline />;
    }
    return (
      <div>
        <h1 className="row m-3">{this.getUserName()}</h1>
        <div className="row">
          <div className="col-9">
            <div className="row mb-4">
              <div className="col-6">
                <AvailableFunds />
              </div>
              <div className="col-6">
                <SavingAccounts />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <FastOperations />
              </div>
              <div className="col-8">
                <LoanBalance history={history} />
              </div>
            </div>
          </div>
          <div className="col-3">
            <LastOperations />
          </div>
        </div>

      </div>
    );
  }
}
