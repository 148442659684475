import React from 'react';
import PropTypes from 'prop-types';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import { FormControl, Radio } from '../../../../commonComponents/FormControl';

const ConfirmMethodControls = ({ changeHandler, selectedMethod }) => (
  <FormLine>
    <FormLineColumn size="12">
      <FormControl.FormLabel>Выберите способ потверждения</FormControl.FormLabel>
      <Radio
        id="confirm-sms"
        field="confirmMethod"
        onChange={changeHandler}
        value="sms"
        checked={selectedMethod === 'sms'}
      >
        Выслать код в SMS
      </Radio>
      <Radio
        id="confirm-email"
        field="confirmMethod"
        onChange={changeHandler}
        value="email"
        checked={selectedMethod === 'email'}
      >
        Выслать код на e-mail
      </Radio>
      <Radio
        id="confirm-push"
        field="confirmMethod"
        onChange={changeHandler}
        value="push"
        checked={selectedMethod === 'push'}
      >
        PUSH сообщение
      </Radio>
    </FormLineColumn>
  </FormLine>
);

ConfirmMethodControls.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  selectedMethod: PropTypes.oneOf([
    'sms',
    'email',
  ]).isRequired,
};

export default ConfirmMethodControls;
