import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Wrapper from '../../../../commonComponents/Wrapper';
import Submenu from '../../components/SubMenu';
import SessionModal from '../../components/SessionModal';
import Chat from '../../../Chat/containers/Chat';
import NotifyString from '../../../Notices/components/NotifyString';
import { checkAuth, getCookie } from '../../../../utils';

class Main extends React.Component {
  static propTypes = {
    appActions: PropTypes.objectOf(PropTypes.func).isRequired,
    children: PropTypes.element.isRequired,
    hasNewNotice: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.isAliveToken = this.isAliveToken.bind(this);
  }

  componentDidMount() {
    checkAuth(this.isAliveToken);
  }

  isAliveToken() {
    const token = getCookie('access_token');
    if (!token) {
      this.props.appActions.sessionWasExpired();
      return false;
    }
    return true;
  }

  render() {
    return [
      this.props.hasNewNotice ? <NotifyString key="notify" /> : null,
      <Header key="header" />,
      <Wrapper className="wrapper--main" key="wrapper">
        <Submenu currentPath="props.location.pathname" />
        {this.props.children}
      </Wrapper>,
      <Footer key="footer" />,
      <SessionModal key="sessionModal" />,
      <Chat key="chat" />,
    ];
  }
}

const mapStateToProps = state => ({
  userFetching: state.appReducer.userFetching,
  user: state.appReducer.user,
  userError: state.appReducer.userError,
  location: state.router.location,
  hasNewNotice: !!state.noticesReducer.noticesNew.length,
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions, push }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Main);
