import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import * as accountsActions from '../../actions';
import './assets/styles/styles.scss';
import AccountFilters from '../../components/AccountFilters';
import Table from '../../../../commonComponents/Table';
import SummaryBlock from '../../../../commonComponents/SummaryBlock';
import Pagination from '../../../../commonComponents/Pagination';
import { ACCOUNT_SUMMARY_COLUMNS, CONDITIONS_COLUMNS, DETAILS_TABS } from '../../utils';
import { getCookie } from '../../../../utils';
import Throbber from '../../../../commonComponents/Throbber';
import { PAGINATION_LIMIT, BASE_API_URL } from '../../../../constants';
import OperationsTable from './operationsTable';


class Details extends React.Component {
  static propTypes = {
    accountsActions: PropTypes.objectOf(PropTypes.func).isRequired,
    currentAccountType: PropTypes.string,
    currentAccountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    account: PropTypes.shape({
      id: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }),
    operations: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      sum: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
    })).isRequired,
    balance: PropTypes.objectOf(PropTypes.number),
    shedule: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string.isRequired,
      sum: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
    })).isRequired,
    operationsDateStart: PropTypes.instanceOf(moment),
    operationsDateEnd: PropTypes.instanceOf(moment),
    loading: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        accountType: PropTypes.string,
        accountId: PropTypes.number,
        dateOfCreate: PropTypes.string,
      }),
    }).isRequired,
    accounts: PropTypes.shape({
      deposit: PropTypes.arrayOf(PropTypes.shape({
        productTypeId: PropTypes.string,
      })),
    }),
  };

  static defaultProps = {
    currentAccountType: null,
    currentAccountId: null,
    account: null,
    balance: null,
    operationsDateStart: null,
    operationsDateEnd: null,
    accounts: {
      deposit: [],
    },
  };

  constructor(props) {
    super(props);

    this.tabChange = this.tabChange.bind(this);
    this.getDetailsTab = this.getDetailsTab.bind(this);
    this.getOperations = this.getOperations.bind(this);
    this.getSchedule = this.getSchedule.bind(this);
    this.getConditions = this.getConditions.bind(this);

    this.tabs = {
      operations: this.getOperations,
      schedule: this.getSchedule,
      conditions: this.getConditions,
    };

    this.state = {
      currentTab: 'operations',
      page: 1,
      shedulePage: 1,
    };
  }

  componentDidMount() {
    document.title = 'Детализация';
    const locationState = this.props.location.state;

    if (locationState && locationState.dataType && locationState.accountId) {
      this.props.accountsActions.setAccountFilters({
        type: locationState.dataType,
        accountId: locationState.accountId,
        dateStart: moment(locationState.dateOfCreate),
        dateEnd: moment(),
        minDate: moment(locationState.dateOfCreate),
        maxDate: moment(),
      });
    } else {
      this.props.accountsActions.setAccountFilters({
        type: 'current',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading) {
      return;
    }

    if (
      this.props.currentAccountId !== prevProps.currentAccountId ||
      this.props.currentAccountType !== prevProps.currentAccountType
    ) {
      this.getData();
    }

    if (
      this.props.operationsDateStart !== prevProps.operationsDateStart ||
      this.props.operationsDateEnd !== prevProps.operationsDateEnd
    ) {
      this.props.accountsActions.getOperations(this.props.currentAccountId);
    }
  }

  componentWillUnmount() {
    this.props.accountsActions.resetDetailsAndFilters();
  }

  getDetailsTab() {
    return this.tabs[this.state.currentTab]();
  }

  getDetailsTabsControls() {
    const tabs = DETAILS_TABS[this.props.currentAccountType];
    try {
      if (tabs.length > 1) {
        return (
          <div className="detail-controls">
            {tabs.map(tab => (
              <label
                htmlFor={`detailsTab${tab.name}`}
                key={tab.name}
                className="detail-controls__item"
              >
                <input
                  id={`detailsTab${tab.name}`}
                  type="radio"
                  checked={this.state.currentTab === tab.name}
                  value={tab.name}
                  name="detailsTab"
                  onChange={this.tabChange}
                  className="detail-controls__input"
                />
                <span className="detail-controls__label">{tab.title}</span>
              </label>
            ))}
          </div>
        );
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  getData = () => {
    if (this.props.loading) {
      return;
    }

    this.props.accountsActions.getDetails(
      this.isPensionAccount() ? 'pension' : this.props.currentAccountType,
      this.props.currentAccountId,
    );

    this.props.accountsActions.getBalanceDetails(this.props.currentAccountId);

    if (this.props.currentAccountType === 'loan') {
      this.props.accountsActions.getShedule(this.props.currentAccountId);
    }
  };

  getOperations() {
    const isLoan = this.props.currentAccountType === 'loan';
    return [
      <OperationsTable
        key="list"
        data={this.props.operations.slice(
          (this.state.page - 1) * PAGINATION_LIMIT,
          PAGINATION_LIMIT * this.state.page,
        )}
        currency={this.props.account ? this.props.account.currency : null}
        isLoan={isLoan}
      />,
      <Pagination
        key="pagination"
        items={this.props.operations}
        totalCount={this.props.operations.length}
        current={this.state.page}
        limit={PAGINATION_LIMIT}
        onChangePage={page =>
          this.setState({
            page,
          })
        }
      />,
    ];
  }

  getSchedule() {
    return [
      <Table
        key="list"
        type="shedule"
        data={{
          shedule: this.props.shedule
            .map(item => ({
              ...item,
              currency: this.props.account.currency,
              id: item.balance * item.sum * (moment(item.date).valueOf() / 100000),
            }))
            .slice((this.state.page - 1) * PAGINATION_LIMIT, PAGINATION_LIMIT * this.state.page),
        }}
      />,
      <Pagination
        key="pagination"
        items={this.props.shedule}
        totalCount={this.props.shedule.length}
        current={this.state.shedulePage}
        limit={PAGINATION_LIMIT}
        onChangePage={page =>
          this.setState({
            shedulePage: page,
          })
        }
      />,
    ];
  }

  getConditions() {
    const type = this.isPensionAccount() ? 'pension' : this.props.currentAccountType;
    if (CONDITIONS_COLUMNS[type]) {
      return (
        <SummaryBlock
          columns={CONDITIONS_COLUMNS[type]}
          data={{
            ...this.props.account,
            ...this.props.balance,
            accountType: type,
          }}
        />
      );
    }
    return null;
  }

  getAccountSummaryColumns() {
    const type = this.props.currentAccountType;
    const columns = ACCOUNT_SUMMARY_COLUMNS[type];
    return columns || [];
  }

  tabChange(event) {
    this.setState({
      currentTab: event.target.value,
    });
  }

  isPensionAccount = () => {
    let isPensionAccount = false;
    if (this.props.currentAccountType === 'deposit' && this.props.accounts.deposit.length > 0) {
      const account = this.props.accounts.deposit.find(({ id }) => id === this.props.currentAccountId);

      if (account) {
        isPensionAccount = account.productTypeId === '900' || account.productTypeId === '950';
      }
    }

    return isPensionAccount;
  };

  render() {
    return (
      <div className="details">
        <AccountFilters />
        {this.props.loading ? (
          <Throbber inPanel />
        ) : (
          <SummaryBlock
            columns={this.getAccountSummaryColumns()}
            data={{
              ...this.props.account,
              ...this.props.balance,
              accountType: this.props.currentAccountType,
            }}
          />
        )}
        {this.props.currentAccountType &&
          this.props.account && (
            <p className="details__links">
              <a
                className="inline-button details__print"
                href={`${BASE_API_URL}/accounts/${
                  this.props.account.id
                }/details/print?token=${getCookie('access_token')}&dateFrom=${this.props.operationsDateStart.format('YYYY-MM-DD')}&dateTo=${this.props.operationsDateEnd.format('YYYY-MM-DD')}`}
                target="_blank"
              >
                <span className="details__icon details__icon--print" title="Печать выписки" />
                Печать выписки за выбранный период
              </a>
            </p>
          )}
        {this.props.currentAccountType && this.props.account && this.getDetailsTabsControls()}
        <div>{this.props.operations && this.props.account && this.getDetailsTab()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.accountsReducer.accounts,
  account: state.accountsReducer.details.account,
  balance: state.accountsReducer.details.balance,
  currentAccountId: state.accountsReducer.accountsFilters.accountId,
  currentAccountType: state.accountsReducer.accountsFilters.type,
  operations: state.accountsReducer.details.operations,
  shedule: state.accountsReducer.details.shedule,
  operationsDateStart: state.accountsReducer.accountsFilters.dateStart,
  operationsDateEnd: state.accountsReducer.accountsFilters.dateEnd,
  loading: state.appReducer.fetching,
  location: state.router.location,
});

const mapDispatchToProps = dispatch => ({
  accountsActions: bindActionCreators(accountsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Details));
