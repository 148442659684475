import React, { Component, Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './assets/styles/styles.scss';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.arrayOf(PropTypes.element),
]);

const propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes,
  withBottomDevider: PropTypes.bool,
};

const defaultProps = {
  className: null,
  children: null,
  withBottomDevider: false,
};

const rowPropTypes = {
  children: childrenPropTypes,
  key: PropTypes.string,
  reverse: PropTypes.bool,
  white: PropTypes.bool,
};

const rowDefaultProps = {
  key: null,
  children: null,
  reverse: false,
  white: false,
};

// eslint-disable-next-line react/prefer-stateless-function
class Row extends Component {
  static propTypes = rowPropTypes;
  static defaultProps = rowDefaultProps;

  render() {
    const { key, children, reverse, white } = this.props;
    return (
      <tr key={key} className={cn('table-dashboard__row', { 'table-dashboard__row--body': !reverse && !white }, { 'table-dashboard__row--body_reverse': reverse && !white })}>
        {children}
      </tr>
    );
  }
}

const colPropTypes = {
  children: childrenPropTypes,
  key: PropTypes.string,
  className: PropTypes.string,
};

const colDefaultProps = {
  key: null,
  children: null,
  className: null,
};

// eslint-disable-next-line react/prefer-stateless-function,react/no-multi-comp
class Col extends Component {
  static propTypes = colPropTypes;
  static defaultProps = colDefaultProps;

  render() {
    const { key, children, className } = this.props;
    return (
      <td
        key={key}
        className={cn(
          'table-dashboard__cell',
          'table-dashboard__cell--body',
          className,
        )}
      >
        {children}
      </td>
    );
  }
}

const subColPropTypes = {
  children: childrenPropTypes,
  key: PropTypes.string,
  className: PropTypes.string,
};

const subColDefaultProps = {
  key: null,
  children: null,
  className: null,
};

// eslint-disable-next-line react/prefer-stateless-function,react/no-multi-comp
class SubCol extends Component {
  static propTypes = subColPropTypes;
  static defaultProps = subColDefaultProps;

  render() {
    const { key, children, className } = this.props;
    return (
      <div
        key={key}
        className={cn('table-dashboard__sub-col', className)}
      >
        {children}
      </div>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
export default class Table extends Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  static Row = Row;
  static Col = Col;
  static SubCol = SubCol;

  render() {
    const { children, className, withBottomDevider } = this.props;
    return (
      <Fragment>
        <table key="table" className={cn('table-dashboard', className)}>
          <tbody className="table-dashboard__body">
            {children}
          </tbody>
        </table>
        {withBottomDevider && <div className="px-3"><div className="table-dashboard__devider" /></div> }
      </Fragment>
    );
  }
}
