import React from 'react';
import cx from 'classnames';
import AccountSettingsForm from '../../components/AccountSettingsForm';
import UserSettingsForm from '../../components/UserSettingsForm';
import './assets/styles/styles.scss';

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.tabChangeHandler = this.tabChangeHandler.bind(this);

    this.state = {
      activeTab: 'user',
    };
  }

  componentDidMount() {
    document.title = 'Личные данные';
  }

  getForm() {
    if (this.state.activeTab === 'user') {
      return <UserSettingsForm />;
    }
    return <AccountSettingsForm />;
  }

  tabChangeHandler(event) {
    this.setState({
      activeTab: event.target.value,
    });
  }

  render() {
    return (
      <div>
        <div className="settings__tab-controls">
          <label
            htmlFor="userSettings"
            className={cx('settings__tab-button', { 'settings__tab-button--active': this.state.activeTab === 'user' })}
          >
            <input
              id="userSettings"
              type="radio"
              name="activeTab"
              value="user"
              onChange={this.tabChangeHandler}
              checked={this.state.activeTab === 'user'}
            />
            Личные данные
          </label>
          <label
            htmlFor="loginSettings"
            className={cx('settings__tab-button', { 'settings__tab-button--active': this.state.activeTab === 'account' })}
          >
            <input
              id="loginSettings"
              type="radio"
              name="activeTab"
              value="account"
              onChange={this.tabChangeHandler}
              checked={this.state.activeTab === 'account'}
            />
            Настройки аккаунта
          </label>
        </div>
        <div className="settings__container">
          {this.getForm()}
        </div>
      </div>
    );
  }
}
