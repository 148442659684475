const initialState = {
  depositIncome: 0,
  depositIncomeError: null,
  loanShedule: [],
  loanSheduleError: null,
  loanMonthlyPayment: 0,
  fetching: false,
  requestError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'applications/FETCH_START':
      return {
        ...state,
        fetching: true,
        depositIncomeError: initialState.depositIncomeError,
        loanSheduleError: initialState.loanSheduleError,
      };

    case 'applications/FETCH_STOP':
      return {
        ...state,
        fetching: false,
      };

    case 'applications/DEPOSIT_INCOME_LOAD_SUCCESS':
      return {
        ...state,
        depositIncome: action.payload,
        depositIncomeError: initialState.depositIncomeError,
        requestError: initialState.requestError,
      };

    case 'applications/DEPOSIT_INCOME_LOAD_FAIL':
      return {
        ...state,
        depositIncomeError: action.payload,
        depositIncome: 0,
      };

    case 'applications/SCHEDULE_LOAD_SUCCESS': {
      let loanMonthlyPayment;
      if (action.payload.billLoanPlanCalendarPeriods[1]) {
        loanMonthlyPayment = action.payload.billLoanPlanCalendarPeriods[1].monthlyPayment || 0;
      } else {
        loanMonthlyPayment = 0;
      }


      return {
        ...state,
        loanShedule: action.payload.billLoanPlanCalendarPeriods.map(item => ({
          ...item,
          sum: item.monthlyPayment,
          date: item.startDate,
        })),
        loanMonthlyPayment,
        loanSheduleError: initialState.loanSheduleError,
        requestError: initialState.requestError,
      };
    }

    case 'applications/SCHEDULE_LOAD_FAIL':
      return {
        ...state,
        loanShedule: initialState.loanShedule,
        loanMonthlyPayment: initialState.loanMonthlyPayment,
        loanSheduleError: action.payload,
      };

    case 'applications/REQUEST_FAIL':
      return {
        ...state,
        requestError: action.payload,
      };

    case 'applications/RESET_FORM_DATA':
      return initialState;

    default:
      return state;
  }
};
