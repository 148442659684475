import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRequest, clearRequestData } from '../../actions';
import Throbber from '../../../../commonComponents/Throbber';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import { FormControl } from '../../../../commonComponents/FormControl';
import './assets/styles/styles.scss';

class SingleRequest extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    propsFromModal: PropTypes.shape({
      requestId: PropTypes.number,
    }),
    data: PropTypes.shape({
      id: PropTypes.number,
      partnerName: PropTypes.string,
      address: PropTypes.string,
      billCode: PropTypes.string,
      payer: PropTypes.string,
      payerBill: PropTypes.string,
      payerBank: PropTypes.string,
      paymentDestination: PropTypes.string,
      receiver: PropTypes.string,
      receiverBill: PropTypes.string,
      receiverBank: PropTypes.string,
      details: PropTypes.string,
    }),
  };

  static defaultProps = {
    propsFromModal: null,
    data: null,
  };

  componentDidMount() {
    if (this.props.propsFromModal && this.props.propsFromModal.requestId) {
      const id = this.props.propsFromModal.requestId;

      this.props.dispatch(getRequest(id));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearRequestData());
  }

  render() {
    const { data } = this.props;
    if (this.props.fetching) {
      return <Throbber inPanel />;
    }

    if (!this.props.data) {
      return <div>no data</div>;
    }

    return (
      <div className="single-request">
        <FormLine>
          <FormLineColumn size="3">
            <FormControl.FormLabel>Клиент</FormControl.FormLabel>
            <br />
            <strong>{data.partnerName}</strong>
            <br />
            <small>{data.address}</small>
          </FormLineColumn>
          <FormLineColumn size="4">
            <FormControl.FormLabel>Счет клиента</FormControl.FormLabel>
            <br />
            <strong>{data.billCode}</strong>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="3">
            <FormControl.FormLabel>Плательщик</FormControl.FormLabel>
            <br />
            <strong>{data.payer}</strong>
          </FormLineColumn>
          <FormLineColumn size="4">
            <FormControl.FormLabel>Счет плательщика</FormControl.FormLabel>
            <br />
            <strong>{data.payerBill}</strong>
            <br />
          </FormLineColumn>
          <FormLineColumn size="2">
            <FormControl.FormLabel>Банк плательщика</FormControl.FormLabel>
            <br />
            <strong>{data.payerBank}</strong>
          </FormLineColumn>
          {data.details &&
            <FormLineColumn size="3">
              <FormControl.FormLabel>Назначение платежа</FormControl.FormLabel>
              <br />
              <strong>{data.details}</strong>
            </FormLineColumn>
          }
        </FormLine>
        <FormLine>
          <FormLineColumn size="3">
            <FormControl.FormLabel>Получатель</FormControl.FormLabel>
            <br />
            <strong>{data.receiver}</strong>
          </FormLineColumn>
          <FormLineColumn size="4">
            <FormControl.FormLabel>Счет получателя</FormControl.FormLabel>
            <br />
            <strong>{data.receiverBill}</strong>
            <br />
          </FormLineColumn>
          <FormLineColumn size="2">
            <FormControl.FormLabel>Банк получателя</FormControl.FormLabel>
            <br />
            <strong>{data.receiverBank}</strong>
          </FormLineColumn>
        </FormLine>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.applicationsReducer.requestFetching,
  data: state.applicationsReducer.requestData,
});

export default connect(mapStateToProps)(SingleRequest);
