import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import LinesEllipsis from 'react-lines-ellipsis';

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import { NavLink } from 'react-router-dom';
import { getNews } from '../../actions';
import './assets/styles/styles.scss';
import Icon from '../../../../commonComponents/Icon';
import Throbber from '../../../../commonComponents/Throbber';

const ArrowPrev = props => (
  <div {...props} className="slider-arrow slider-arrow--prev">
    <Icon name="chevron-left" />
  </div>
);

const ArrowNext = props => (
  <div {...props} className="slider-arrow slider-arrow--next">
    <Icon name="chevron-right" />
  </div>
);

class NewsContainer extends React.Component {
  static propTypes = {
    news: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      preview: PropTypes.string,
    })).isRequired,
    newsFetching: PropTypes.bool.isRequired,
    newsFetchingError: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    newsFetchingError: null,
  };

  componentDidMount() {
    this.props.dispatch(getNews());
  }

  sliderRef = (element) => {
    this.slider = element;
    this.forceUpdate();
  }

  render() {
    if (this.props.newsFetching && !this.props.news.length) {
      return <Throbber inline />;
    }

    if (this.props.newsFetchingError || !this.props.news.length) {
      return <div />;
    }

    return (
      <div className="news-container">
        <span className="news-container__heading">
          <Icon name="rss" /> Новости
        </span>
        <span className="news-container__devider" />
        {this.slider && (
          <Slider
            dots
            arrows
            asNavFor={this.slider}
            className="news-container__controls"
            dotsClass="news-container__dots"
            appendDots={dots => <div>{dots}</div>}
            customPaging={i => <button className="news-container__dot">{i + 1}</button>}
            nextArrow={<ArrowNext />}
            prevArrow={<ArrowPrev />}
          >
            {this.props.news.map(item => <div key={item.id} />)}
          </Slider>
        )}
        <span className="news-container__devider" />
        <Slider
          ref={this.sliderRef}
          className="news-container__slider"
          arrows={false}
          infinite
          slidesToScroll={1}
          slidesToShow={1}
          speed={500}
        >
          {this.props.news.map(item => (
            <NavLink to={`/news/${item.id}`} className="news-container__item" key={item.id}>
              <LinesEllipsis
                text={item.preview}
                maxLine={3}
              />
            </NavLink>
          ))}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  news: state.newsReducer.news,
  newsFetching: state.newsReducer.newsFetching,
  newsFetchingError: state.newsReducer.newsFetchingError,
});

export default connect(mapStateToProps)(NewsContainer);
