import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import './assets/styles/styles.scss';


const Link = ({
  children, className, activeClassName, to,
}) => (
  <NavLink
    className={cx(
      'link',
      { [className]: className },
    )}
    activeClassName={activeClassName}
    to={to}
  >
    {children}
  </NavLink>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

Link.defaultProps = {
  className: null,
  activeClassName: null,
};

export default Link;
