import { getCookie, resultDataToCamelCase } from '../../../utils';
import PromiseHelper from '../../../utils/PromiseHelper';
import { BASE_API_URL } from '../../../constants';

const promiseHelper = new PromiseHelper();

export interface TokenSet {
  access_token: string;
  expires_in: string;
  refresh_token: string;
}

export interface DefaultResponseWithError {
  message?: string;
}

export async function refreshToken() {
  const token = getCookie('refresh_token');

  if (!token) {
    throw Error('refreshToken is required');
  }

  const request = () => fetch(`${BASE_API_URL}/user/token?refreshToken=${token}`);

  try {
    const response = await promiseHelper.request(request(), 'refreshToken', 1000);
    const json: TokenSet | DefaultResponseWithError = resultDataToCamelCase(await response.json());
    if (response.ok) {
      return Promise.resolve(json as TokenSet);
    }

    return Promise.reject(json.message);
  } catch (error) {
    Promise.reject(error);
  }
}
