import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get, take, orderBy, isEmpty } from 'lodash';
import moment from 'moment';

import Page from '../../../../commonComponents/Page';
import Table from '../Table';
import Throbber from '../../../../commonComponents/Throbber';
import { EMPTY_TABLE_TEXT } from '../../../../constants';

const propTypes = {
  history: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    Loan: PropTypes.arrayOf(PropTypes.shape({
      Id: PropTypes.number,
      DateOfCreate: PropTypes.string,
      LoanSum: PropTypes.number,
      NextPaymentSum: PropTypes.number,
      NextPaymentDate: PropTypes.string,
      DateOfEnd: PropTypes.string,
      Currency: PropTypes.string,
      Balance: PropTypes.number,
    })),
  }),
};

const defaultProps = {
  data: {},
};

class LoanBalance extends PureComponent {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  payLoan = id => () => {
    const { history } = this.props;
    history.push('/transfers/loan', { accountId: id });
  };

  render() {
    const { data, loading } = this.props;
    if (loading) {
      return <Throbber inPanel />;
    }
    const Loan = take(orderBy(get(data, 'Loan', []), ['Balance'], ['desc']), 3);
    return (
      <Page className="h-100">
        <Page.Heading>Остаток долга по займам:</Page.Heading>
        <Page.Content className="table-no-padding">
          {!isEmpty(Loan) && <Fragment>
            <Table withBottomDevider>
              {Loan.map(item => (
                <Table.Row key={item.Id}>
                  <Table.Col>
                    {item.LoanSum.toLocaleString('ru-RU', {
                      style: 'currency',
                      currency: item.Currency,
                    })}
                    <Table.SubCol>Дата открытия: {moment(item.DateOfCreate)
                      .format('DD.MM.YYYY')}
                    </Table.SubCol>
                  </Table.Col>
                  <Table.Col>
                    {item.NextPaymentSum.toLocaleString('ru-RU', {
                      style: 'currency',
                      currency: item.Currency,
                    })}
                    <Table.SubCol>Очередной платеж: {moment(item.NextPaymentDate)
                      .format('DD.MM.YYYY')}
                    </Table.SubCol>
                  </Table.Col>
                  <Table.Col>
                    <span
                      className="table-dashboard__cell_red"
                    >
                      {item.Balance.toLocaleString('ru-RU', {
                        style: 'currency',
                        currency: item.Currency,
                      })}
                    </span>
                    <Table.SubCol>Дата закрытия: {moment(item.DateOfEnd)
                      .format('DD.MM.YYYY')}
                    </Table.SubCol>
                  </Table.Col>
                  <Table.Col>
                    <div className="link" role="link" onClick={this.payLoan(item.Id)}>Оплатить</div>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table>
            <div className="p-3">
              <Link to="/accounts/loan" className="link">Показать все займы   &gt; </Link>
            </div>
          </Fragment>}
          {isEmpty(Loan) && <div className="p-4">{EMPTY_TABLE_TEXT.loan}</div>}
        </Page.Content>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  data: state.dashboardReducer.accounts.data,
  loading: state.dashboardReducer.accounts.loading,
});

export default connect(mapStateToProps)(LoanBalance);
