import React from 'react';

const RequestSuccess = () => (
  <div className="transfers-page">
    <h1 key="heading" className="transfers-page__heading">
      Заявка успешно отправлена.
    </h1>
    <div key="content" className="transfers-page__content">
      <div className="success-message">
        <span className="success-message__icon" />
        <p className="success-message__text">
          Ваша заявка успешно отправлена. Сотрудники КПК &quot;Морской Фонд&quot; свяжутся с вами в
          течение 24х часов.
        </p>
      </div>
    </div>
  </div>
);

export default RequestSuccess;
