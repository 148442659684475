const initialState = {
  notices: [],
  noticesNew: [],
  sorting: 'desc',
  notice: null,
  count: 0,
  countNew: 0,
  singleNoticeError: null,
  noticeFetching: false,
  noticesFetching: false,
};

const noticesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NOTICES_FETCH_START':
      return {
        ...state,
        noticesFetching: true,
      };

    case 'NOTICES_FETCH_FAIL':
      return {
        ...state,
        noticesFetching: false,
      };

    case 'NOTICES_FETCH_SUCCESS':
      return {
        ...state,
        ...action.payload,
        noticesFetching: false,
      };

    case 'CHANGE_SORTING':
      return {
        ...state,
        sorting: state.sorting === 'asc' ? 'desc' : 'asc',
      };

    case 'GET_NOTICE_START':
      return {
        ...state,
        noticeFetching: true,
      };

    case 'GET_NOTICE_SUCCESS':
      return {
        ...state,
        notice: action.payload,
        singleNoticeError: initialState.singleNoticeError,
        noticeFetching: false,
      };

    case 'GET_NOTICE_FAIL':
      return {
        ...state,
        singleNoticeError: action.payload,
        noticeFetching: false,
      };

    case 'HIDE_NOTICE':
      return {
        ...state,
        noticesNew: state.noticesNew.filter(item => item.id !== action.payload),
      };

    default:
      return {
        ...state,
      };
  }
};

export default noticesReducer;
