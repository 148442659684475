import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl } from '../../../../commonComponents/FormControl';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import Button from '../../../../commonComponents/Button';
import { changeUserAccountSettings } from '../../../App/actions';
import './assets/styles/styles.scss';

class AccountSettingsForm extends React.Component {
  static propTypes = {
    settingsChanged: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
      login: PropTypes.string.isRequired,
    }).isRequired,
    settingsError: PropTypes.shape({
      ModelState: PropTypes.shape({
        'model.New': PropTypes.arrayOf(PropTypes.string),
        'model.Confirm': PropTypes.arrayOf(PropTypes.string),
        'model.Current': PropTypes.arrayOf(PropTypes.string),
        'model.Login': PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  };

  static defaultProps = {
    settingsError: null,
  };

  constructor(props) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);

    this.state = {
      accountLogin: props.user.login,
      accountPasswordNew: '',
      accountPasswordNewConfirm: '',
      accountPasswordOld: '',
      accountPasswordNewError: null,
      accountPasswordNewConfirmError: null,
      accountPasswordOldError: null,
      accountLoginError: null,
      formWasChanged: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.accountLogin && this.state.accountLogin !== nextProps.user.login) {
      this.setState({
        accountLogin: nextProps.user.login,
      });
    }

    if (JSON.stringify(this.props.settingsError) !== JSON.stringify(nextProps.settingsError)) {
      if (nextProps.settingsError && nextProps.settingsError.ModelState) {
        this.setState({
          accountPasswordNewError: nextProps.settingsError.ModelState['model.New'],
          accountPasswordNewConfirmError: nextProps.settingsError.ModelState['model.Confirm'],
          accountPasswordOldError: nextProps.settingsError.ModelState['model.Current'],
          accountLoginError: nextProps.settingsError.ModelState['model.Login'],
        });
      }

      if (!nextProps.settingsError) {
        this.setState({
          accountPasswordNewError: null,
          accountPasswordNewConfirmError: null,
          accountPasswordOldError: null,
          accountLoginError: null,
        });
      }
    }
  }

  formChangeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value,
      formWasChanged: true,
      accountPasswordNewError: null,
      accountPasswordNewConfirmError: null,
      accountPasswordOldError: null,
      accountLoginError: null,
    });
  }

  formHasError = () => !!(
    this.state.accountPasswordNewError ||
    this.state.accountPasswordNewConfirmError ||
    this.state.accountPasswordOldError ||
    this.state.accountLoginError
  );

  submitForm = (event) => {
    event.preventDefault();
    let hasError = false;

    const {
      accountPasswordNew,
      accountPasswordNewConfirm,
      accountPasswordOld,
      accountLogin,
    } = this.state;

    if (!accountPasswordOld) {
      this.setState({
        accountPasswordOldError: 'Поле не может быть пустым',
      });
      hasError = true;
    }

    if (!accountLogin) {
      this.setState({
        accountLoginError: 'Поле не может быть пустым',
      });
      hasError = true;
    }

    if (accountPasswordNew && !accountPasswordNewConfirm) {
      this.setState({
        accountPasswordNewConfirmError: 'Подтвердите новый пароль',
      });
      hasError = true;
    }

    if (accountPasswordNew && accountPasswordNew !== accountPasswordNewConfirm) {
      this.setState({
        accountPasswordNewConfirmError: 'Пароли не совпадают',
      });
      hasError = true;
    }

    if (accountPasswordOld && accountPasswordNew === accountPasswordOld) {
      this.setState({
        accountPasswordNewError: 'Пароль не должен совпадать с текущим',
      });
      hasError = true;
    }

    const requestData = {
      current: this.state.accountPasswordOld,
    };

    if (this.state.accountPasswordNew) {
      requestData.new = this.state.accountPasswordNew;
      requestData.confirm = this.state.accountPasswordNewConfirm;
    }

    if (this.state.accountLogin !== this.props.user.login) {
      requestData.login = this.state.accountLogin;
    }

    if (!hasError) {
      this.props.dispatch(changeUserAccountSettings(requestData));
    }
  };

  render() {
    return (
      <form onSubmit={this.submitForm} className="account-settings">
        <FormLine>
          <FormLineColumn size="6">
            <FormControl
              className="account-settings__input"
              type="text"
              label="Сменить логин на новый"
              name="accountLogin"
              id="accountLogin"
              value={this.state.accountLogin}
              onChange={this.formChangeHandler}
              error={
                Array.isArray(this.state.accountLoginError)
                  ? this.state.accountLoginError.join(' ')
                  : this.state.accountLoginError
              }
            />
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="6">
            <FormControl
              className="account-settings__input"
              type="password"
              label="Сменить пароль на новый"
              name="accountPasswordNew"
              id="accountPasswordNew"
              value={this.state.accountPasswordNew}
              onChange={this.formChangeHandler}
              error={
                Array.isArray(this.state.accountPasswordNewError)
                  ? this.state.accountPasswordNewError.join(' ')
                  : this.state.accountPasswordNewError
              }
            />
          </FormLineColumn>
          <FormLineColumn size="6">
            <FormControl
              className="account-settings__input"
              type="password"
              label="Подтвердите новый пароль"
              name="accountPasswordNewConfirm"
              id="accountPasswordNewConfirm"
              value={this.state.accountPasswordNewConfirm}
              onChange={this.formChangeHandler}
              required={this.state.accountPasswordNew}
              error={
                Array.isArray(this.state.accountPasswordNewConfirmError)
                  ? this.state.accountPasswordNewConfirmError.join(' ')
                  : this.state.accountPasswordNewConfirmError
              }
            />
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="6">
            <FormControl
              className="account-settings__input"
              type="password"
              label="Введите текущий пароль"
              name="accountPasswordOld"
              id="accountPasswordOld"
              value={this.state.accountPasswordOld}
              onChange={this.formChangeHandler}
              error={
                Array.isArray(this.state.accountPasswordOldError)
                  ? this.state.accountPasswordOldError.join(' ')
                  : this.state.accountPasswordOldError
              }
            />
          </FormLineColumn>
          <FormLineColumn size="6">
            <p className="account-settings__item--description">
              Для изменения логина или пароля вам нужно ввести текущий пароль
            </p>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <Button
              className="transfer-form__button"
              type="primary"
              disabled={!this.state.formWasChanged || this.formHasError()}
            >
              {this.props.fetching ? 'Подождите...' : 'Сохранить изменения'}
            </Button>
            {this.props.settingsChanged && (
              <div className="account-settings__item account-settings__item--description">
                Настройки изменены
              </div>
            )}
          </FormLineColumn>
        </FormLine>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  settingsChanged: state.appReducer.settingsChanged,
  user: state.appReducer.user,
  settingsError: state.appReducer.settingsError,
});

export default connect(mapStateToProps)(AccountSettingsForm);
