import { push } from 'react-router-redux';
import {
  resultDataToCamelCase,
  getCookie,
} from '../../../utils';
import { cancelTransfer } from './';
import { BASE_API_URL } from '../../../constants';

const fetchStart = () => ({
  type: 'TRANSFER_FETCH_START',
});

const fetchStop = () => ({
  type: 'TRANSFER_FETCH_START',
});

export const getAccounts = () => (dispatch) => {
  const token = getCookie('access_token');
  if (!token) {
    return;
  }

  dispatch(fetchStart());

  fetch(`${BASE_API_URL}/transfer/inner`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }).then((json) => {
    dispatch({
      type: 'innertTransfer/ACCOUNTS_LOADED',
      payload: resultDataToCamelCase(JSON.parse(json)),
    });
    dispatch(fetchStop());
  }).catch((error) => {
    dispatch({
      type: 'innertTransfer/ACCOUNTS_LOADED_FAIL',
      payload: error.message,
    });
    dispatch(fetchStop());
  });
};

export const changeAccount = (id, direction) => {
  if (direction === 'from') {
    return {
      type: 'innertTransfer/CHANGE_FROM_ACCOUNT',
      payload: id,
    };
  }

  if (direction === 'to') {
    return {
      type: 'innertTransfer/CHANGE_TO_ACCOUNT',
      payload: id,
    };
  }

  return {};
};

export const changeTransferSum = sum => ({
  type: 'innertTransfer/CHANGE_TRANSFER_SUM',
  payload: sum,
});

export const submitTransferForm = () => (dispatch, getState) => {
  const {
    accountFrom,
    accountTo,
    transferSum,
  } = getState().transfersReducerInner;
  const token = getCookie('access_token');

  if (!token) {
    return;
  }

  const requestData = {
    FromBill: accountFrom,
    ToBill: accountTo,
    Sum: transferSum,
  };

  dispatch(fetchStart());
  fetch(`${BASE_API_URL}/transfer/inner`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  }).then((response) => {
    if (response.ok) {
      dispatch(fetchStop());
      dispatch(cancelTransfer());
      dispatch(push('/transfers/success', {
        transferType: 'inner',
      }));
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }
    /** Если не ОК, рэйзим ошибку из ответа с сервака */
    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }).catch((error) => {
    console.warn(error);
    dispatch(fetchStop());
  });
};
