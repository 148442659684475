import React from 'react';
import PropTypes from 'prop-types';

const FormError = ({ children }) => {
  if (!children) {
    return null;
  }

  return <p className="form-control__error">{children}</p>;
};

FormError.propTypes = {
  children: PropTypes.node,
};

FormError.defaultProps = {
  children: null,
};

export default FormError;
