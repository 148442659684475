import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { get, isEmpty } from 'lodash';

import Page from '../../../../commonComponents/Page';
import Throbber from '../../../../commonComponents/Throbber';
import Table from '../Table';
import { EMPTY_TABLE_TEXT } from '../../../../constants';


const propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    Operations: PropTypes.arrayOf(PropTypes.shape({
      Id: PropTypes.number,
      Title: PropTypes.string,
      Date: PropTypes.string,
      Sum: PropTypes.number,
    })),
  }),
};

const defaultProps = {
  data: {},
};

class LastOperations extends PureComponent {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  render() {
    const { data, loading } = this.props;
    if (loading) {
      return <Throbber inPanel />;
    }
    const Operations = get(data, 'Operations', []);
    return (
      <Page className="h-100">
        <Page.Heading>Последние операции:</Page.Heading>
        <Page.Content className="table-no-padding">
          {!isEmpty(Operations) && <Fragment>
            <Table withBottomDevider>
              {Operations.map(item => (
                <Table.Row key={item.Id} reverse>
                  <Table.Col>
                    {item.Title}
                    <Table.SubCol>
                      {moment(item.Date).format('DD.MM.YYYY')}
                    </Table.SubCol>
                  </Table.Col>
                  <Table.Col className={`table-dashboard__cell_${item.Sum > 0 ? 'green' : 'red'}`}>
                    {item.Sum.toLocaleString('ru-RU', {
                      style: 'currency',
                      currency: item.Currency,
                    })}
                  </Table.Col>
                </Table.Row>
              ))}
            </Table>
            <div className="p-3">
              <Link to="/applications" className="link">Показать больше   &gt; </Link>
            </div>
          </Fragment>}
          {isEmpty(Operations) && <div className="p-4">{EMPTY_TABLE_TEXT.operations}</div>}
        </Page.Content>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  data: state.dashboardReducer.lastOperations.data,
  loading: state.dashboardReducer.lastOperations.loading,
});

export default connect(mapStateToProps)(LastOperations);
