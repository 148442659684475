import { BASE_API_URL } from '../../../constants';
import { resultDataToCamelCase, getCookie } from '../../../utils';

export const getNews = () => (dispatch) => {
  const token = getCookie('access_token');

  if (!token) {
    return;
  }

  dispatch({
    type: 'NEWS_FETCH_START',
  });

  fetch(`${BASE_API_URL}/news?ordering=desc&offset=0&limit=3`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(json => dispatch({
      type: 'NEWS_FETCH_SUCCESS',
      payload: resultDataToCamelCase(json),
    }))
    .catch(error => dispatch({
      type: 'NEWS_FETCH_FAIL',
      payload: error.message,
    }));
};

export const getSingleNews = newsId => new Promise((resolve, reject) => {
  const token = getCookie('access_token');

  if (!token || !newsId) {
    return;
  }

  fetch(`${BASE_API_URL}/news/${newsId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(json => resolve(resultDataToCamelCase(json)))
    .catch(error => reject(error.message));
});
