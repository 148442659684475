import moment from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../constants';

const pascalToCamelCase = string => string.charAt(0).toLowerCase() + string.slice(1);
const objectToCamelCase = (obj) => {
  const dataItem = {};
  Object.keys(obj).forEach((key) => {
    const property = pascalToCamelCase(key);
    const propertyValue = obj[key];

    if (Array.isArray(propertyValue)) {
      dataItem[property] = propertyValue.map(item => objectToCamelCase(item));
      return;
    }

    if (propertyValue && typeof propertyValue === 'object') {
      dataItem[property] = objectToCamelCase(propertyValue);
      return;
    }

    dataItem[property] = propertyValue;
  });
  return dataItem;
};
export const resultDataToCamelCase = (result) => {
  if (Array.isArray(result)) {
    return result.map(item => objectToCamelCase(item));
  }
  return objectToCamelCase(result);
};

export const setCookie = (name, value, options) => {
  const opts = options || {};
  const val = encodeURIComponent(value);
  let { expires } = opts;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires);
    expires = d;
  }
  if (expires && expires.toUTCString) {
    opts.expires = expires.toUTCString();
  }

  let updatedCookie = `${name}=${val}`;

  Object.keys(opts).forEach((item) => {
    updatedCookie += `; ${item}=${opts[item] !== true ? opts[item] : ''}`;
  });

  document.cookie = updatedCookie;
};

export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

/**
 * Формирование строки баланса
 * @param {number} balance - баланс счета
 * @param {string} currency - идентификатор валюты счета
 */
export const getMoneyString = (balance, currency = '') => {
  if (balance === undefined || balance === null) {
    return '---';
  }
  const clearCurrency = currency.replace(/"/g, '');
  switch (clearCurrency) {
    case '&#x20bd;':
    case 'RUB':
    case 'Рубли РФ':
      return `${balance.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}`;

    case '&#x20ac;':
    case 'EUR':
    case 'Евро':
      return `${balance.toLocaleString('ru-RU', { style: 'currency', currency: 'EUR' })}`;

    case '&#x0024;':
    case 'USD':
    case 'Доллары США':
      return `${balance.toLocaleString('ru-RU', { style: 'currency', currency: 'USD' })}`;

    default:
      return `${balance.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}`;
  }
};

export const getAccountLabel = account =>
  `"${account.title}", ${getMoneyString(account.balance, account.currency)}`;
export const getAccountLabelTransfer = account =>
  `"${account.accountName}", ${getMoneyString(
    account.sum || account.balance,
    account.currencyName,
  )}`;

export const checkAuth = (callback) => {
  let timer = null;
  const intreval = 1000 * 5;
  const tick = () => {
    if (callback()) {
      timer = setTimeout(tick, intreval);
    }
    timer = null;
  };
  timer = setTimeout(tick, intreval);
  return timer;
};

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;

export const setDocumentTitle = (path) => {
  switch (path) {
    case '/login':
      document.title = 'Личный кабинет пайщика';
      break;

    default:
      document.title = 'Etest';
      break;
  }
};

export const getDateString = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format(DATE_FORMAT);
  }

  return '---';
};

export const getDateTimeString = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format(DATE_TIME_FORMAT);
  }

  return '---';
};

/**
 * @param {number} number количество
 * @param {Array<string>} titles массив
 * существительных по склонениям
 * @example declOfNum(1, ['яблоко, яблока, яблок']) -> 'яблоко'
 * @example declOfNum(2, ['яблоко, яблока, яблок']) -> 'яблока'
 * @example declOfNum(5, ['яблоко, яблока, яблок']) -> 'яблок'
 */
export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const getCurrencyTitle = (currency) => {
  switch (currency) {
    case 'RUB':
    case 'RUR':
      return 'Рубль РФ';

    case 'EUR':
      return 'Евро';

    case 'USD':
      return 'Доллар США';

    default:
      return currency;
  }
};
