import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import Page from '../../../../commonComponents/Page';
import './assets/styles/styles.scss';
import { getDateString } from '../../../../utils';


const NoticesList = props => (
  <div className="notices-list">
    {props.data.length === 0 && (
      <Page>
        <Page.Content>
          <p className="notice-preview__not-found">У вас нет уведомлений</p>
        </Page.Content>
      </Page>
    )}
    {props.data.map(item => (
      <Page
        key={item.id}
        className={cx('notices-list__item', 'notice-preview', {
          'notice-preview--unread': item.isUnread,
        })}
      >
        <div className="notice-preview__header">
          <p className="notice-preview__title">
            <NavLink to={`/notices/${item.id}`} className="notice-preview__link">
              {item.title}
            </NavLink>
          </p>
          <p className="notice-preview__date">
            {getDateString(item.date)}
          </p>
        </div>
        <Page.Content className="notice-preview__body">
          <p className="notice-preview__preview">{item.preview}</p>
        </Page.Content>
      </Page>
    ))}
  </div>
);

NoticesList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    preview: PropTypes.string,
    date: PropTypes.string,
    isUnread: PropTypes.bool,
  })).isRequired,
};

export default NoticesList;
