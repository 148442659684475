import React from 'react';
import PropTypes from 'prop-types';
import './assets/styles/styles.scss';

const Link = ({ children }) => <span className="typography__link">{children}</span>;

Link.propTypes = {
  children: PropTypes.node,
};

Link.defaultProps = {
  children: null,
};

export default class Typography {
  static Link = Link;
}
