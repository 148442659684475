import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Draggable from 'react-draggable';
import './assets/styles/styles.scss';
import { getCookie } from '../../../../utils/index';

const getMessageKey = (messages) => {
  try {
    return `${Date.now() * Math.random()}_${messages.text.substring(0, 2)}`;
  } catch (e) {
    return `${Date.now() * Math.random()}_key`;
  }
};

const subscribeToSocket = (callbacks, _attempt) => {
  let attempt = _attempt;

  try {
    if (attempt > 3) {
      throw new Error('Не удалось подключиться к сокет-серверу');
    }

    const socket = new WebSocket('wss://online.m-fond.com/api/messages/ws/');
    // const socket = new WebSocket('ws://new_online.m-fond.com/api/messages/ws/');

    socket.onopen = callbacks.onopen;
    socket.onmessage = callbacks.onmessage;
    socket.onclose = (event) => {
      setTimeout(() => {
        callbacks.onclose(event);
        attempt += 1;
        subscribeToSocket(callbacks, attempt);
      }, 2000);
    };
    socket.onerror = (event) => {
      setTimeout(() => {
        callbacks.onerror(event);
        attempt += 1;
        subscribeToSocket(callbacks, attempt);
      }, 2000);
    };
    return socket;
  } catch (error) {
    return null;
  }
};


class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.changeHandler = this.changeHandler.bind(this);
    this.keypressHandler = this.keypressHandler.bind(this);
    this.collapsHandler = this.collapsHandler.bind(this);
    this.onmessageHandler = this.onmessageHandler.bind(this);

    this.state = {
      input: '',
      messages: [],
      collapsed: true,
      interlocutor: '',
    };

    this.socket = subscribeToSocket({
      onmessage: this.onmessageHandler,
      // onopen: (e) => { console.log(e); },
      onclose: (e) => { console.warn(e); },
      onerror: (e) => { console.warn(e); },
    }, 0);
  }

  componentDidMount() {
    this.token = getCookie('access_token');

    this.setChatOffset();
    if (window && document) {
      try {
        document.getElementById('root').addEventListener('resize', () => {
          this.setChatOffset();
        });
        window.addEventListener('resize', () => {
          this.setChatOffset();
        });
        window.addEventListener('scroll', () => {
          this.setChatOffset();
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.messages.length !== this.state.messages.length) {
      this.messagesContainer.scrollTop = 100000;
    }
  }

  onmessageHandler(event) {
    const response = JSON.parse(event.data);

    this.setState(prevState => ({
      messages: [
        ...prevState.messages,
        {
          author: response.Name,
          text: response.Message,
        },
      ],
      interlocutor: response.Name ? response.Name : prevState.interlocutor,
    }));
  }

  getStatusText() {
    if (this.state.interlocutor) {
      return 'онлайн';
    }
    return 'задать вопрос';
  }

  setChatOffset() {
    try {
      const scrollHeight = document.getElementById('root').offsetHeight - document.body.offsetHeight - window.scrollY;
      this.containerElement.style.setProperty('bottom', `${scrollHeight <= 50 ? 50 - scrollHeight : 0}px`);
    } catch (e) {
      console.warn(e);
    }
  }

  changeHandler(event) {
    this.setState({
      input: event.target.value,
    });
  }

  keypressHandler(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (!this.state.input) {
        return;
      }
      try {
        const requestData = JSON.stringify({
          Id: 0,
          Token: this.token,
          Message: this.state.input,
        });
        this.socket.send(requestData);
        this.setState({
          input: '',
        });
      } catch (error) {
        console.warn('send error', error);
      }
    }
  }

  collapsHandler() {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  }

  render() {
    return (
      <Draggable
        axis="x"
        handle=".chat__header"
        bounds="#root"
      >
        <div
          className={cx(
            'chat',
            this.state.interlocutor ? 'chat--online' : 'chat--offline',
            {
              'chat--collapsed': this.state.collapsed,
            },
          )}
          ref={(element) => { this.containerElement = element; }}
        >
          <div className="chat__header">
            <span className="chat__interlocutor">{this.state.interlocutor}</span>
            <span className="chat__status">{this.getStatusText()}</span>
            <button
              className="chat__collapse-button"
              onClick={this.collapsHandler}
            />
          </div>
          <div className="chat__body">
            <div ref={(element) => { this.messagesContainer = element; }} className="chat__messages">
              {this.state.messages.map(item => (
                <p
                  key={getMessageKey()}
                  className={cx(
                    'chat__message',
                    {
                      'chat__message--you': !item.author,
                    },
                  )}
                >
                  <span
                    className={cx(
                      'chat__author',
                      {
                        'chat__author--you': !item.author,
                      },
                    )}
                  >{item.author || 'Вы'}:
                  </span> {item.text}
                </p>
              ))}
            </div>
            <textarea
              rows="3"
              className="chat__input"
              onChange={this.changeHandler}
              onKeyDown={this.keypressHandler}
              value={this.state.input}
              placeholder="Введите ваше сообщение и нажмите кнопку Enter"
            />
          </div>
        </div>
      </Draggable>
    );
  }
}

export default connect()(Chat);
