import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Wrapper from '../../../../commonComponents/Wrapper';
import { noticeHide } from '../../actions';
import './assets/styles/styles.scss';

const NotifyString = (props) => {
  if (!props.notice) {
    return null;
  }

  const clickHandler = () => {
    props.dispatch(push(`/notices/${props.notice.id}`));
  };

  const closeHandler = () => {
    props.dispatch(noticeHide(props.notice.id));
  };

  return (
    <div className="notify-string">
      <Wrapper className="notify-string__wrapper">
        <button onClick={clickHandler} className="notify-string__title">
          <div className="notify-string__icon" />
          {props.notice.title}
        </button>
        <button onClick={closeHandler} className="notify-string__close" />
      </Wrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  notice: state.noticesReducer.noticesNew[0],
});

export default connect(mapStateToProps)(NotifyString);

NotifyString.propTypes = {
  dispatch: PropTypes.func.isRequired,
  notice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

NotifyString.defaultProps = {
  notice: null,
};
