import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FormLabel = ({ id, children, className }) => {
  if (!children) {
    return null;
  }

  let Node = 'span';
  const formLabelProps = {
    className: cx(
      'form-label',
      className && className,
    ),
  };

  if (id) {
    formLabelProps.htmlFor = id;
    Node = 'label';
  }

  return (
    <Node {...formLabelProps} >
      {children}
    </Node>
  );
};

FormLabel.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
};

FormLabel.defaultProps = {
  id: null,
  className: null,
  children: null,
};

export default FormLabel;
