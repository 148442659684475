import { BASE_API_URL, PAGINATION_LIMIT, ACCOUNT_TYPES_FOR_REQUESTS } from '../../../constants';
import { resultDataToCamelCase, getCookie } from '../../../utils';

const getQueryParams = ({
  page, status, dates, account,
}) => {
  const queryString = new URLSearchParams();
  queryString.append('fromDate', dates.startDate.format('DD.MM.YYYY'));
  queryString.append('toDate', dates.endDate.format('DD.MM.YYYY'));

  if (page) {
    queryString.append('offset', (page - 1) * PAGINATION_LIMIT);
  }

  if (PAGINATION_LIMIT) {
    queryString.append('limit', PAGINATION_LIMIT);
  }

  if (status > -1) {
    queryString.append('status', status);
  }

  if (account > -1) {
    queryString.append('billId', account);
  }

  return queryString;
};

const fetchStop = () => ({
  type: 'REQUESTS_FETCH_STOP',
});

export const getRequests = ({
  page = 1, status = -1, dates, account = -1,
} = {}) => (dispatch) => {
  dispatch({
    type: 'REQUESTS_FETCH_START',
  });

  const token = getCookie('access_token');
  const queryParams = getQueryParams({
    page,
    status,
    dates,
    account,
  });

  fetch(`${BASE_API_URL}/requests?${queryParams}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(resultDataToCamelCase(json).message);
      });
    })
    .then((json) => {
      const result = resultDataToCamelCase(json);

      dispatch({
        type: 'REQUESTS_FETCH_START_SUCCESS',
        payload: {
          applications: result.requests,
          count: result.count,
          currentPage: page,
        },
      });
      dispatch(fetchStop());
    })
    .catch((error) => {
      dispatch({
        type: 'REQUESTS_FETCH_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const getRequest = id => (dispatch) => {
  const token = getCookie('access_token');

  if (!token || !id) {
    return;
  }

  dispatch({
    type: 'REQUEST_FETCH_START',
  });

  fetch(`${BASE_API_URL}/requests/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(json =>
      dispatch({
        type: 'REQUEST_FETCH_SUCCESS',
        payload: resultDataToCamelCase(json),
      }))
    .catch(error =>
      dispatch({
        type: 'REQUEST_FETCH_FAIL',
        payload: error.message,
      }));
};

export const clearRequestData = () => ({
  type: 'CLEAR_REQUEST',
});

export const getAccounts = () => (dispatch) => {
  const token = getCookie('access_token');
  if (!token) {
    return;
  }

  dispatch({
    type: 'requestFilter/ACCOUNTS_FETCH_START',
  });

  const requests = ACCOUNT_TYPES_FOR_REQUESTS.map(item =>
    fetch(`${BASE_API_URL}/accounts/${item}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    }));

  Promise.all(requests)
    .then(json =>
      dispatch({
        type: 'requestFilter/ACCOUNTS_FETCH_SUCCESS',
        payload: resultDataToCamelCase(json.reduce((a, b) => a.concat(b))),
      }))
    .catch(error =>
      dispatch({
        type: 'requestFilter/ACCOUNTS_FETCH_FAIL',
        payload: error.message,
      }));
};

export const cancelRequest = id => (dispatch) => {
  const token = getCookie('access_token');

  if (!token || !id) {
    return;
  }

  dispatch({
    type: 'REQUEST_CANCEL_START',
  });

  fetch(`${BASE_API_URL}/transfer/cancel/${id}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(() => dispatch({ type: 'REQUEST_CANCEL_SUCCESS' }))
    .catch(() => dispatch({ type: 'REQUEST_CANCEL_FAIL' }));
};
