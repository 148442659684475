import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import Page from '../../../../commonComponents/Page';
import Table from '../Table';

import img from './assets/images/vektornyi_smart-obekt.jpg';
import img1 from './assets/images/vektornyi_smart-obekt_2.jpg';
import img2 from './assets/images/vektornyi_smart-obekt_3.jpg';
import img3 from './assets/images/vektornyi_smart-obekt_4.jpg';
import img4 from './assets/images/vektornyi_smart-obekt_5.jpg';


const data = [
  {
    id: 1,
    image: img,
    text: 'Перевод на зарплатную карту',
    link: '/transfers/to-salary',
  },
  {
    id: 2,
    image: img4,
    text: 'Перевод между счетами',
    link: '/transfers/to-account',
  },
  // {
  //   id: 3,
  //   image: img1,
  //   text: 'Перевод другому пайщику',
  //   link: '/transfers/to-partner',
  // },
  {
    id: 4,
    image: img2,
    text: 'Погашение займа',
    link: '/transfers/loan',
  },
  {
    id: 5,
    image: img3,
    text: 'Пополнение вклада',
    link: '/transfers/deposit',
  },
];

const propTypes = {
  data: PropTypes.shape({
    Loan: PropTypes.arrayOf(PropTypes.shape({})),
    Deposit: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

const defaultProps = {
  data: {},
};

class FastOperations extends PureComponent {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  checkDisabled = (text) => {
    const { accaunts } = this.props;

    switch (text) {
      case 'Погашение займа':
        return isEmpty(get(accaunts, 'Loan', []));
      case 'Пополнение вклада':
        return isEmpty(get(accaunts, 'Deposit', []));
      default:
        return false;
    }
  };

  preventClick = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <Page className="h-100">
        <Page.Heading>Быстрые операции:</Page.Heading>
        <Page.Content className="table-no-padding">
          <Table>
            {data.map(item => (
              <Table.Row key={item.id} white>
                <Table.Col>
                  <img src={item.image} alt={item.text} />
                </Table.Col>
                <Table.Col>
                  {this.checkDisabled(item.text) ? (
                    <Link className="link link__disabled" to={item.link} onClick={this.preventClick}>
                      {item.text}
                    </Link>
                  ) : (
                    <Link className="link" to={item.link}>
                      {item.text}
                    </Link>
                  )}

                </Table.Col>
              </Table.Row>
            ))}
          </Table>
        </Page.Content>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  accaunts: state.dashboardReducer.accounts.data,
  loading: state.dashboardReducer.accounts.loading,
});

export default connect(mapStateToProps)(FastOperations);
