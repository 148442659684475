import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectBox from '../../../../commonComponents/SelectBox';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import { FormControl } from '../../../../commonComponents/FormControl';
import Button from '../../../../commonComponents/Button';
import Throbber from '../../../../commonComponents/Throbber';
import ConfirmMethodControls from '../ConfirmMethodControls';
import {
  getAccounts,
  setAccount,
  setTransferSum,
  submitTransferForm,
  getIncome,
} from '../../actions/depositTransfer';
import { getMoneyString, getDateString, declOfNum } from '../../../../utils';
import { DEFAULT_STRING_VALUE } from '../../../../constants';

class TransferFormDeposit extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    depositAccountId: PropTypes.number,
    debitAccountId: PropTypes.number,
    transferSum: PropTypes.number.isRequired,
    fetching: PropTypes.bool.isRequired,
    transferFormError: PropTypes.string,
    incomeAfterRefill: PropTypes.number,
    incomeFetching: PropTypes.bool.isRequired,
    incomeError: PropTypes.string,
    initialAccount: PropTypes.number,
  };

  static defaultProps = {
    depositAccountId: null,
    debitAccountId: null,
    transferFormError: null,
    incomeAfterRefill: null,
    incomeError: null,
    initialAccount: null,
  };

  state = {
    time: null,
    closeDate: null,
    openingSum: null,
    income: null,
    currency: '',
    confirmMethod: 'sms',
    sumError: null,
    accountFromError: null,
    accountToError: null,
  };

  componentDidMount() {
    this.props.dispatch(getAccounts(this.props.initialAccount));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.depositAccountId !== nextProps.depositAccountId) {
      const depositAccount = nextProps.accounts.find(item => item.id === nextProps.depositAccountId);

      if (depositAccount) {
        this.setState({
          time: depositAccount.periods,
          closeDate: depositAccount.closeDate,
          openingSum: depositAccount.openingSum,
          income: depositAccount.income,
          currency: depositAccount.currencyName,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.transferSum !== this.props.transferSum && this.props.transferSum > 0) {
      clearTimeout(this.changeSummTimer);
      this.changeSummTimer = setTimeout(() => {
        this.props.dispatch(getIncome());
      }, 300);
    }

    if (!this.props.accounts.length && prevProps.accounts.length) {
      this.props.dispatch(getAccounts());
    }
  }

  getDepositLabel = accountData =>
    `${accountData.accountName} (баланс ${getMoneyString(
      accountData.sum,
      accountData.currencyName,
    )})`;

  getDebitAccounts = () => {
    const depositAccount = this.props.accounts.find(item => item.id === this.props.depositAccountId);

    if (depositAccount) {
      return depositAccount.availableAccounts.map(item => ({
        value: item.id,
        label: `"${item.accountName}", ${getMoneyString(item.sum, item.currencyName)}`,
      }));
    }
    return null;
  };

  getDeositTimeString = () => {
    const { time } = this.state;
    if (typeof time === 'number') {
      const days = declOfNum(time, ['день', 'дня', 'дней']);

      return `${time} ${days}`;
    }

    return DEFAULT_STRING_VALUE;
  };

  changeSummTimer = null;

  changeAccount = (id, field) => {
    this.resetErrors(() => {
      this.props.dispatch(setAccount(id, field));
    });
  };

  handleChangeTransferSum = value => this.props.dispatch(setTransferSum(value));

  handleChangeConfirmMethod = (event) => {
    this.setState({
      confirmMethod: event.target.value,
    });
  };

  validateSumm = () => {
    const sum = parseFloat(this.props.transferSum);
    if (sum <= 0 || !sum) {
      return 'Укажите сумму пополнения';
    }

    return null;
  };

  resetErrors = (callback) => {
    this.setState(
      {
        sumError: null,
        accountFromError: null,
        accountToError: null,
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    );
  };

  validateAccoutFrom = () => {
    const account = this.props.debitAccountId;

    if (!account) {
      return 'Выберите счет, с которого будет пополнение';
    }

    return null;
  };

  validateAccoutTo = () => {
    const account = this.props.depositAccountId;

    if (!account) {
      return 'Выберите вклад, который хотите пополнить';
    }

    return null;
  };

  submitForm = () => {
    if (this.props.fetching) {
      return;
    }

    this.setState(
      {
        sumError: this.validateSumm(),
        accountFromError: this.validateAccoutFrom(),
        accountToError: this.validateAccoutTo(),
      },
      () => {
        if (this.state.sumError || this.state.accountFromError || this.state.accountToError) {
          return;
        }

        this.props.dispatch(submitTransferForm(this.state.confirmMethod));
      },
    );
  };

  submitHasDisabled = () => {
    const {
      fetching,
      depositAccountId,
      debitAccountId,
      transferSum,
      transferFormError,
    } = this.props;

    return !!(
      fetching ||
      !depositAccountId ||
      !debitAccountId ||
      !transferSum ||
      transferFormError
    );
  };

  render() {
    return (
      <div className="transfer-form">
        <FormLine>
          <FormLineColumn size="12">
            <div className="form-control">
              <p className="form-control__label">Выберите вклад, который хотите пополнить</p>
              <SelectBox
                className="transfer-form__accounts-select"
                options={this.props.accounts.map(item => ({
                  value: item.id,
                  label: this.getDepositLabel(item),
                  account: item,
                }))}
                name="accountType"
                clearable={false}
                searchable={false}
                onChange={({ value }) => this.changeAccount(value, 'deposit')}
                value={this.props.depositAccountId}
                placeholder="Выберите счет"
                error={!!this.state.accountToError}
              />
              <FormControl.FormError>{this.state.accountToError}</FormControl.FormError>
            </div>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="6">
            <BlockLikeInput>
              <BlockLikeInput.RegularText>Срок:</BlockLikeInput.RegularText>{' '}
              <BlockLikeInput.BoldText>{this.getDeositTimeString()}</BlockLikeInput.BoldText>
            </BlockLikeInput>
          </FormLineColumn>
          <FormLineColumn size="6">
            <BlockLikeInput>
              <BlockLikeInput.RegularText>Дата окончания:</BlockLikeInput.RegularText>{' '}
              <BlockLikeInput.BoldText>
                {getDateString(this.state.closeDate)}
              </BlockLikeInput.BoldText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <BlockLikeInput>
              <BlockLikeInput.RegularText>Сумма начального размещения:</BlockLikeInput.RegularText>{' '}
              <BlockLikeInput.BoldText>
                {getMoneyString(this.state.openingSum, this.state.currency)}
              </BlockLikeInput.BoldText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <BlockLikeInput>
              <BlockLikeInput.RegularText>
                Сумма дохода на конец действия вклада:
              </BlockLikeInput.RegularText>{' '}
              <BlockLikeInput.BoldText>
                {getMoneyString(this.state.income, this.state.currency)}
              </BlockLikeInput.BoldText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <div className="form-control">
              <p className="form-control__label">Выберите счет, с которого будет пополнение</p>
              <SelectBox
                className="transfer-form__accounts-select"
                options={this.getDebitAccounts()}
                name="accountType"
                clearable={false}
                searchable={false}
                onChange={({ value }) => this.changeAccount(value, 'debit')}
                value={this.props.debitAccountId}
                placeholder="Выберите счет"
                disabled={!this.props.depositAccountId}
                error={this.props.depositAccountId && !!this.state.accountFromError}
              />
              <FormControl.FormError>
                {this.props.depositAccountId && this.state.accountFromError}
              </FormControl.FormError>
            </div>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="9">
            <FormControl
              type="money"
              name="transferSum"
              id="transferSum"
              label="Сумма пополнения"
              value={this.props.transferSum}
              onChange={this.handleChangeTransferSum}
              disabled={!this.props.depositAccountId || !this.props.debitAccountId}
              error={
                (this.props.depositAccountId || this.props.debitAccountId) && this.state.sumError
              }
              onFocus={this.resetErrors}
            />
          </FormLineColumn>
          <FormLineColumn size="3">
            <FormControl.FormLabel>Валюта</FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>
                {this.state.currency || DEFAULT_STRING_VALUE}
              </BlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        {this.props.incomeAfterRefill && (
          <FormLine>
            <FormLineColumn size="12">
              <BlockLikeInput>
                <BlockLikeInput.RegularText>
                  Сумма дохода после пополнения:
                </BlockLikeInput.RegularText>{' '}
                {this.props.incomeFetching ? (
                  <Throbber inline />
                ) : (
                  <span>
                    <BlockLikeInput.BoldText>
                      {getMoneyString(this.props.incomeAfterRefill, this.state.currency)}
                    </BlockLikeInput.BoldText>{' '}
                    <BlockLikeInput.BoldText className="success-color">
                      (+ {(this.props.incomeAfterRefill - this.state.income).toLocaleString()})
                    </BlockLikeInput.BoldText>
                  </span>
                )}
              </BlockLikeInput>
            </FormLineColumn>
            <FormLineColumn size="12">
              <p className="form-control__error">{this.props.incomeError}</p>
            </FormLineColumn>
          </FormLine>
        )}
        <ConfirmMethodControls
          changeHandler={this.handleChangeConfirmMethod}
          selectedMethod={this.state.confirmMethod}
        />
        <FormLine>
          {this.props.transferFormError && (
            <FormLineColumn size="12">
              <p className="form-control__error">{this.props.transferFormError}</p>
            </FormLineColumn>
          )}
          <FormLineColumn size="12">
            <Button
              type="primary"
              onClick={this.submitForm}
              fakeDisabled={this.submitHasDisabled()}
              block
            >
              {this.props.fetching ? 'Подождите...' : 'Подтвердить операцию'}
            </Button>
          </FormLineColumn>
        </FormLine>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.transfersReducer.fetching,
  accounts: state.transfersReducerDeposit.accounts,
  depositAccountId: state.transfersReducerDeposit.depositAccountId,
  debitAccountId: state.transfersReducerDeposit.debitAccountId,
  transferSum: state.transfersReducerDeposit.transferSum,
  transferFormError: state.transfersReducerDeposit.transferFormError,
  incomeAfterRefill: state.transfersReducerDeposit.incomeAfterRefill,
  incomeFetching: state.transfersReducerDeposit.incomeFetching,
  incomeError: state.transfersReducerDeposit.incomeError,
});

export default connect(mapStateToProps)(TransferFormDeposit);
