import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { FormControl } from '../../../../commonComponents/FormControl';
import SelectBox from '../../../../commonComponents/SelectBox';
import Button from '../../../../commonComponents/Button';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import Typography from '../../../../commonComponents/Typography';
import SetMaxSum from '../SetMaxSum';
import { getAccountLabelTransfer, getMoneyString } from '../../../../utils';
import { cancelTransfer } from '../../actions';
import {
  getAccounts,
  changeAccount,
  setTransferSum,
  submitTransferForm,
} from '../../actions/salaryTransfer';
import { DEFAULT_CURRENCY_LABEL } from '../../../../constants';
import ConfirmMethodControls from '../ConfirmMethodControls';
import { acceptLimitInfo, checkLimitInfo } from '../../../App/actions';

class TransferFormSalary extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
    accountFrom: PropTypes.number,
    transferSum: PropTypes.number.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    transferFormError: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
    initialAccount: PropTypes.number,
    limitInfo: PropTypes.shape({
      IsActivated: PropTypes.bool.isRequired,
      IsAccepted: PropTypes.bool.isRequired,
      Description: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    className: null,
    accountFrom: null,
    transferFormError: null,
    initialAccount: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      sumError: null,
      currency: DEFAULT_CURRENCY_LABEL,
      availableSum: 0,
      confirmMethod: 'sms',
      accountFromError: null,
      focusSum: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getAccounts());
    this.props.dispatch(checkLimitInfo());
    if (this.props.initialAccount) {
      this.props.dispatch(changeAccount(this.props.initialAccount));
    }
  }

  componentWillReceiveProps(nextProps) {
    const selectedAccount = nextProps.accounts.find(item => item.id === nextProps.accountFrom);

    if (selectedAccount) {
      this.setState({
        currency: selectedAccount.currencyName,
        availableSum: selectedAccount.sum,
      });
    }

    if (!nextProps.accountFrom) {
      this.setState({
        currency: DEFAULT_CURRENCY_LABEL,
        availableSum: 0,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.accounts.length && prevProps.accounts.length) {
      this.props.dispatch(getAccounts());
    }
  }

  setMaxSum = () => {
    this.props.dispatch(setTransferSum(this.state.availableSum));
  };

  handleChangeAccount = ({ value: id }) => {
    this.resetErrors(() => this.props.dispatch(changeAccount(id)));
  };

  handleChangeSum = (value) => {
    this.props.dispatch(setTransferSum(value));
  };

  handleChangeConfirmMethod = (event) => {
    this.setState({
      confirmMethod: event.target.value,
    });
  };

  resetErrors = (callback) => {
    this.setState(
      {
        sumError: null,
        accountFromError: null,
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    );
  };

  validateAccoutFrom = () => {
    const account = this.props.accountFrom;

    if (!account) {
      return 'Выберите счет списания';
    }

    return null;
  };

  validateSumm = () => {
    const sum = parseFloat(this.props.transferSum);

    if (sum <= 0) {
      return 'Введите сумму перевода';
    }

    if (sum > this.state.availableSum) {
      return 'Недостаточно средств на счете';
    }

    return null;
  };

  submitTransferForm = () => {
    if (this.props.fetching) {
      return;
    }

    this.setState(
      {
        sumError: this.validateSumm(),
        accountFromError: this.validateAccoutFrom(),
      },
      () => {
        if (this.state.sumError || this.state.accountFromError) {
          return;
        }

        this.props.dispatch(submitTransferForm(this.state.confirmMethod));
      },
    );
  };

  submitHasDisabled = () => {
    const { fetching, accountFrom, transferSum } = this.props;
    const { sumError } = this.state;
    return !!(fetching || !accountFrom || !transferSum || sumError);
  };

  cancelTransfer = () => {
    this.props.dispatch(cancelTransfer());
  };

  renderBlockForm = () => {
    const { limitInfo } = this.props;
    return (
      <div className="blocking-form">
        <div className="blocking-form__title" dangerouslySetInnerHTML={{__html: limitInfo.Description.replace(/\\r\\n/g, '<br />')}} />
        <Button
          className="blocking-form__button"
          type="primary"
          onClick={this.acceptBlocking}
          block
        >
          Продолжить
        </Button>
      </div>
    );
  };

  showBlockForm = () => {
    const { focusSum } = this.state;
    const { limitInfo } = this.props;

    return focusSum && limitInfo.IsActivated && !limitInfo.IsAccepted;
  };

  onFocus = () => {
    this.resetErrors();
    this.setState({
      focusSum: true,
    });
  };

  acceptBlocking = () => {
    this.props.dispatch(acceptLimitInfo());
  };


  render() {
    return (
      <div className={cx('transfer-form', 'transfer-form-relative', { [this.props.className]: this.props.className })}>
        {this.showBlockForm() && this.renderBlockForm()}
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>Счет списания</FormControl.FormLabel>
            <SelectBox
              className="transfer-form__accounts-select"
              options={this.props.accounts.map(item => ({
                value: item.id,
                label: getAccountLabelTransfer(item),
                account: item,
              }))}
              name="accountType"
              clearable={false}
              searchable={false}
              onChange={this.handleChangeAccount}
              value={this.props.accountFrom}
              placeholder="Выберите счет"
              error={!!this.state.accountFromError}
            />
            <FormControl.FormError>{this.state.accountFromError}</FormControl.FormError>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="8">
            <FormControl.FormLabel>Доступно для снятия</FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>
                {getMoneyString(this.state.availableSum, this.state.currency)}
              </BlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
          <FormLineColumn size="4">
            <FormControl.FormLabel>Валюта</FormControl.FormLabel>
            <BlockLikeInput>
              <BlockLikeInput.RegularText>{this.state.currency}</BlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl
              type="money"
              name="transferSumm"
              id="transferSumm"
              label="Сумма перевода на карту"
              value={this.props.transferSum}
              onChange={this.handleChangeSum}
              error={!!this.state.availableSum && this.state.sumError}
              disabled={!this.state.availableSum}
              onFocus={this.onFocus}
            />
          </FormLineColumn>
          {this.state.availableSum > 0 && (
            <FormLineColumn size="12">
              <SetMaxSum
                onClick={this.setMaxSum}
                label={
                  <span>
                    Перевести все{' '}
                    <Typography.Link>
                      {getMoneyString(this.state.availableSum, this.state.currency)}
                    </Typography.Link>
                  </span>
                }
              />
            </FormLineColumn>
          )}
        </FormLine>
        <ConfirmMethodControls
          changeHandler={this.handleChangeConfirmMethod}
          selectedMethod={this.state.confirmMethod}
        />
        {this.props.transferFormError && (
          <p className="form-control__error">{this.props.transferFormError}</p>
        )}
        <FormLine>
          <FormLineColumn size="12">
            <Button
              type="primary"
              onClick={this.submitTransferForm}
              fakeDisabled={this.submitHasDisabled()}
              block
            >
              {this.props.fetching ? 'Подождите...' : 'Подтвердить операцию'}
            </Button>
          </FormLineColumn>
        </FormLine>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.transfersReducerSalary.accounts,
  accountFrom: state.transfersReducerSalary.accountFrom,
  transferSum: state.transfersReducerSalary.transferSum,
  transferFormError: state.transfersReducerSalary.transferFormError,
  fetching: state.transfersReducer.fetching,
  limitInfo: state.appReducer.limitInfo,
});

export default connect(mapStateToProps)(TransferFormSalary);
