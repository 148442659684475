import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack, push } from 'react-router-redux';
import { FormControl } from '../../../../commonComponents/FormControl';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import Button from '../../../../commonComponents/Button';
import Icon from '../../../../commonComponents/Icon';
import { cancelTransfer, submitConfirmCode, reFetchConfirmCode } from '../../actions';
import './assets/styles/styles.scss';

const isValidCode = text => /^\d{6}$/.test(text);

class TransferConfirm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    transferCodeError: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        transferType: PropTypes.string,
        transferData: PropTypes.object,
      }),
    }).isRequired,
  };

  static defaultProps = {
    className: null,
    transferCodeError: null,
  };

  constructor(props) {
    super(props);

    this.transfer = this.props.location.state;

    this.state = {
      code: '',
      codeError: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      codeError: nextProps.transferCodeError,
    });
  }

  moveBack = () => {
    if (this.transfer && this.transfer.transferType === 'salary') {
      this.props.dispatch(push('/transfers/to-salary'));
    } else {
      this.props.dispatch(goBack());
    }
  };

  handleChangeCode = (event) => {
    const { value } = event.target;

    this.setState({
      codeError: null,
      code: value,
    });
  };

  submitCode = () => {
    if (this.props.fetching) {
      return;
    }

    const { code } = this.state;
    if (code.length === 0) {
      this.setState({
        codeError: 'Поле не может быть пустым',
      });
      return;
    }

    if (!isValidCode(code)) {
      this.setState({
        codeError: 'Код должен состоять из 6-и цифр',
      });
      return;
    }

    this.props.dispatch(submitConfirmCode(code, this.transfer));
  };

  repeatCode = () => {
    this.props.dispatch(reFetchConfirmCode(this.transfer));
  };

  isDisabledSubmit = () => !!(this.props.fetching || !this.state.code || this.state.codeError);

  cancelTransfer = () => {
    this.props.dispatch(cancelTransfer());
    this.moveBack();
  };

  render() {
    return (
      <div className={cx('transfer-confirm', { [this.props.className]: this.props.className })}>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl
              type="text"
              name="transferSumm"
              id="transferSumm"
              label="Введите код подтверждения"
              value={this.state.code}
              onChange={this.handleChangeCode}
              error={this.state.codeError}
            />
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <div className="transfer-confirm__repeat-code">
              <Button type="inline" onClick={this.repeatCode}>
                Выслать код повторно
              </Button>
            </div>
            <Button
              onClick={this.submitCode}
              type="primary"
              className="button transfer-form__button"
              disabled={this.isDisabledSubmit()}
              block
            >
              {this.props.fetching ? 'Подождите...' : 'Подтвердить перевод'}
            </Button>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <div className="transfer-confirm__controls">
              <Button onClick={this.moveBack} type="inline">
                <Icon name="chevron-left" />
                {' '}
                Назад
              </Button>
              <Button
                type="inline"
                onClick={this.cancelTransfer}
              >
                Отменить перевод
              </Button>
            </div>
          </FormLineColumn>
        </FormLine>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.transfersReducer.fetching,
  transferCodeError: state.transfersReducer.transferCodeError,
});

export default withRouter(connect(mapStateToProps)(TransferConfirm));
