import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';

export default class Button extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
    type: PropTypes.oneOf(['primary', 'action', 'danger', 'inline']),
    disabled: PropTypes.bool,
    fakeDisabled: PropTypes.bool,
    block: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    onClick: null,
    size: 'm',
    type: 'action',
    disabled: false,
    fakeDisabled: false,
    block: false,
  };

  handleClick = (event) => {
    event.stopPropagation();

    if (this.props.disabled) {
      event.preventDefault();
      return;
    }

    if (typeof this.props.onClick === 'function') {
      this.props.onClick();
    }
  };

  render() {
    const className = cx(
      'button',
      `button--size-${this.props.size}`,
      `button--type-${this.props.type}`,
      {
        [this.props.className]: !!this.props.className,
        'button--disabled': this.props.disabled || this.props.fakeDisabled,
        'button--block': this.props.block,
      },
    );

    const Tag = this.props.type === 'inline' ? 'span' : 'button';

    return (
      <Tag className={className} onClick={this.handleClick}>
        {this.props.children}
      </Tag>
    );
  }
}
