import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import './assets/styles/styles.scss';
import { FormControl, Radio } from '../../../../commonComponents/FormControl';
import {
  recoveryPasswordStep1,
  confirmRecoveryCode,
  recoveryBackToStep1,
} from '../../actions';

const isValidUserField = text => /^[a-z0-9]+$/i.test(text);
const isValidCode = text => /^\d{6}$/.test(text);


class RecoveryForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    recoveryBySms: PropTypes.bool.isRequired,
    recoveryError: PropTypes.string,
    recoveryCodeError: PropTypes.string,
  }

  static defaultProps = {
    recoveryError: null,
    recoveryCodeError: null,
  }

  constructor(props) {
    super(props);

    this.changeUserHandler = this.changeUserHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.backHandler = this.backHandler.bind(this);
    this.changeRecoveryMethod = this.changeRecoveryMethod.bind(this);
    this.changeCodeHandler = this.changeCodeHandler.bind(this);
    this.submitCodeHandler = this.submitCodeHandler.bind(this);

    this.state = {
      user: '',
      recoveryMethod: '1',
      userError: '',
      code: '',
      codeError: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      userError: nextProps.recoveryError,
      codeError: nextProps.recoveryCodeError,
    });
  }

  submitHandler(event) {
    event.preventDefault();
    this.setState({
      code: '',
      codeError: '',
    });

    if (this.state.user.length === 0) {
      this.setState({
        userError: 'Поле не может быть пустым',
      });
      return;
    }
    if (!isValidUserField(this.state.user)) {
      this.setState({
        userError: 'Введите корректный логин или телефон',
      });
      return;
    }
    this.props.dispatch(recoveryPasswordStep1({
      user: this.state.user,
      recoveryMethod: this.state.recoveryMethod,
    }));
  }

  backHandler() {
    if (this.props.recoveryBySms) {
      this.props.dispatch(recoveryBackToStep1());
      return;
    }
    this.props.dispatch(push('/'));
  }

  changeCodeHandler(event) {
    const { value } = event.target;
    if (/\D/.test(value)) {
      return;
    }
    this.setState({
      code: value,
      codeError: '',
    });
  }

  changeUserHandler(event) {
    this.setState({
      user: event.target.value,
      userError: '',
    });
  }

  changeRecoveryMethod(event) {
    this.setState({
      recoveryMethod: event.target.value.toString(),
    });
  }

  submitCodeHandler(event) {
    event.preventDefault();

    if (this.state.code.length === 0) {
      this.setState({
        codeError: 'Поле не может быть пустым',
      });
      return;
    }

    if (!isValidCode(this.state.code)) {
      this.setState({
        codeError: 'Код должен состоять из 6-и цифр',
      });
      return;
    }
    this.props.dispatch(confirmRecoveryCode(this.state.code, this.state.user));
  }

  repeatCode = () => {
    this.props.dispatch(recoveryPasswordStep1({
      user: this.state.user,
      recoveryMethod: '2',
    }));
  };

  render() {
    if (this.props.recoveryBySms) {
      return (
        <div>
          <form className="recovery-form" onSubmit={this.submitCodeHandler}>
            <FormControl
              className="recovery-form__form-control recovery-form__form-control--code"
              type="text"
              name="code"
              id="code"
              placeholder="Введите код из SMS"
              error={this.state.codeError}
              value={this.state.code}
              onChange={this.changeCodeHandler}
            />
            <input
              type="submit"
              className="button recovery-form__button"
              value="Сбросить пароль"
            />
          </form>
          <div className="recovery-form__repeat">
            <button
              className="inline-button"
              onClick={this.repeatCode}
            >
              Отправить код повторно
            </button>
          </div>
          <p className="recovery-form__back">
            <button className="inline-button" onClick={this.backHandler}>&lt; Назад</button>
          </p>
        </div>
      );
    }
    return (
      <div>
        <form className="recovery-form" onSubmit={this.submitHandler}>
          <FormControl
            className="recovery-form__form-control"
            type="text"
            name="user"
            id="user"
            placeholder="Введите ваш логин или телефон"
            error={this.state.userError}
            value={this.state.user}
            onChange={this.changeUserHandler}
          />
          <span className="form-control__label">
            Как вы хотите сбросить пароль?
          </span>
          <Radio
            id="confirm-sms"
            field="recoveryMethod"
            onChange={this.changeRecoveryMethod}
            value="2"
            checked={this.state.recoveryMethod === '2'}
          >
            Сбросить пароль через SMS
          </Radio>
          <Radio
            id="confirm-email"
            field="recoveryMethod"
            onChange={this.changeRecoveryMethod}
            value="1"
            checked={this.state.recoveryMethod === '1'}
          >
            Сбросить пароль через e-mail
          </Radio>
          <input
            type="submit"
            className="button recovery-form__button"
            value="Отправить"
          />
        </form>
        <p className="recovery-form__back">
          <button className="inline-button" onClick={this.backHandler}>&lt; Назад</button>
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  recoveryBySms: state.appReducer.recoveryBySms,
  recoveryError: state.appReducer.recoveryError,
  recoveryCodeError: state.appReducer.recoveryCodeError,
});

export default connect(mapStateToProps)(RecoveryForm);
