import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getValueString, DEFAULT_STRING_VALUE } from '../../modules/Accounts/utils';
import { history } from '../../store';
import './assets/styles/styles.scss';
import Button from '../Button';

const SummaryBlock = (props) => {
  const { data, columns, className } = props;

  const getParamValue = (paramName) => {
    try {
      if (paramName === 'percent' && data.accountType === 'pension') {
        const value =
          getValueString(data[paramName], paramName, data.currency) || DEFAULT_STRING_VALUE;
        return `${data.currency === 'RUB' ? 'Ключевая ставка' : 'Ставка ФРС'} + ${value}`;
      }
      if (data[paramName] === undefined || data[paramName] === null) {
        return DEFAULT_STRING_VALUE;
      }
      return getValueString(data[paramName], paramName, data.currency);
    } catch (e) {
      return DEFAULT_STRING_VALUE;
    }
  };

  const getParam = (param, accountData) => (
    <div
      className={cx('summary-block__item', `summary-block__item--${param.name}`)}
      key={param.name}
    >
      <p className="summary-block__label">{param.title}</p>
      <div className="summary-block__value">
        {getParamValue(param.name)}
        {accountData.type === 'loan' &&
          param.name === 'nextPaymentSum' && (
            <div>
              <Button
                size="xs"
                onClick={() => history.push('/transfers/loan', { accountId: accountData.id })}
              >
                Погасить досрочно
              </Button>
            </div>
          )}
        {accountData.type === 'deposit' &&
          param.name === 'balance' && (
            <div>
              <Button
                size="xs"
                onClick={() => history.push('/transfers/deposit', { accountId: accountData.id })}
              >
                Пополнить вклад
              </Button>
            </div>
          )}
      </div>
    </div>
  );

  return (
    <div className={cx('summary-block', { [className]: className })}>
      {columns.map((column, index) => {
        const key = `column_${index}`;
        return (
          <div className="summary-block__column" key={key}>
            {column.map(item =>
              getParam(item, {
                type: data.accountType,
                id: data.id,
              }))}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryBlock;

SummaryBlock.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    balance: PropTypes.number,
    currency: PropTypes.string,
    balanceDiff: PropTypes.number,
    balanceIn: PropTypes.number,
    balanceOut: PropTypes.number,
    accountType: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))).isRequired,
  className: PropTypes.string,
};

SummaryBlock.defaultProps = {
  data: null,
  className: null,
};
