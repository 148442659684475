export const BASE_API_URL = '/api';
export const TOKEN_LIFETIME = 1000 * 60 * 20;
export const PAGINATION_LIMIT = 10;
export const CLIENT_DATE_FORMAT = 'DD.MM.YYYY';
export const CLIENT_DATETIME_FORMAT = 'DD.MM.YYYY, HH:mm:ss';

export const MENU = {
  accounts: {
    title: 'Счета',
    name: 'accounts',
    path: '/accounts',
    childrens: [
      {
        title: 'Текущие счета',
        name: 'current',
        path: '/accounts/current',
      },
      {
        title: 'Паенакопление',
        name: 'shares',
        path: '/accounts/shares',
      },
      {
        title: 'Зарплатные счета',
        name: 'salary',
        path: '/accounts/salary',
      },
      {
        title: 'Сберегательные программы',
        name: 'deposit',
        path: '/accounts/deposit',
      },
      {
        title: 'Займы',
        name: 'loan',
        path: '/accounts/loan',
      },
      {
        title: 'Другие доходы',
        name: 'raising',
        path: '/accounts/raising',
      },
    ],
  },
  transfers: {
    title: 'Счета',
    name: 'accounts',
    path: '/accounts',
    childrens: [
      {
        title: 'Перевод на ЗП карту',
        name: 'toSalary',
        path: '/transfers/to-salary',
      },
      {
        title: 'Перевод между своими счетами',
        name: 'toAccount',
        path: '/transfers/to-account',
      },
      // {
      //   title: 'Перевод другому пайщику',
      //   name: 'toPartner',
      //   path: '/transfers/to-partner',
      // },
      {
        title: 'Досрочное погашение займа',
        name: 'loan',
        path: '/transfers/loan',
      },
      {
        title: 'Пополнение вклада',
        name: 'deposit',
        path: '/transfers/deposit',
      },
    ],
  },
};

/**
 * Колонки для табличного представления заявок
 */
const applicationColumns = [
  {
    name: 'id',
    title: '№ заявки',
  },
  {
    name: 'date',
    title: 'Дата и время',
  },
  {
    name: 'accountType',
    title: 'Тип',
  },
  {
    name: 'accountCode',
    title: 'Номер счета',
  },
  {
    name: 'sum',
    title: 'Сумма',
  },
  {
    name: 'currencyName',
    title: 'Валюта',
  },
  {
    name: 'status',
    title: 'Статус',
  },
];

/**
 * Колонки для табличного представления оперций по счету
 */
const operationsColumns = [
  {
    name: 'date',
    title: 'Дата транзакции',
  },
  {
    name: 'title',
    title: 'Операция',
  },
  {
    name: 'sum',
    title: 'Сумма операции',
  },
  {
    name: 'balance',
    title: 'Баланс',
  },
];

/**
 * Колонки для табличного представления графика платежей
 */
const sheduleColumns = [
  {
    name: 'date',
    title: 'Дата платежа',
  },
  {
    name: 'sum',
    title: 'Сумма платежа',
  },
  {
    name: 'balance',
    title: 'Остаток основного долга',
  },
];

/**
 * Колонки таблицы счетов, разделенные по типу счета
 */
export const COLUMNS = {
  current: [
    {
      name: 'title',
      title: 'Наименовая счета',
    },
    {
      name: 'balance',
      title: 'Баланс счета',
    },
    {
      name: 'actions',
      title: 'Действия',
    },
  ],
  shares: [
    {
      name: 'title',
      title: 'Наименовая счета',
    },
    {
      name: 'dateOfCreate',
      title: 'Дата открытия',
    },
    {
      name: 'balance',
      title: 'Баланс счета',
    },
    {
      name: 'actions',
      title: 'Действия',
    },
  ],
  salary: [
    {
      name: 'title',
      title: 'Наименовая счета',
    },
    {
      name: 'dateOfCreate',
      title: 'Дата открытия',
    },
    {
      name: 'accruedIncome',
      title: 'Начисленный доход',
    },
    {
      name: 'incomeToday',
      title: 'Доход на сегодня',
    },
    {
      name: 'balance',
      title: 'Баланс счета',
    },
    {
      name: 'actions',
      title: 'Действия',
    },
  ],
  deposit: [
    {
      name: 'title',
      title: 'Наименовая счета',
    },
    {
      name: 'dateOfEnd',
      title: 'Плановая дата закрыти',
    },
    {
      name: 'paidIncome',
      title: 'Выплаченный доход',
    },
    {
      name: 'nextPayment',
      title: 'Очередная выплата',
    },
    {
      name: 'balance',
      title: 'Баланс счета',
    },
    {
      name: 'percent',
      title: 'Проценная ставка, %',
    },
    {
      name: 'funcPay',
      title: 'Функция начисления',
    },
    {
      name: 'actions',
      title: 'Действия',
    },
  ],
  loan: [
    {
      name: 'loanSum',
      title: 'Сумма займа',
    },
    {
      name: 'dateOfCreate',
      title: 'Дата открытия',
    },
    {
      name: 'dateOfEnd',
      title: 'Плановая дата закрытия',
    },
    {
      name: 'nextPaymentSum',
      title: 'Очередной  платеж *',
    },
    {
      name: 'overduePayment',
      title: 'Просрочка',
    },
    {
      name: 'balance',
      title: 'Остаток долга',
    },
    {
      name: 'actions',
      title: 'Действия',
    },
  ],
  raising: [
    {
      name: 'title',
      title: 'Наименовая счета',
    },
    {
      name: 'dateOfEnd',
      title: 'Плановая дата закрыти',
    },
    {
      name: 'paidIncome',
      title: 'Выплаченный доход',
    },
    {
      name: 'nextPayment',
      title: 'Очередная выплата',
    },
    {
      name: 'balance',
      title: 'Баланс счета',
    },
    {
      name: 'actions',
      title: 'Действия',
    },
  ],
  operations: operationsColumns,
  shedule: sheduleColumns,
  schedule: sheduleColumns,
  applications: applicationColumns,
};

export const ACCOUNT_TYPES_FOR_TRANSFER = ['current', 'salary'];

export const EMPTY_TABLE_TEXT = {
  current: 'В данный момент у вас нет текущих счетов',
  shares: 'В данный момент у вас нет счетов паенакопления',
  salary: 'В данный момент у вас нет зарплатных счетов',
  deposit: 'В данный момент у вас нет сберегательных счетов',
  loan: 'В данный момент у вас нет займов',
  raising: 'В данный момент у вас нет счетов привлечения средств',
  operations: 'Операций не найдено',
  availableFunds: 'В данный момент у вас нет доступных средств',
};

/**
 * GET-параметр, проверяя который мы узнаем что юзер пришел по ссылке из письма
 */
export const SUCCESS_RESET_PARAM = '?email_reset_success';

export const DEFAULT_STRING_VALUE = '---';
export const DEFAULT_CURRENCY_LABEL = DEFAULT_STRING_VALUE;

export const CONFIRM_TYPES = {
  sms: 2,
  email: 3,
  push: 4,
};

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY, hh.mm';

export const ACCOUNT_TYPES_FOR_REQUESTS = ['current', 'salary'];
