import React from 'react';
import { FormControl } from '../../../../commonComponents/FormControl';
import SelectBox from '../../../../commonComponents/SelectBox';
import { getAccountLabelTransfer, getMoneyString } from '../../../../utils';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine';
import Button from '../../../../commonComponents/Button';
import Typography from '../../../../commonComponents/Typography';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import SetMaxSum from '../SetMaxSum';
import { TransferFormContainer } from '../Views';
import { getAccounts, InnerAccount, getPartner, getCode } from './api';
import { DEFAULT_CURRENCY_LABEL, CONFIRM_TYPES } from '../../../../constants';
import { debounce, Cancelable } from 'lodash';
import { PartnerLabel } from './views';
import ConfirmMethodControls from '../ConfirmMethodControls';
import { withRouter, RouteComponentProps } from 'react-router';

// TODO: refactor to ts
const JsFormControl: any = FormControl;
const JsBlockLikeInput: any = BlockLikeInput;
const JsSelectBox: any = SelectBox;

export type ConfirmMethodType = keyof typeof CONFIRM_TYPES;

export interface Partner {
  id: number;
  partnerSecureId: string;
}

export interface TransferFormPartnerProps extends RouteComponentProps {}

export interface TransferFormPartnerState {
  accounts: InnerAccount[];
  account: InnerAccount | null;
  sum: number;
  sumError: string | null;
  accountError: string | null;
  partnerPhoneOrEmail: string;
  loading: boolean;
  partnerLoading: boolean;
  partner: Partner | null;
  partnerError: string | null;
  confirmMethod: ConfirmMethodType;
  transferError: string | null;
  accountsLoading: boolean;
}

class TransferFormPartner extends React.Component<TransferFormPartnerProps, TransferFormPartnerState> {
  private debouncedParterRequest: ((partnerPhoneOrEmail: string) => void) & Cancelable;

  constructor(props: TransferFormPartnerProps) {
    super(props);

    this.state = {
      accounts: [],
      account: null,
      sum: 0,
      sumError: null,
      accountError: null,
      partnerPhoneOrEmail: '',
      partnerLoading: false,
      loading: false,
      partner: null,
      partnerError: null,
      confirmMethod: 'sms',
      transferError: null,
      accountsLoading: false
    };

    this.debouncedParterRequest = debounce<(partnerPhoneOrEmail: string) => void>(this.getPartner, 1500)
  }

  public componentDidMount() {
    this.getAccounts();
  }

  public render() {
    return (
      <TransferFormContainer>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel id="accountType" className={undefined}>Счет снятия</FormControl.FormLabel>
            <JsSelectBox
              className="transfer-form__accounts-select"
              options={
                Array.isArray(this.state.accounts)
                  ? this.state.accounts.map(item => ({
                      value: item.id,
                      label: getAccountLabelTransfer(item),
                    }))
                  : []
              }
              name="accountType"
              id="accountType"
              clearable={false}
              searchable={false}
              onChange={this.handleChangeAccount}
              value={this.state.account ? this.state.account.id : null}
              placeholder="Выберите счет"
              error={!!this.state.accountError}
              loading={this.state.accountsLoading}
            />
            <FormControl.FormError>{this.state.accountError}</FormControl.FormError>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <JsFormControl.FormLabel>Получатель перевода</JsFormControl.FormLabel>
            <FormControl
              value={this.state.partnerPhoneOrEmail}
              onChange={this.handleChangePartner}
              id="partnerPhoneOrEmail"
              name="partnerPhoneOrEmail"
              loading={this.state.partnerLoading}
              placeholder="Телефон или e-mail пайщика кооператива"
              error={this.state.partnerError}
            />
            {
              this.state.partner && (
                <PartnerLabel>Перевод для: {this.state.partner.partnerSecureId}</PartnerLabel>
              )
            }
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="8">
            <JsFormControl.FormLabel>Доступно для снятия</JsFormControl.FormLabel>
            <BlockLikeInput>
              <JsBlockLikeInput.RegularText>{this.getavailableSum()}</JsBlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
          <FormLineColumn size="4">
            <JsFormControl.FormLabel>Валюта</JsFormControl.FormLabel>
            <BlockLikeInput>
              <JsBlockLikeInput.RegularText>
                {this.state.account ? this.state.account.currencyName : DEFAULT_CURRENCY_LABEL}
              </JsBlockLikeInput.RegularText>
            </BlockLikeInput>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <JsFormControl
              type="money"
              name="transferSum"
              id="transferSum"
              label="Сумма перевода"
              value={this.state.sum}
              onChange={this.handleChangetransferSum}
              error={this.state.account && this.state.sumError}
              disabled={!this.state.account}
              onFocus={this.resetErrors}
            />
          </FormLineColumn>
          {this.state.account && this.state.account.balance > 0 && (
            <FormLineColumn size="12">
              <SetMaxSum
                onClick={this.setMaxSum}
                label={
                  <span>
                    Перевести все <Typography.Link>{this.getavailableSum()}</Typography.Link>
                  </span>
                }
              />
            </FormLineColumn>
          )}
        </FormLine>
        <FormLine>
          <ConfirmMethodControls
            changeHandler={this.handleChangeConfirmMethod}
            selectedMethod={this.state.confirmMethod}
          />
          {this.state.transferError && (
            <FormLineColumn size="12">
              <JsFormControl.FormError>{this.state.transferError}</JsFormControl.FormError>
            </FormLineColumn>
          )}
          <FormLineColumn size="12">
            <Button
              type="primary"
              fakeDisabled={this.isSubmitDisabled()}
              onClick={this.submitForm}
              block
            >
              {this.state.loading ? 'Подождите...' : 'Подтвердить операцию'}
            </Button>
          </FormLineColumn>
        </FormLine>
      </TransferFormContainer>
    );
  }

  private getAccounts = async () => {
    this.setState({ accountsLoading: true });
    try {
      this.setState({ accounts: await getAccounts<InnerAccount[]>() });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error);
    } finally {
      this.setState({ accountsLoading: false });
    }
  };

  private getPartner = async () => {
    const { partnerPhoneOrEmail } = this.state;

    this.setState({ partnerLoading: true });

    try {
      this.setState({
        partner: await getPartner<Partner>(partnerPhoneOrEmail),
        partnerError: null
      });
    } catch (error) {
      this.setState({
        partner: null,
        partnerError: error.message,
      });
    } finally {
      this.setState({
        partnerLoading: false
      })
    }
  }

  private getavailableSum = () => {
    if (this.state.account) {
      return getMoneyString(this.state.account.balance, this.state.account.currencyName);
    }

    return getMoneyString(0, '');
  };

  private setMaxSum = () => {
    const {account} = this.state;
    if (account) {
      this.setState({
        sum: account.balance,
      });
    }
  };

  private handleChangeAccount = ({ value }: {value: number}) => {
    const selectedAccount = this.state.accounts.find(item => item.id === value);
    if (selectedAccount) {
      this.resetErrors();
      this.setState({ account: selectedAccount });
    }
  };

  private handleChangePartner = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.resetErrors();
    this.setState(state => ({
      partnerPhoneOrEmail: value,
      partnerLoading: value.length > 5,
      partner: value.length < 6 ? null : state.partner,
      partnerError: null
    }), () => {
      this.debouncedParterRequest.cancel();

      if (this.state.partnerPhoneOrEmail.length > 5) {
        this.debouncedParterRequest(this.state.partnerPhoneOrEmail);
      }
    });
  };

  private handleChangetransferSum = (value: number) => {
    this.setState({
      sum: value,
    });
  };

  private resetErrors = (callback?: () => void) => {
    this.setState(
      {
        sumError: null,
        accountError: null,
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    );
  };

  private validateAccount = () => {
    if (!this.state.account) {
      return 'Выберите счет списания';
    }

    return null;
  };

  private validateSumm = () => {
    const {sum, account} = this.state;
    const availableSum = account ? account.balance : 0

    if (sum <= 0) {
      return 'Введите сумму перевода';
    }

    if (sum > availableSum) {
      return 'Недостаточно средств';
    }

    return null;
  };

  private submitForm = () => {
    if (this.state.loading) {
      return;
    }

    this.setState(state => 
      ({
        sumError: this.validateSumm(),
        accountError: this.validateAccount(),
        partnerError: state.partner ? null : state.partnerError || 'Укажите получателя перевода'
      }),
      () => {
        if (this.state.sumError || this.state.accountError) {
          return;
        }

        this.requestCode();
      },
    );
  };

  private requestCode = async () => {
    const {
      sum,
      account,
      partner,
      confirmMethod,
    } = this.state;

    if (!sum || !account || !partner) {
      return;
    }

    this.setState({ loading: true });

    const requestData = {
      sum,
      account: account.id,
      partner: partner.id,
      confirmMethod
    }

    try {
      await getCode(requestData);
      this.setState({loading: false}, () => {
        this.props.history.push('/transfers/confirm', {
          confirmMethod,
          transferType: 'to-partner',
          transferData: {
            Sum: sum,
            FromBill: account.id,
            toParnertId : partner.id,
            CodeType: CONFIRM_TYPES[confirmMethod as ConfirmMethodType]
          },
        })
      })
    } catch (error) {
      this.setState({loading: false, transferError: error })
    }
  }

  private isSubmitDisabled = () => {
    const {
      account, sumError, loading, partnerLoading, partner,
    } = this.state;
    return !!(loading || !account || sumError || partnerLoading || !partner);
  };

  private handleChangeConfirmMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ConfirmMethodType;
    this.setState({
      confirmMethod: value
    });
  }
}

export default withRouter(TransferFormPartner)
