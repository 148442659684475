const initialState = {
  accounts: [],
  accountFrom: null,
  transferSum: 0,
  accountsLoadingError: null,
  transferFormError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'salaryTransfer/ACCOUNTS_LOADED_SUCCESS':
      return {
        ...state,
        accounts: action.payload,
      };

    case 'salaryTransfer/ACCOUNTS_LOADED_FAIL':
      return {
        ...state,
        accountsLoadingError: action.payload,
      };

    case 'salaryTransfer/CHANGE_ACCOUNT':
      return {
        ...state,
        accountFrom: action.payload,
        transferSum: 0,
      };

    case 'salaryTransfer/SET_TRANSFER_SUM':
      return {
        ...state,
        transferSum: action.payload,
      };

    case 'salaryTransfer/SUBMIT_FORM_FAIL':
      return {
        ...state,
        transferFormError: action.payload,
      };

    case 'CANCEL_TRANSFER':
      return initialState;

    default:
      return state;
  }
};
