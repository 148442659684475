import React from 'react';
import moment from 'moment';
import Wrapper from '../../../../commonComponents/Wrapper';
import './assets/styles/styles.scss';

const Footer = () => (
  <footer className="footer">
    <Wrapper className="footer__wrapper">
      <div className="footer__copyright">
        © 2010-{moment().year()} ГК &quot;Морской фонд&quot;
      </div>
    </Wrapper>
  </footer>
);

export default Footer;
