import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { FormControl } from '../../../../commonComponents/FormControl';
import './assets/styles/styles.scss';
import { changeTempPassword } from '../../../App/actions';

class ChangePassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    tempPasswordError: PropTypes.shape({
      Message: PropTypes.string,
      ModelState: PropTypes.shape({
        'model.Login': PropTypes.arrayOf(PropTypes.string),
        'model.New': PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    isTempPassword: PropTypes.bool.isRequired,
    login: PropTypes.string,
  }

  static defaultProps = {
    tempPasswordError: null,
    login: '',
  }

  constructor(props) {
    super(props);

    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);

    this.state = {
      login: props.login,
      password: '',
      passwordConfirm: '',
      errors: {
        login: '',
        password: '',
        passwordConfirm: '',
      },
      showPassword: false,
    };
  }

  componentDidMount() {
    document.title = 'Сброс пароля';
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.login && nextProps.login) {
      this.setState({
        login: nextProps.login,
      });
    }

    if (this.props.tempPasswordError !== nextProps.tempPasswordError) {
      if (nextProps.tempPasswordError && nextProps.tempPasswordError.ModelState) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            login: Array.isArray(nextProps.tempPasswordError.ModelState['model.Login']) ? nextProps.tempPasswordError.ModelState['model.Login'].join(' ') : '',
            password: Array.isArray(nextProps.tempPasswordError.ModelState['model.New']) ? nextProps.tempPasswordError.ModelState['model.New'].join(' ') : '',
          },
        }));
      }

      if (!nextProps.tempPasswordError) {
        this.setState({
          errors: {
            login: '',
            password: '',
            passwordConfirm: '',
          },
        });
      }
    }
  }

  changeHandler(event) {
    const { value, name, checked } = event.target;
    if (name === 'showPassword') {
      this.setState({
        showPassword: checked,
      });
    } else {
      this.setState(prevState => ({
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: '',
        },
      }));
    }
  }

  submitHandler(event) {
    event.preventDefault();
    const formData = {
      login: this.state.login,
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm,
    };

    Object.keys(formData).forEach((field) => {
      if (formData[field].length === 0) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [field]: 'Поле не может быть пустым',
          },
        }));
      }
    });

    if (formData.password.length && formData.passwordConfirm.length
      && formData.password.length !== formData.passwordConfirm.length) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          passwordConfirm: 'Пароли должны совпадать',
        },
      }));
      return;
    }

    if (Object.keys(this.state.errors).every(item => this.state.errors[item] === '')) {
      this.props.dispatch(changeTempPassword(formData));
    }
  }

  render() {
    if (!this.props.isTempPassword) {
      this.props.dispatch(push('/'));
    }
    return (
      <div className="password-page">
        <div className="password-page__container">
          <h1 className="password-page__heading">Изменение временного логина и пароля</h1>
          <p className="password-page__text">
            Пожалуйста, придумайте и введите новый логин и пароль.<br />
            Вы также можете оставить свой предыдущий логин.
          </p>
          <form className="password-page__form" onSubmit={this.submitHandler}>
            <FormControl
              className="password-page__form-control"
              type="text"
              id="login"
              name="login"
              label="Логин"
              value={this.state.login}
              error={this.state.errors.login}
              onChange={this.changeHandler}
            />
            <FormControl
              className="password-page__form-control"
              type={this.state.showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              label="Новый пароль"
              value={this.state.password}
              error={this.state.errors.password}
              onChange={this.changeHandler}
            />
            <FormControl
              className="password-page__form-control"
              type={this.state.showPassword ? 'text' : 'password'}
              id="passwordConfirm"
              name="passwordConfirm"
              label="Повторите новый пароль"
              value={this.state.passwordConfirm}
              error={this.state.errors.passwordConfirm}
              onChange={this.changeHandler}
            />
            <label htmlFor="showPassword" className="password-page__show-password">
              <input
                type="checkbox"
                id="showPassword"
                name="showPassword"
                checked={this.state.showPassword}
                onChange={this.changeHandler}
              />
              Показать пароль
            </label>
            <input
              type="submit"
              value="Войти в личный кабинет"
              className="button password-page__button"
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isTempPassword: state.appReducer.isTempPassword,
  tempPasswordError: state.appReducer.tempPasswordError,
  login: state.appReducer.user.login,
});

export default connect(mapStateToProps)(ChangePassword);
