import { push } from 'react-router-redux';
import {
  resultDataToCamelCase,
  getCookie,
} from '../../../utils';
import {
  BASE_API_URL,
  CONFIRM_TYPES,
} from '../../../constants';
import {
  fetchStart,
  fetchStop,
  fetchFail,
  cancelTransfer,
} from './';

export const getAccounts = () => (dispatch) => {
  const token = getCookie('access_token');
  if (!token) {
    return;
  }

  dispatch(fetchStart());

  fetch(
    `${BASE_API_URL}/transfer/currency`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then((json) => {
      dispatch({
        type: 'currencyTransfer/ACCOUNTS_LOADED_SUCCESS',
        payload: resultDataToCamelCase(json),
      });
      dispatch(fetchStop());
    })
    .catch((error) => {
      dispatch({
        type: 'currencyTransfer/ACCOUNTS_LOADED_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const changeAccount = (id, direction) => ({
  type: 'currencyTransfer/CHANGE_ACCOUNT',
  payload: {
    id,
    direction,
  },
});

export const setTransferSum = sum => ({
  type: 'currencyTransfer/SET_TRANSFER_SUM',
  payload: sum,
});

export const submitSalaryTransfer = () => (dispatch, getState) => {
  const token = getCookie('access_token');
  const { accountFrom, accountTo, transferSum } = getState().transfersReducerCurrency;

  if (!token || !accountFrom || !accountTo || !transferSum) {
    return;
  }

  dispatch(fetchStart());

  const requestData = {
    Sum: transferSum,
    FromBill: accountFrom,
    ToBill: accountTo,
  };

  fetch(
    `${BASE_API_URL}/transfer/currency`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    },
  )
    .then((response) => {
      if (response.ok) {
        return Promise.resolve();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(() => {
      dispatch(fetchStop());
      dispatch(push('/currency-exchange/success', {
        transferType: 'currency',
        accountTo,
      }));
      dispatch(cancelTransfer());
    })
    .catch((error) => {
      dispatch(fetchFail(error.message));
      dispatch(fetchStop());
    });
};

export const submitTransferForm = confirmMethod => (dispatch, getState) => {
  const {
    accountFrom,
    accountTo,
    transferSum,
  } = getState().transfersReducerCurrency;

  const token = getCookie('access_token');

  if (!token || !CONFIRM_TYPES[confirmMethod]) {
    return;
  }

  const requestData = {
    CodeType: CONFIRM_TYPES[confirmMethod], // SMS
    FromBill: accountFrom,
    ToBill: accountTo,
    Sum: transferSum,
  };
  dispatch(fetchStart());
  fetch(`${BASE_API_URL}/transfer/currency/request-code/${CONFIRM_TYPES[confirmMethod]}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (response.ok) {
        dispatch(fetchStop());
        dispatch(push('/currency-exchange/confirm', {
          transferType: 'currency',
          transferData: requestData,
        }));
        return;
      }

      if (response.status === 500) {
        response.text().then((text) => {
          throw new Error(text);
        });
      }

      response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .catch((error) => {
      dispatch({
        type: 'currencyTransfer/SUBMIT_FORM_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};
