import React from 'react';
import PropTypes from 'prop-types';

export default class CurrencyInput extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeEvent: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    onChangeEvent: null,
    onFocus: null,
    onBlur: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.clearValue(props.value),
      parsedValue: this.parseValue(props.value),
      focused: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: this.clearValue(nextProps.value),
        parsedValue: this.parseValue(nextProps.value),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.focused && !this.state.focused && this.props.onChangeEvent) {
      this.props.onChangeEvent({}, this.state.value, parseFloat(this.state.parsedValue) || 0);
    }
  }

  handleChange = (event) => {
    const parsedValue = this.parseValue(event.target.value);
    const rawValue = this.clearValue(event.target.value);

    if (this.isValidValue(rawValue) || !rawValue) {
      this.setState({
        value: rawValue || 0,
        parsedValue,
      });
    } else {
      event.preventDefault();
    }
  };

  handleFocus = (event) => {
    event.persist();
    this.setState(
      {
        focused: true,
      },
      () => {
        if (this.props.onFocus) {
          this.props.onFocus(event);
        }
      },
    );
  };

  handleBlur = (event) => {
    event.persist();
    this.setState(
      {
        focused: false,
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
    );
  };

  isValidValue = value => /^([0-9]+)(,|.{0,1}\d{0,2})$/.test(value);

  clearValue = (value = '') =>
    value
      .toString()
      .replace(/[^\d.,]/, '')
      .replace(/^0(?=[1-9]$)/, '')
      .replace('.', ',')
      .replace(/^,$/, '0,');

  parseValue = (value = '') =>
    Number(value
      .toString()
      .replace(/[^\d.,]/, '')
      .replace(',', '.')).toFixed(2);

  formatValue = () =>
    parseFloat(this.state.parsedValue).toLocaleString(undefined, { minimumFractionDigits: 2 });

  isZeroValue = () =>
    this.state.value === '0' || this.state.value === '0,00' || !this.state.value;

  renderValue = () => {
    if (this.state.focused) {
      return this.isZeroValue() ? '' : this.state.value;
    }

    return this.formatValue();
  };

  render() {
    const { value, onChangeEvent, ...rest } = this.props;

    return (
      <input
        {...rest}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        value={this.renderValue()}
        onChange={this.handleChange}
      />
    );
  }
}
