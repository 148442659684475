/**
 * Хэлпер для выполнения запросов
 * {cancellationsStore}
 */
export default class PromiseHelper {
  /**
   * Создается хранилище промисов
   */
  constructor() {
    this.cancellationsStore = {};
  }

  /**
   * По переданному ключу в cancellationsStore записывается время запуска промиса
   * и reject для его отмены
   * @param  {Promise} Promise, который нужно добавить в стор
   * @param  {string} Ключ, по которому будет храниться промис
   * @param  {number} Интервал в ms для дебаунса выполнения промисов,
   *                  если передан, промис с таким же ключом не запустится
   *                  до выполнения предыдущего
   * @return {Promise}
   */
  request = (promise, key, minInterval = 0) => {
    const now = Date.now();
    const cancellation = this.cancellationsStore[key];
    if (cancellation && minInterval && now - cancellation.timestamp < minInterval) {
      return Promise.reject(new Error('Слишком быстро'));
    }

    return Promise.race([
      new Promise((resolve, reject) => {
        this.cancellationsStore[key] = {
          cancel: reject,
          timestamp: now,
        };
      }),
      promise,
    ])
      .then((result) => {
        delete this.cancellationsStore[key];
        return result;
      })
      .catch(() => {
        delete this.cancellationsStore[key];
      });
  };

  /**
   * Отмена промиса по переданному ключу
   * @param  {string} key
   */
  cancel = (key) => {
    if (this.cancellationsStore[key]) {
      this.cancellationsStore[key].cancel();
    }
  }
}
