import { connect } from 'react-redux';
import Dashboard from './Dashboard';


const mapStateToProps = state => ({
  user: state.appReducer.user,
  loading: state.appReducer.userFetching,
});

export default connect(mapStateToProps)(Dashboard);
