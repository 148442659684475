const initialState = {
  applications: [],
  applicationsCount: 0,
  error: null,
  fetching: false,
  currentPage: 1,
  requestFetching: false,
  requestData: null,
  accountForFilter: [],
  accountsFetching: false,
  cancelInProgress: false,
};

const applicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUESTS_FETCH_START':
      return {
        ...state,
        fetching: true,
      };

    case 'REQUESTS_FETCH_STOP':
      return {
        ...state,
        fetching: false,
      };

    case 'REQUESTS_FETCH_START_SUCCESS':
      return {
        ...state,
        applications: action.payload.applications,
        applicationsCount: action.payload.count,
        currentPage: action.payload.currentPage,
        error: initialState.error,
      };

    case 'REQUESTS_FETCH_START_FAIL':
      return {
        ...state,
        error: action.payload,
      };

    case 'REQUEST_FETCH_START':
      return {
        ...state,
        requestFetching: true,
      };

    case 'REQUEST_FETCH_SUCCESS':
      return {
        ...state,
        requestFetching: false,
        requestData: action.payload,
      };

    case 'REQUEST_FETCH_FAIL':
      return {
        ...state,
        requestFetching: false,
        requestData: initialState.requestData,
      };

    case 'CLEAR_REQUEST':
      return {
        ...state,
        requestData: initialState.requestData,
      };

    case 'requestFilter/ACCOUNTS_FETCH_START':
      return {
        ...state,
        accountsFetching: true,
      };

    case 'requestFilter/ACCOUNTS_FETCH_SUCCESS':
      return {
        ...state,
        accountForFilter: action.payload,
        accountsFetching: false,
      };

    case 'requestFilter/ACCOUNTS_FETCH_FAIL':
      return {
        ...state,
        accountsFetching: false,
      };

    case 'REQUEST_CANCEL_START':
      return {
        ...state,
        cancelInProgress: true,
      };

    case 'REQUEST_CANCEL_SUCCESS':
      return {
        ...state,
        cancelInProgress: false,
      };

    case 'REQUEST_CANCEL_FAIL':
      return {
        ...state,
        cancelInProgress: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default applicationsReducer;
