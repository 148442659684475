import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './assets/styles/styles.scss';

const Throbber = ({ className, inPanel, inline }) => (
  <div
    className={cx(
      'throbber',
      {
        'throbber--in-panel': inPanel,
        'throbber--inline': inline,
        [className]: className,
      },
    )}
  >
    <div className="throbber__icon" />
  </div>
);

export default Throbber;

Throbber.propTypes = {
  className: PropTypes.string,
  inPanel: PropTypes.bool,
  inline: PropTypes.bool,
};

Throbber.defaultProps = {
  className: '',
  inPanel: false,
  inline: false,
};
