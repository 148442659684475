import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faSyncAlt,
  faTimesCircle,
  faCheckCircle,
  faRss,
  faRedo,
  faSpinner,
  faExchangeAlt,
} from '@fortawesome/fontawesome-free-solid';

/**
 * Чтобы не тащить в бандл сотни неиспользуемых
 * иконок, подключаем только необходимые
 */
const getIcon = (name) => {
  switch (name) {
    case 'chevron-left':
      return faChevronLeft;

    case 'chevron-right':
      return faChevronRight;

    case 'sync-alt':
      return faSyncAlt;

    case 'times-circle':
      return faTimesCircle;

    case 'check-circle':
      return faCheckCircle;

    case 'rss':
      return faRss;

    case 'redo':
      return faRedo;

    case 'spinner':
      return faSpinner;

    case 'exchange-alt':
      return faExchangeAlt;

    default:
      return null;
  }
};

/**
 * @param {String} Название иконки как в каталоге
 * FontAwesome (https://fontawesome.com/icons)
 */
const Icon = ({ name }) => {
  const icon = getIcon(name);

  if (!icon) {
    return null;
  }

  return <FontAwesomeIcon icon={icon} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
