import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import './assets/styles/styles.scss';
import SelectBox from '../../../../commonComponents/SelectBox';
import DatePicker from '../../../../commonComponents/DatePicker';
import { setAccountFilters, getAccountsListing, clearAccountDetails } from '../../actions';
import { getMoneyString } from '../../../../utils';
import { MENU } from '../../../../constants';

const getAccountLabel = account =>
  `"${account.title}", ${getMoneyString(account.balance, account.currency)}`;

class AccountFilters extends React.Component {
  componentDidMount() {
    this.props.dispatch(getAccountsListing(this.props.currentType));
    this.getDefaultAccount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentType !== this.props.currentType) {
      this.props.dispatch(getAccountsListing(this.props.currentType));
    }
    if (!this.props.currentAccountId && this.props.accounts[this.props.currentType].length) {
      this.getDefaultAccount();
    }
  }

  getDefaultAccount = () => {
    if (this.props.accountsFetching) {
      return;
    }

    if (this.props.accounts[this.props.currentType].length && !this.props.currentAccountId) {
      const defaultAccount = this.props.accounts[this.props.currentType][0];

      this.props.dispatch(setAccountFilters({
        accountId: defaultAccount.id,
        dateStart: moment(defaultAccount.dateOfCreate),
        dateEnd: moment(),
        minDate: moment(defaultAccount.dateOfCreate),
        maxDate: moment(),
      }));
    }
  };

  changeTypeHandler = (option) => {
    const type = option.value;

    if (type === this.props.currentType) {
      return;
    }

    this.props.dispatch(setAccountFilters({
      type,
      accountId: null,
      dateStart: null,
      dateEnd: null,
      minDate: null,
      maxDate: null,
    }));
    this.props.dispatch(clearAccountDetails());
    this.props.dispatch(getAccountsListing(type));
  };

  changeAccountHandler = (option) => {
    const { account } = option;
    if (account.id === this.props.currentAccountId) {
      return;
    }

    this.props.dispatch(setAccountFilters({
      accountId: account.id,
      dateStart: moment(account.dateOfCreate),
      dateEnd: moment(),
      minDate: moment(account.dateOfCreate),
      maxDate: moment(),
    }));
    this.props.dispatch(clearAccountDetails());
  };

  handleChangeDates = dates =>
    this.props.dispatch(setAccountFilters({
      dateStart: dates.startDate,
      dateEnd: dates.endDate,
    }));

  render() {
    return (
      <div className={`filters ${this.props.className}`}>
        <div className="filters__item filters__item--account-type">
          <p className="filters__label">Тип счета</p>
          <SelectBox
            className={cx('filters__select', 'filters__select--type')}
            options={MENU.accounts.childrens.map(item => ({
              value: item.name,
              label: item.title,
            }))}
            name="accountType"
            value={this.props.currentType}
            clearable={false}
            searchable={false}
            onChange={this.changeTypeHandler}
            placeholder="Выберите тип счета"
            noResultsText="Нет доступных счетов..."
          />
        </div>
        <div className="filters__item filters__item--account">
          <p className="filters__label">Наименование счета, баланс</p>
          <SelectBox
            className={cx('filters__select', 'filters__select--account')}
            isLoading={this.props.accountsFetching}
            disabled={!this.props.currentType}
            options={
              this.props.currentType &&
              this.props.accounts[this.props.currentType] &&
              this.props.accounts[this.props.currentType].map(item => ({
                value: item.id,
                label: getAccountLabel(item),
                account: item,
              }))
            }
            name="account"
            value={this.props.currentAccountId}
            clearable={false}
            searchable={false}
            onChange={this.changeAccountHandler}
            placeholder="Выберите счет"
            noResultsText="Не найдены счета указанного типа"
          />
        </div>
        <div className="filters__item filters__item--period">
          <p className="filters__label">За выбранный период</p>
          <div>
            <DatePicker
              onChange={this.handleChangeDates}
              dateStart={this.props.dateStart}
              dateEnd={this.props.dateEnd}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentType: state.accountsReducer.accountsFilters.type,
  currentAccountId: state.accountsReducer.accountsFilters.accountId,
  dateStart: state.accountsReducer.accountsFilters.dateStart,
  dateEnd: state.accountsReducer.accountsFilters.dateEnd,
  accounts: state.accountsReducer.accounts,
  accountsFetching: state.accountsReducer.accountsFetching,
  minDate: state.accountsReducer.accountsFilters.minDate,
  maxDate: state.accountsReducer.accountsFilters.maxDate,
});

export default connect(mapStateToProps)(AccountFilters);

AccountFilters.propTypes = {
  className: PropTypes.string,
  currentAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentType: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  accounts: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  accountsFetching: PropTypes.bool.isRequired,
  dateStart: PropTypes.instanceOf(moment),
  dateEnd: PropTypes.instanceOf(moment),
  minDate: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
};

AccountFilters.defaultProps = {
  className: '',
  currentAccountId: null,
  currentType: null,
  dateStart: null,
  dateEnd: null,
  minDate: null,
  maxDate: null,
};
