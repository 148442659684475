import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import SelectBox from '../../../../commonComponents/SelectBox';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import Button from '../../../../commonComponents/Button';
import Throbber from '../../../../commonComponents/Throbber';
import Modal from '../../../../commonComponents/Modal';
import Table from '../../../../commonComponents/Table';
import Pagination from '../../../../commonComponents/Pagination';
import { FormControl } from '../../../../commonComponents/FormControl';
import { getCurrencyTitle, getMoneyString, getDateString } from '../../../../utils';
import { submitForm, sendRequest, resetFormData } from '../../actions/forms';


const CURRENCIES = [
  'RUR',
  'EUR',
  'USD',
];

const DEPOSIT_TERMS = [
  {
    value: 3,
    label: '3 месяца',
  },
  {
    value: 6,
    label: '6 месяцев',
  },
  {
    value: 12,
    label: '12 месяцев',
  },
  {
    value: 24,
    label: '24 месяца',
  },
];

class LoanForm extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    monthlyPayment: PropTypes.number,
    error: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
    schedule: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string,
    })).isRequired,
  };

  static defaultProps = {
    error: null,
    monthlyPayment: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      currency: 'RUR',
      term: 12,
      sum: 0,
      page: 1,
    };
  }

  handleChangeCurrency = (value) => {
    this.setState({ currency: value });
    this.props.dispatch(resetFormData());
  };
  handleChangeSum = (value) => {
    this.setState({ sum: value });
    this.props.dispatch(resetFormData());
  };
  handleChangeTerm = (value) => {
    this.setState({ term: value });
    this.props.dispatch(resetFormData());
  };
  isSubmitDisabled = () => !this.state.sum || this.props.fetching;

  submitForm = () => {
    const requestData = {
      formType: 'loan',
      currency: this.state.currency,
      term: this.state.term,
      sum: this.state.sum,
    };

    if (!this.props.monthlyPayment) {
      return this.props.dispatch(submitForm(requestData));
    }

    return this.props.dispatch(sendRequest(requestData));
  };

  renderPaymentData = () => {
    if (!this.props.schedule.length || !this.props.monthlyPayment) {
      return null;
    }
    return (
      <div>
        <BlockLikeInput.RegularText>
          Начиная с {getDateString(this.props.schedule[0].startDate)}:
        </BlockLikeInput.RegularText>
        {' '}
        <BlockLikeInput.BoldText>
          {getMoneyString(this.props.monthlyPayment, this.state.currency)}
        </BlockLikeInput.BoldText>
      </div>
    );
  }

  renderScheduleTable = () => (
    <div>
      <Table
        type="schedule"
        data={{
          schedule: this.props.schedule.map(item => ({
            ...item,
            currency: this.state.currency,
          })).slice((this.state.page - 1) * 10, 10 * this.state.page),
        }}
      />
      <Pagination
        key="pagination"
        items={this.props.schedule}
        current={this.state.page}
        limit={10}
        changePage={page => this.setState({
          page,
        })}
      />
    </div>
  );

  render() {
    return (
      <div className="application-form">
        <FormLine>
          <FormLineColumn size="8">
            <FormControl
              type="money"
              name="sum"
              id="sum"
              label="Сумма займа"
              value={this.state.sum}
              onChange={this.handleChangeSum}
            />
          </FormLineColumn>
          <FormLineColumn size="4">
            <div className="form-control">
              <p className="form-control__label">Валюта</p>
              <SelectBox
                className="transfer-form__accounts-select"
                options={CURRENCIES.map(item => ({
                  value: item,
                  label: getCurrencyTitle(item),
                }))}
                name="currency"
                clearable={false}
                searchable={false}
                onChange={({ value }) => this.handleChangeCurrency(value)}
                value={this.state.currency}
                placeholder="Выберите валюту"
              />
            </div>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <div className="form-control">
              <FormControl.FormLabel>
                Срок займа
              </FormControl.FormLabel>
              <SelectBox
                className="transfer-form__accounts-select"
                options={DEPOSIT_TERMS}
                name="term"
                clearable={false}
                searchable={false}
                onChange={({ value }) => this.handleChangeTerm(value)}
                value={this.state.term}
                placeholder="Выберите срок"
              />
            </div>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>
              Расчетный ежемесячный платеж
            </FormControl.FormLabel>
            <BlockLikeInput>
              {
                this.props.fetching ?
                  <BlockLikeInput.RegularText>
                    <Throbber inline />
                  </BlockLikeInput.RegularText> :
                  this.renderPaymentData()
              }
            </BlockLikeInput>
          </FormLineColumn>
          {!!this.props.schedule.length &&
            <div className="transfer-form__side-control">
              <Button
                onClick={() => {
                  if (this.modal) {
                    this.modal.openModal();
                  }
                }}
                type="inline"
              >
                Посмотреть полный график платежей
              </Button>
            </div>
          }
        </FormLine>
        <FormLine>
          {this.props.error && (
            <FormLineColumn size="12">
              <p className="form-control__error">{this.props.error}</p>
            </FormLineColumn>
          )}
          <FormLineColumn size="12">
            <Button
              type="primary"
              onClick={this.submitForm}
              disabled={this.isSubmitDisabled()}
              block
            >
              {this.props.monthlyPayment ? 'Отправить заявку' : 'Рассчитать'}
            </Button>
          </FormLineColumn>
        </FormLine>
        <Modal ref={(element) => { this.modal = element; }}>
          {this.renderScheduleTable()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  schedule: state.applicationsFormsReducer.loanShedule,
  monthlyPayment: state.applicationsFormsReducer.loanMonthlyPayment,
  error: state.applicationsFormsReducer.loanSheduleError ||
    state.applicationsFormsReducer.requestError,
  fetching: state.applicationsFormsReducer.fetching,
});

export default connect(mapStateToProps)(LoanForm);
