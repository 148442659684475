import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import './assets/styles/styles.scss';
import { COLUMNS, EMPTY_TABLE_TEXT } from '../../constants';
import { getMoneyString, getDateString } from '../../utils';
import ApplicationStatus from '../../modules/Applications/components/Status';
import Button from '../Button';
import { history } from '../../store';

/**
 * Получение значения для текущей колонки в таблице
 * @param {Object} entity - объект данных счета
 * @param {String} columnName - идентификатор текущей колонки
 */
const getValue = (entity, columnName, dataType) => {
  switch (columnName) {
    case 'dateOfCreate':
    case 'dateOfEnd':
      return moment(entity[columnName]).format('DD.MM.YYYY');

    case 'date':
      if (dataType === 'schedule' || dataType === 'shedule') {
        return getDateString(entity[columnName]);
      }
      return moment(entity[columnName]).format('DD.MM.YYYY, HH:mm:ss');

    case 'sum':
    case 'balance':
    case 'accruedIncome':
    case 'incomeToday':
    case 'paidIncome':
    case 'nextPayment':
    case 'loanSum':
    case 'nextPaymentSum':
    case 'overduePayment':
      return (
        <span
          className={cx(
            'table__value',
            'table__value--money',
            `table__value--${columnName}`,
            `table__value--${dataType}`,
          )}
        >
          {getMoneyString(entity[columnName], entity.currency)}
        </span>
      );

    case 'actions':
      return (
        <div className="table__actions">
          {(dataType === 'current' || dataType === 'salary') &&
            entity.balance > 0 && (
              <NavLink
                className={cx('table__action', {
                  'table__action--collapse': dataType !== 'current' && dataType !== 'shares',
                })}
                key="transaction"
                to={{
                  pathname: '/transfers/to-salary',
                  state: {
                    accountId: entity.id,
                  },
                }}
              >
                <span
                  className={cx('table__action-icon', 'table__action-icon--transaction')}
                  title="Перевод на карту"
                />
                Перевод на карту
              </NavLink>
            )}
          <NavLink
            className={cx('table__action', {
              'table__action--collapse': dataType !== 'current' && dataType !== 'shares',
            })}
            key="details"
            to={{
              pathname: '/details',
              state: {
                dataType,
                accountId: entity.id,
                dateOfCreate: entity.dateOfCreate,
              },
            }}
          >
            <span
              className={cx('table__action-icon', 'table__action-icon--details')}
              title="Выписки"
            />
            Выписки
          </NavLink>
          {dataType === 'loan' && (
            <Button
              className="table__action-button"
              size="xs"
              onClick={() => history.push('/transfers/loan', { accountId: entity.id })}
            >
              Погасить досрочно
            </Button>
          )}
          {dataType === 'deposit' && (
            <Button
              className="table__action-button"
              size="xs"
              onClick={() => history.push('/transfers/deposit', { accountId: entity.id })}
            >
              Пополнить вклад
            </Button>
          )}
        </div>
      );

    case 'status':
      return <ApplicationStatus status={entity[columnName]} />;

    case 'title':
      // #TODO: исправить костыль
      // Этим костылем проверяется тип таблицы "Операции", будет работата, пока
      // поле комбинация полей Title и Details есть только у операций
      if (entity.details && typeof entity.details === 'string') {
        return (
          <span>
            <span className="table__operation">{entity.title}</span>
            <br />
            <span className="table__operation table__operation--details">{entity.details}</span>
          </span>
        );
      }
      return entity[columnName];

    default:
      return entity[columnName];
  }
};

const getTableDescription = (accountType) => {
  switch (accountType) {
    case 'salary':
      return [
        <p className="table-description__item" key="accruedIncome">
          <span
            className={cx('table-description__label', 'table-description__label--accruedIncome')}
          />
          Весь фактически начисленный доход (после удержания НДФЛ и членских взносов) на текущую
          дату.
        </p>,
        <p className="table-description__item" key="incomeToday">
          <span
            className={cx('table-description__label', 'table-description__label--incomeToday')}
          />
          Невыплаченный доход (после удержания НДФЛ и членских взносов), рассчитанный на текущую
          дату.
        </p>,
        <p className="table-description__item" key="mark">
          <span className="table-description__label table-description__label--none" />
          Начисленный доход перечисляется автоматически на текущий счет.
        </p>,
      ];

    case 'deposit':
      return [
        <p className="table-description__item" key="paidIncome">
          <span
            className={cx('table-description__label', 'table-description__label--paidIncome')}
          />
          Весь фактически выплаченный доход (после удержания НДФЛ и членских взносов) на текущую
          дату
        </p>,
        <p className="table-description__item" key="nextPayment">
          <span
            className={cx('table-description__label', 'table-description__label--nextPayment')}
          />
          Расчетная сумма ближайшей выплаты
        </p>,
      ];

    case 'loan':
      return [
        <p className="table-description__item" key="nextPayment">
          * Сумма очередного платежа без учета возможной просрочки
        </p>,
      ];

    case 'raising':
      return [
        <p className="table-description__item" key="paidIncome">
          <span
            className={cx('table-description__label', 'table-description__label--paidIncome')}
          />
          Весь фактически выплаченный доход на текущую дату
        </p>,
        <p className="table-description__item" key="nextPayment">
          <span
            className={cx('table-description__label', 'table-description__label--nextPayment')}
          />
          Расчетная сумма ближайшей выплаты
        </p>,
      ];

    default:
      return null;
  }
};

/**
 * Компонент тыбличного представления данных, в зависимости от типа данных, выводящий разные колонки
 */
export default class Table extends React.Component {
  static propTypes = {
    data: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])))).isRequired,
    type: PropTypes.string.isRequired,
  };

  /**
   * Получение колонок для текущего типа данных
   */
  getColumns() {
    const { type } = this.props;

    return COLUMNS[type] || [];
  }

  getNotFoundText() {
    const { type } = this.props;
    try {
      return EMPTY_TABLE_TEXT[type];
    } catch (e) {
      return 'Нет данных';
    }
  }

  render() {
    const { data, type } = this.props;
    console.log('data', data);

    return [
      <table key="table" className="table">
        <thead className="table__head">
          <tr className={cx('table__row', 'table__row--head')}>
            {this.getColumns().map(column => (
              <td
                key={column.name}
                className={cx(
                  'table__cell',
                  'table__cell--head',
                  `table__cell--${this.props.type}-${column.name}`,
                  { 'table__cell--head-actions': column.name === 'actions' },
                )}
              >
                {column.title}
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="table__body">
          {data[type].length > 0 ? (
            data[type].map(item => (
              <tr key={item.id} className={cx('table__row', 'table__row--body')}>
                {this.getColumns().map(column => (
                  <td
                    key={column.name}
                    className={cx(
                      'table__cell',
                      'table__cell--body',
                      `table__cell--${this.props.type}-${column.name}`,
                    )}
                  >
                    {getValue(item, column.name, type)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="table__row table__row--body">
              <td colSpan={this.getColumns().length} className="table__cell table__cell--not-found">
                {this.getNotFoundText()}
              </td>
            </tr>
          )}
        </tbody>
      </table>,
      <div key="description" className="table-description">
        {getTableDescription(type)}
      </div>,
    ];
  }
}
