import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'moment/locale/ru';
import './assets/styles/styles.scss';

const getPeriodValueString = (dateStart, dateEnd) => {
  if (!dateStart || !dateEnd) {
    return '';
  }
  const format = 'DD.MM.YYYY';
  const start = moment(dateStart).format(format);
  const end = moment(dateEnd).format(format);
  return `${start} - ${end}`;
};

export default class DatePicker extends React.PureComponent {
  static propTypes = {
    dateStart: PropTypes.instanceOf(moment),
    dateEnd: PropTypes.instanceOf(moment),
    minDate: PropTypes.instanceOf(moment),
    maxDate: PropTypes.instanceOf(moment),
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    dateStart: null,
    dateEnd: null,
    minDate: null,
    maxDate: null,
    className: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      value: getPeriodValueString(props.dateStart, props.dateEnd),
    };
  }

  componentWillReceiveProps(nextProps) {
    if ((JSON.stringify(this.props.dateStart) !== JSON.stringify(nextProps.dateStart)) ||
      (JSON.stringify(this.props.dateEnd) !== JSON.stringify(nextProps.dateEnd))) {
      this.setState({
        value: getPeriodValueString(nextProps.dateStart, nextProps.dateEnd),
      });
    }
  }

  isDisabled = () => !this.props.minDate;

  changeHandler = dates => this.props.onChange(dates);

  render() {
    return (
      <div className={cx('datepicker', { [this.props.className]: this.props.className })}>
        <input
          className={cx('datepicker__input', { 'datepicker__input--disabled': this.isDisabled() })}
          onFocus={() => this.setState({ focused: true })}
          readOnly
          type="text"
          value={this.state.value}
          placeholder="Выберите период"
          disabled={this.isDisabled()}
        />
        <div className={cx('datepicker__calendar', { 'datepicker__calendar--is-open': this.state.focused })}>
          <DateRange
            classNames={{
              dateRange: 'calendar',
              calendar: 'calendar__container',
              days: 'calendar__days',
              day: 'calendar__day',
              dayInRange: 'calendar__day--in-range',
              dayActive: 'calendar__day--active',
              dayPassive: 'calendar__day--passive',
              weekDays: 'calendar__weekdays',
              weekDay: 'calendar__weekday',
              monthAndYearWrapper: 'calendar__month-and-year',
              month: 'calendar__month',
              year: 'calendar__year',
              monthAndYearDivider: 'calendar__divider',
              prevButton: 'calendar__button calendar__button--prev',
              nextButton: 'calendar__button calendar__button--next',
            }}
            startDate={this.props.dateStart}
            endDate={this.props.dateEnd}
            format="DD.MM.YYYY"
            lang="ru"
            calendars={1}
            linkedCalendars
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            onlyClasses
            onChange={this.changeHandler}
          />
        </div>
        {this.state.focused &&
          <div
            onClick={() => this.setState({ focused: false })}
            onKeyPress={() => {}}
            className="datepicker__overlay"
            role="button"
            tabIndex="-1"
          />
        }
      </div>
    );
  }
}
