import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './assets/styles/styles.scss';
import { logout } from '../../actions';
import Throbber from '../../../../commonComponents/Throbber';
import Button from '../../../../commonComponents/Button';

class SessionModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    fetching: PropTypes.bool.isRequired,
  }

  exitHandler = () => this.props.dispatch(logout());

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div className="session-modal">
        <div className="session-modal__window">
          <div className="session-modal__content">
            <p className="session-modal__heading">
              Ваша сессия истекла
            </p>
            <p className="session-modal__info">
              Для продолжения работы в личном кабинете
              <br />
              необходимо заново войти в систему
            </p>
            <div className="session-modal__controls">
              <Button
                onClick={this.exitHandler}
                type="primary"
                disabled={this.props.fetching}
              >
                Войти в личный кабинет
              </Button>
            </div>
          </div>
          {this.props.fetching &&
            <Throbber className="session-modal__throbber" />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  show: state.appReducer.sessionExpired,
  fetching: state.appReducer.fetching,
});

export default connect(mapStateToProps)(SessionModal);
