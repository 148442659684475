import { BASE_API_URL } from '../../../constants';
import { resultDataToCamelCase, getCookie } from '../../../utils';

const getFetchConfig = () => {
  const token = getCookie('access_token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const authSuccess = () => ({
  type: 'AUTH_SUCCESS',
});

export const fetchFail = error => (dispatch) => {
  dispatch({
    type: 'FETCH_FAIL',
    payload: error,
  });
};

export const getAccountsListing = accountType => (dispatch) => {
  const token = getCookie('access_token');

  if (!token) {
    return;
  }

  /** Формируем URL для запроса счетов в зависимости от типа */
  let accountsUrl = `${BASE_API_URL}/accounts/`;
  switch (accountType) {
    case 'current':
      accountsUrl += 'current';
      break;

    case 'shares':
      accountsUrl += 'share';
      break;

    case 'salary':
      accountsUrl += 'salary';
      break;

    case 'deposit':
      accountsUrl += 'deposit';
      break;

    case 'loan':
      accountsUrl += 'loan';
      break;

    case 'raising':
      accountsUrl += 'raising';
      break;

    default:
      /** На всякий случай по дефолту загрузим текущие счета */
      accountsUrl += 'current';
      break;
  }

  dispatch({
    type: 'ACCOUNTS_FETCH_START',
  });

  /** Получаем счета с сервера */
  fetch(accountsUrl, getFetchConfig())
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then((json) => {
      dispatch({
        type: 'ACCOUNTS_FETCH_SUCCESS',
        payload: {
          accountType,
          data: resultDataToCamelCase(json),
        },
      });
    })
    .catch(error =>
      dispatch({
        type: 'ACCOUNTS_FETCH_FAIL',
        payload: error.message,
      }));
};

export const getDetails = (accountType, accountId) => (dispatch) => {
  if (!(accountType && accountId)) {
    return;
  }

  let accountsUrl = `${BASE_API_URL}/accounts/`;
  switch (accountType) {
    case 'current':
      accountsUrl += 'current';
      break;

    case 'share':
      accountsUrl += 'share';
      break;

    case 'salary':
      accountsUrl += 'salary';
      break;

    case 'deposit':
      accountsUrl += 'deposit';
      break;

    case 'loan':
      accountsUrl += 'loan';
      break;

    case 'raising':
      accountsUrl += 'raising';
      break;

    case 'pension':
      accountsUrl += 'pension';
      break;

    default:
      /** На всякий случай по дефолту загрузим текущие счета */
      accountsUrl += 'current';
      break;
  }

  accountsUrl += `/${accountId}/details`;

  dispatch({
    type: 'ACCOUNT_DETAILS_FETCH_START',
  });

  fetch(accountsUrl, getFetchConfig())
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then((json) => {
      dispatch({
        type: 'ACCOUNT_DETAILS_FETCH_SUCCESS',
        payload: resultDataToCamelCase(json),
      });
    })
    .catch(error =>
      dispatch({
        type: 'ACCOUNT_DETAILS_FETCH_FAIL',
        payload: error.message,
      }));
};

export const setAccountFilters = data => ({
  type: 'SET_ACCOUNT_FILTERS',
  payload: data,
});

const balanceDetailsSuccess = data => ({
  type: 'BALANCE_DETAILS_SUCCESS',
  payload: data,
});

const operationsFetchSuccess = data => ({
  type: 'OPERATIONS_FETCH_SUCCESS',
  payload: data,
});

const sheduleFetchSuccess = data => ({
  type: 'SHEDULE_FETCH_SUCCESS',
  payload: data,
});

export const getBalanceDetails = id => (dispatch, getState) => {
  const state = getState();

  if (
    !state.accountsReducer.accountsFilters.dateStart ||
    !state.accountsReducer.accountsFilters.dateEnd
  ) {
    return;
  }

  const dateStart = state.accountsReducer.accountsFilters.dateStart.format('YYYY-MM-DD');
  const dateEnd = state.accountsReducer.accountsFilters.dateEnd.format('YYYY-MM-DD');

  fetch(
    `${BASE_API_URL}/accounts/${id}/details?dateFrom=${dateStart}&dateTo=${dateEnd}`,
    getFetchConfig(),
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      /** Если не ОК, рэйзим ошибку из ответа с сервака */
      return response.json().then((json) => {
        throw new Error(resultDataToCamelCase(json).message);
      });
    })
    .then((json) => {
      const result = resultDataToCamelCase(json);
      dispatch(balanceDetailsSuccess(result));
    })
    .catch(error => dispatch(fetchFail(error)));
};

export const getOperations = id => (dispatch, getState) => {
  const state = getState();
  const { dateStart, dateEnd } = state.accountsReducer.accountsFilters;

  if (!dateStart || !dateEnd) {
    return;
  }

  fetch(
    `${BASE_API_URL}/accounts/${id}/operations?dateFrom=${dateStart.format('YYYY-MM-DD')}&dateTo=${dateEnd.format('YYYY-MM-DD')}`,
    getFetchConfig(),
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      /** Если не ОК, рэйзим ошибку из ответа с сервака */
      return response.json().then((json) => {
        throw new Error(resultDataToCamelCase(json).message);
      });
    })
    .then((json) => {
      const result = {
        operations: resultDataToCamelCase(json.Operations),
        count: resultDataToCamelCase(json.Count),
      };
      dispatch(operationsFetchSuccess(result));
    })
    .catch(error => dispatch(fetchFail(error)));
};

export const getShedule = id => (dispatch, getState) => {
  const state = getState();
  const { dateStart, dateEnd } = state.accountsReducer.accountsFilters;

  if (!dateStart || !dateEnd) {
    return;
  }

  fetch(`${BASE_API_URL}/accounts/loan/${id}/shedule`, getFetchConfig())
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      /** Если не ОК, рэйзим ошибку из ответа с сервака */
      return response.json().then((json) => {
        throw new Error(resultDataToCamelCase(json).message);
      });
    })
    .then((json) => {
      const result = {
        shedule: resultDataToCamelCase(json.Payments),
        sheduleCount: resultDataToCamelCase(json.Count),
      };
      dispatch(sheduleFetchSuccess(result));
    })
    .catch(error => dispatch(fetchFail(error)));
};

export const clearAccountDetails = () => ({
  type: 'CLEAR_ACCOUNT_DETAILS',
});

export const resetDetailsAndFilters = () => ({
  type: 'RESET_DETAILS_AND_FILTERS',
});
