import React from 'react';
import PropTypes from 'prop-types';
import TransferFormSalary from '../../components/TransferFormSalary';
import TransferFormInner from '../../components/TransferFormInner';
import TransferFormLoan from '../../components/TransferFormLoan';
import TransferFormDeposit from '../../components/TransferFormDeposit';
import TransferFormCurrency from '../../components/TransferFormCurrency';
import TransferFormPartner from '../../components/TransferFormPartner/index.tsx';
import './assets/styles/styles.scss';

const renderForm = (transferType, initialAccount) => {
  switch (transferType) {
    case 'to-salary':
      return (
        <TransferFormSalary className="transfers-page__form" initialAccount={initialAccount} />
      );

    case 'to-account':
      return <TransferFormInner className="transfers-page__form" initialAccount={initialAccount} />;

    case 'loan':
      return (
        <TransferFormLoan
          className="transfers-page__form transfers-page__form--loan"
          initialAccount={initialAccount}
        />
      );

    case 'deposit':
      return <TransferFormDeposit initialAccount={initialAccount} />;

    case 'currency':
      return <TransferFormCurrency initialAccount={initialAccount} />;

    case 'to-partner':
      return <TransferFormPartner initialAccount={initialAccount} />;

    default:
      return null;
  }
};

export default class Transfers extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        transferType: PropTypes.oneOf([
          'to-salary',
          'to-account',
          'loan',
          'deposit',
          'currency',
          'to-partner',
        ]),
      }),
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        accountId: PropTypes.number,
      }),
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.getHeading = this.getHeading.bind(this);
    this.getTransferType = this.getTransferType.bind(this);

    this.initialAccount = this.props.location.state ? this.props.location.state.accountId : null;
  }

  componentDidMount() {
    document.title = 'Платежи и переводы';
  }

  getHeading() {
    const transferType = this.getTransferType();
    switch (transferType) {
      case 'to-salary':
        return 'Перевод средств на зарплатную карту';

      case 'to-account':
        return 'Перевод между своими счетами';

      case 'loan':
        return 'Досрочное погашение займа';

      case 'deposit':
        return 'Пополнение вклада';

      case 'currency':
        return 'Конвертация валюты';

      case 'to-partner':
        return 'Перевод другому пайщику кооператива';

      default:
        return '---';
    }
  }

  getTransferType() {
    const { transferType = 'currency' } = this.props.match.params;

    return transferType;
  }

  render() {
    const transferType = this.getTransferType();
    return (
      <div className="transfers-page">
        <h1 key="heading" className="transfers-page__heading">
          {this.getHeading()}
        </h1>
        <div key="content" className="transfers-page__content">
          {renderForm(transferType, this.initialAccount)}
        </div>
      </div>
    );
  }
}
