import React from 'react';
import Icon from '../../../../commonComponents/Icon';
import { DEFAULT_STRING_VALUE } from '../../../../constants';
import './assets/styles/styles.scss';

const RejectedStatus = () => (
  <div className="application-status application-status--rejected">
    <span className="application-status__icon"><Icon name="times-circle" /></span>
    {' '}
    <span className="application-status__label application-status__label--rejected">Отклонено</span>
  </div>
);
const CompletedStatus = () => (
  <div className="application-status application-status--completed">
    <span className="application-status__icon"><Icon name="check-circle" /></span>
    {' '}
    <span className="application-status__label application-status__label--completed">Выполнено</span>
  </div>
);
const InProcessStatus = () => (
  <div className="application-status application-status--in-process">
    <span className="application-status__icon"><Icon name="sync-alt" /></span>
    {' '}
    <span className="application-status__label application-status__label--in-process">Новое</span>
  </div>
);
const Canceled = () => (
  <div className="application-status application-status--canceled">
    <span className="application-status__label application-status__label--canceled">Отменено</span>
  </div>
);

const ApplicationStatus = ({ status }) => {
  const statuses = {
    0: <InProcessStatus />,
    1: <CompletedStatus />,
    2: <RejectedStatus />,
    3: <Canceled />,
  };

  return statuses[status] || DEFAULT_STRING_VALUE;
};

export default ApplicationStatus;
