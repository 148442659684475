import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import { FormControl } from '../../../../commonComponents/FormControl';
import Throbber from '../../../../commonComponents/Throbber';
import './assets/styles/styles.scss';
import { changeUserSettings } from '../../../App/actions';
import {
  EMAIL_REGEX,
  PHONE_REGEX,
} from '../../../../utils';
import PhoneInput from '../../../../commonComponents/PhoneInput';

class UserSettingsForm extends React.Component {
  static propTypes = {
    address: PropTypes.string,
    birthday: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneCountryId: PropTypes.number,
    name: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    settingsError: PropTypes.string,
    saved: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    settingsError: null,
    address: '',
    birthday: '',
    email: '',
    phone: '',
    name: '',
    phoneNumber: '',
    phoneCountryId: 0,
  }

  constructor(props) {
    super(props);

    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);

    this.state = {
      userName: props.name || '',
      userBirthday: props.birthday || '',
      userEmail: props.email || '',
      userAddress: props.address || '',
      password: '',
      emailError: '',
      phoneError: '',
      passwordError: '',
      phoneNumber: props.phoneNumber || '',
      phoneCountryId: props.phoneCountryId || 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({
      userName: !state.userName ? (nextProps.name || '') : state.userName,
      userBirthday: !state.userBirthday ? (nextProps.birthday || '') : state.userBirthday,
      userEmail: !state.userEmail ? (nextProps.email || '') : state.userEmail,
      userAddress: !state.userAddress ? (nextProps.address || '') : state.userAddress,
      phoneCountryId: !state.phoneCountryId ? (nextProps.phoneCountryId || '') : state.phoneCountryId,
      phoneNumber: !state.phoneNumber ? (nextProps.phoneNumber || '') : state.phoneNumber,
    }));
  }

  formChangeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value,
      emailError: '',
      phoneError: '',
      passwordError: '',
    });
  }

  formSubmitHandler(event) {
    event.preventDefault();
    let hasError = false;

    if (!EMAIL_REGEX.test(this.state.userEmail)) {
      this.setState({
        emailError: 'Некорректный адрес электронной почты',
      });
      hasError = true;
    }

    if (!this.state.phoneNumber) {
      this.setState({
        phoneError: 'Некорректный номер телефона',
      });
      hasError = true;
    }

    if (this.state.password.length === 0) {
      this.setState({
        passwordError: 'Поле не может быть пустым',
      });
      hasError = true;
    }

    const requestData = {
      PhoneCountryId: this.state.phoneCountryId,
      Phone: this.state.phoneNumber,
      email: this.state.userEmail,
      password: this.state.password,
    };

    if (!hasError) {
      this.props.dispatch(changeUserSettings(requestData));
    }
  }

  onChangePhoneInput = (phoneNumber, additionalData) => {
    this.setState({
      phoneNumber,
      phoneCountryId: additionalData.id,
    })
  }

  render() {
    if (this.props.loading) {
      return <Throbber inPanel />;
    }
    return (
      <form onSubmit={this.formSubmitHandler} className="user-settings">
        <label htmlFor="userName" className="user-settings__item">
          <span className="user-settings__label">Фамилия и имя</span>
          <FormControl
            className="user-settings__input"
            type="text"
            name="userName"
            id="userName"
            value={this.state.userName}
            disabled
            onChange={() => {}}
          />
        </label>
        <label htmlFor="userBirthday" className="user-settings__item">
          <span className="user-settings__label">Дата рождения</span>
          <FormControl
            className="user-settings__input"
            type="text"
            name="userBirthday"
            id="userBirthday"
            value={this.state.userBirthday ? moment(this.state.userBirthday).format('DD MMMM YYYY') : ''}
            disabled
            onChange={() => {}}
          />
        </label>
        <label htmlFor="userPhone" className="user-settings__item">
          <span className="user-settings__label">Мобильный телефон</span>
          <PhoneInput
            hasError={this.state.phoneError}
            value={this.state.phoneNumber}
            PhoneCountryId={this.state.phoneCountryId}
            onChange={this.onChangePhoneInput}
            disableCountryCode
          />
        </label>
        <label htmlFor="userEmail" className="user-settings__item">
          <span className="user-settings__label">Электронная почта</span>
          <FormControl
            className="user-settings__input"
            type="text"
            name="userEmail"
            id="userEmail"
            required
            autoComplete="off"
            value={this.state.userEmail}
            error={this.state.emailError}
            onChange={this.formChangeHandler}
          />
        </label>
        <label htmlFor="userAddress" className="user-settings__item user-settings__item--address">
          <span className="user-settings__label">Адрес</span>
          <FormControl
            className="user-settings__input"
            type="text"
            name="userAddress"
            id="userAddress"
            value={this.state.userAddress}
            disabled
            onChange={() => {}}
          />
        </label>
        <label htmlFor="password" className="user-settings__item">
          <span className="user-settings__label">Введите текущий пароль</span>
          <FormControl
            className="user-settings__input"
            type="password"
            name="password"
            id="password"
            value={this.state.password}
            autoComplete="off"
            onChange={this.formChangeHandler}
            error={this.state.passwordError}
          />
        </label>
        <p className="info-text user-settings__item user-settings__item--info">
          Для изменения телефона или адреса электронной почты вам нужно ввести текущий пароль
        </p>
        {this.props.settingsError &&
          <p className="form-control__error user-settings__error">{this.props.settingsError}</p>
        }
        <input
          type="submit"
          className="button user-settings__button"
          value="Сохранить изменения"
        />
        {(!this.props.settingsError && this.props.saved) &&
          <p className="user-settings__success">
            <span className="user-settings__success-icon" />  Настройки успешно обновлены
          </p>
        }
      </form>
    );
  }
}

const mapStateToProps = state => ({
  address: state.appReducer.user.address,
  birthday: state.appReducer.user.birthday,
  email: state.appReducer.user.email,
  number: state.appReducer.user.number,
  phoneCountryId: state.appReducer.user.phoneCountryId,
  phoneNumber: state.appReducer.user.phoneNumber,
  phone: state.appReducer.user.phone,
  name: state.appReducer.user.name,
  settingsError: state.appReducer.settingsError,
  saved: state.appReducer.userSettingsSaved,
  loading: state.appReducer.userFetching,
});

export default connect(mapStateToProps)(UserSettingsForm);
