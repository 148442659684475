import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { FormControl } from '../../../../commonComponents/FormControl';
import SelectBox from '../../../../commonComponents/SelectBox';
import Button from '../../../../commonComponents/Button';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import BlockLikeInput from '../../../../commonComponents/BlockLikeInput';
import Typography from '../../../../commonComponents/Typography';
import SetMaxSum from '../SetMaxSum';
import { getAccountLabelTransfer, getMoneyString, declOfNum } from '../../../../utils';
import {
  getAccounts,
  changeAccount,
  setTransferSum,
  submitTransferForm,
} from '../../actions/currencyTransfer';
import ConfirmMethodControls from '../ConfirmMethodControls';
import './assets/styles/styles.scss';
import Icon from '../../../../commonComponents/Icon';
import getRate, { promiseHelper } from './utils';
import FormOverlay from './overlay';

const TIMER = 30;

class TransferFormCurrency extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string,
    accountFrom: PropTypes.number,
    accountTo: PropTypes.number,
    transferSum: PropTypes.number.isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })).isRequired,
    transferFormError: PropTypes.string,
    initialAccount: PropTypes.number,
    fetching: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: null,
    accountFrom: null,
    accountTo: null,
    transferFormError: null,
    initialAccount: null,
  };

  constructor(props) {
    super(props);

    this.timer = null;
    this.promiseHelper = promiseHelper;

    this.state = {
      sumError: null,
      accountFromError: null,
      accountToError: null,
      confirmMethod: 'sms',
      counter: TIMER,
      rateList: [],
      currency: null,
      ratesIsNotAvailable: false,
    };
  }

  componentDidMount() {
    this.fetchRate();

    if (this.props.accountFrom) {
      this.setCurrency();
    }

    this.props.dispatch(getAccounts());
    if (this.props.initialAccount) {
      this.props.dispatch(changeAccount(this.props.initialAccount, 'from'));
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.accounts.length && prevProps.accounts.length) {
      this.props.dispatch(getAccounts());
    }

    if (prevProps.accountFrom !== this.props.accountFrom && this.props.accountFrom) {
      this.fetchRate();
    }

    if (
      prevProps.accountFrom !== this.props.accountFrom ||
      prevProps.accounts.length !== this.props.accounts.length
    ) {
      if (this.props.accountFrom && !!this.props.accounts.length);
      this.setCurrency();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.promiseHelper.cancel('changeRate');
  }

  setMaxSum = (sum) => {
    this.setState({
      sumError: null,
    });
    this.props.dispatch(setTransferSum(sum));
  };

  setCurrency = () => {
    const currentAccountFrom = this.props.accounts.find(({ id }) => id === this.props.accountFrom);

    if (currentAccountFrom) {
      this.setState({ currency: currentAccountFrom.currencyName });
    }
  };

  getRate = () => {
    if (this.state.rateList.length) {
      const rate = this.state.rateList.find(({ currencyName }) => currencyName === `${this.state.currency}Inner`);
      return rate ? rate.value || 0 : 0;
    }

    return 0;
  };

  resetErrors = (callback) => {
    this.setState({
      sumError: null,
      accountFromError: null,
      accountToError: null,
    }, () => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  handleChangeAccount = ({ value: id }, direction) => {
    this.resetErrors(() => {
      this.props.dispatch(changeAccount(id, direction));
    });
  };

  handleChangeSum = (value) => {
    this.setState({
      sumError: null,
    }, () => {
      this.props.dispatch(setTransferSum(value));
    });
  };

  formHasError = () => {
    const {
      sumError,
      accountFromError,
      accountToError,
    } = this.state;

    return sumError || accountFromError || accountToError;
  }

  validateSumm = (maxSum) => {
    const sum = parseFloat(this.props.transferSum);
    if (sum <= 0 || !sum) {
      return 'Укажите сумму конвертации';
    }

    if (sum > maxSum) {
      return 'На вашем счете недостаточно средств';
    }

    return null;
  };

  validateAccoutFrom = () => {
    const account = this.props.accountFrom;

    if (!account) {
      return 'Выберите счет снятия';
    }

    return null;
  }

  validateAccoutTo = () => {
    const account = this.props.accountTo;

    if (!account) {
      return 'Выберите счет зачисления';
    }

    return null;
  }

  submitTransferForm = (maxSum) => {
    if (this.state.ratesIsNotAvailable) {
      return;
    }

    this.setState({
      sumError: this.validateSumm(maxSum),
      accountFromError: this.validateAccoutFrom(),
      accountToError: this.validateAccoutTo(),
    }, () => {
      if (this.formHasError()) {
        return;
      }

      this.props.dispatch(submitTransferForm(this.state.confirmMethod));
    });
  };

  submitHasDisabled = () => {
    const { accountFrom, accountTo, transferSum } = this.props;
    return !!(
      !accountFrom ||
      !transferSum ||
      !accountTo ||
      !this.state.rateList.length ||
      this.state.rateLoading
    );
  };

  handleChangeConfirmMethod = (event) => {
    this.setState({
      confirmMethod: event.target.value,
    });
  };

  startCounter = () => {
    this.stopCounter();
    this.tick();
  };

  stopCounter = () => {
    this.setState({ counter: TIMER }, () => clearTimeout(this.timer));
  };

  tick = () => {
    if (this.state.counter === 0) {
      this.setState({ rateList: [] }, this.stopCounter);
      return;
    }
    this.setState(
      state => ({
        counter: state.counter - 1,
      }),
      () => {
        this.timer = setTimeout(this.tick, 1000);
      },
    );
  };

  fetchRate = () => {
    if (this.state.rateLoading) {
      return;
    }

    this.setState({ rateList: [], rateLoading: true }, this.stopCounter);

    getRate()
      .then((result) => {
        this.setState(
          {
            rateList: result.currenciesList,
            rateLoading: false,
          },
          () => {
            this.startCounter();
            this.checkRates();
          },
        );
      })
      .catch(() => {
        this.setState({ rateLoading: false }, () => {
          this.stopCounter();
          this.checkRates();
        });
      });
  };

  checkRates = () => {
    const ratesIsNotAvailable = this.state.rateList.every(item => item.value === 0);

    this.setState({
      ratesIsNotAvailable,
    });
  }

  renderRate = () => {
    const currentRate = this.getRate();

    if (currentRate && this.props.accountFrom) {
      return typeof currentRate === 'number'
        ? currentRate.toLocaleString('ru-RU', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        })
        : currentRate;
    }

    return '--';
  };

  render() {
    const selectedAccount =
      this.props.accounts.find(item => item.id === this.props.accountFrom) || {};
    const targetAccounts = selectedAccount.avaliableAccounts || [];
    const selectedTargetAccount =
      targetAccounts.find(item => item.id === this.props.accountTo) || {};

    const sumFieldIsDisabled = !selectedAccount.balance || !this.state.rateList.length;

    return (
      <div className={cx('transfer-form', { [this.props.className]: this.props.className })}>
        {this.state.ratesIsNotAvailable && <FormOverlay />}
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>Счёт снятия</FormControl.FormLabel>
            <SelectBox
              options={this.props.accounts.map(item => ({
                value: item.id,
                label: getAccountLabelTransfer(item),
              }))}
              name="accountType"
              clearable={false}
              searchable={false}
              onChange={event => this.handleChangeAccount(event, 'from')}
              value={this.props.accountFrom}
              placeholder="Выберите счет"
              error={!!this.state.accountFromError}
            />
            <FormControl.FormError>
              {this.state.accountFromError}
            </FormControl.FormError>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>Счёт зачисления</FormControl.FormLabel>
            <SelectBox
              options={targetAccounts.map(item => ({
                value: item.id,
                label: `${item.accountName}, ${item.currencyName}`,
              }))}
              name="accountType"
              clearable={false}
              searchable={false}
              onChange={event => this.handleChangeAccount(event, 'to')}
              value={this.props.accountTo}
              placeholder="Выберите счет"
              disabled={!targetAccounts.length}
              error={!!targetAccounts.length && !!this.state.accountToError}
            />
            <FormControl.FormError>
              {targetAccounts.length && this.state.accountToError}
            </FormControl.FormError>
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl
              type="money"
              name="transferSumm"
              id="transferSumm"
              label="Хочу обменять"
              value={this.props.transferSum}
              onChange={this.handleChangeSum}
              error={sumFieldIsDisabled ? '' : this.state.sumError}
              disabled={sumFieldIsDisabled}
              onFocus={this.resetErrors}
            />
          </FormLineColumn>
          {selectedAccount.balance > 0 && (
            <FormLineColumn size="12">
              <SetMaxSum
                onClick={() => this.setMaxSum(selectedAccount.balance)}
                label={
                  <span>
                    Конвертировать все{' '}
                    <Typography.Link>
                      {getMoneyString(selectedAccount.balance, selectedAccount.currencyName)}
                    </Typography.Link>
                  </span>
                }
              />
            </FormLineColumn>
          )}
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl.FormLabel>Курс обмена</FormControl.FormLabel>
            <BlockLikeInput className="transfer-currency__rate-input">
              <BlockLikeInput.RegularText>{this.renderRate()}</BlockLikeInput.RegularText>
              {!!this.props.accountFrom && (
                <BlockLikeInput.RegularText>
                  <Button type="inline" onClick={this.fetchRate}>
                    <Icon name="redo" /> Обновить курс
                  </Button>
                </BlockLikeInput.RegularText>
              )}
            </BlockLikeInput>
          </FormLineColumn>
          {(!!this.state.rateList.length && !!this.props.accountFrom) && (
            <FormLineColumn size="12">
              <div className="transfer-currency__counter-container">
                {this.state.rateLoading ? (
                  <small>Курс обновляется...</small>
                ) : (
                  <small>
                    Курс действителен{' '}
                    <strong>
                      {this.state.counter}{' '}
                      {declOfNum(this.state.counter, ['секунду', 'секунды', 'секунд'])}
                    </strong>
                  </small>
                )}
              </div>
            </FormLineColumn>
          )}
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <FormControl
              type="money"
              name="transferSumm"
              id="transferSumm"
              label="К зачислению после обмена"
              value={(this.props.transferSum * this.getRate()).toFixed(2)}
              onChange={(value) => {
                const transferSum = value / (selectedTargetAccount.rate || 1);

                this.handleChangeSum(parseFloat(transferSum.toFixed(2)));
              }}
              error={sumFieldIsDisabled ? '' : this.state.sumError}
              disabled={sumFieldIsDisabled}
              onFocus={this.resetErrors}
            />
          </FormLineColumn>
        </FormLine>
        <FormLine>
          <FormLineColumn size="12">
            <ConfirmMethodControls
              changeHandler={this.handleChangeConfirmMethod}
              selectedMethod={this.state.confirmMethod}
            />
          </FormLineColumn>
        </FormLine>
        {this.props.transferFormError && (
          <p className="form-control__error">{this.props.transferFormError}</p>
        )}
        <FormLine>
          <FormLineColumn size="12">
            <Button
              type="primary"
              onClick={() => this.submitTransferForm(selectedAccount.balance)}
              fakeDisabled={this.submitHasDisabled()}
              block
            >
              {this.props.fetching ? 'Подождите...' : 'Подтвердить операцию'}
            </Button>
          </FormLineColumn>
        </FormLine>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.transfersReducer.fetching,
  accounts: state.transfersReducerCurrency.accounts,
  accountFrom: state.transfersReducerCurrency.accountFrom,
  accountTo: state.transfersReducerCurrency.accountTo,
  transferSum: state.transfersReducerCurrency.transferSum,
  transferFormError: state.transfersReducerCurrency.transferFormError,
});

export default connect(mapStateToProps)(TransferFormCurrency);
