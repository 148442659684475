import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../../../commonComponents/Page';
import DepositForm from '../../components/DepositForm';
import LoanForm from '../../components/LoanForm';


export default class ApplicationsForms extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        type: PropTypes.string,
      }),
    }).isRequired,
  }

  getHeading = () => {
    switch (this.props.location.state.type) {
      case 'deposit':
        document.title = 'Вклады';
        return 'Заявка на размещение вклада';

      case 'loan':
        return 'Заявка на получение займа';

      default:
        return null;
    }
  };

  getForm = () => {
    switch (this.props.location.state.type) {
      case 'deposit':
        return <DepositForm />;

      case 'loan':
        return <LoanForm />;

      default:
        return null;
    }
  };

  render() {
    return (
      <Page>
        <Page.Heading>
          {this.getHeading()}
        </Page.Heading>
        <Page.Content>
          {this.getForm()}
        </Page.Content>
      </Page>
    );
  }
}
