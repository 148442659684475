import { push } from 'react-router-redux';
import { resultDataToCamelCase, getCookie } from '../../../utils';
import { BASE_API_URL, CONFIRM_TYPES } from '../../../constants';
import { fetchStart, fetchStop } from './';

export const getAccounts = () => (dispatch) => {
  const token = getCookie('access_token');
  if (!token) {
    return;
  }

  dispatch(fetchStart());

  fetch(`${BASE_API_URL}/transfer/loan`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      /** Если не ОК, рэйзим ошибку из ответа с сервака */
      return response.json().then((json) => {
        throw new Error(json);
      });
    })
    .then((json) => {
      dispatch(fetchStop());
      dispatch({
        type: 'loanTransfer/ACCOUNTS_LOADED',
        payload: resultDataToCamelCase(json),
      });
    })
    .catch((error) => {
      dispatch({
        type: 'loanTransfer/ACCOUNTS_LOADING_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const changeAccount = (id, direction) => {
  if (direction === 'loan') {
    return {
      type: 'loanTransfer/CHANGE_ACCOUNT_LOAN',
      payload: id,
    };
  }

  if (direction === 'debit') {
    return {
      type: 'loanTransfer/CHANGE_ACCOUNT_DEBIT',
      payload: id,
    };
  }

  return null;
};

export const setTransferSum = sum => ({
  type: 'loanTransfer/SET_TRANSFER_SUM',
  payload: sum,
});

export const changeAgreement = () => ({
  type: 'loanTransfer/CHANGE_AGREEMENT',
});

export const submitForm = confirmMethod => (dispatch, getState) => {
  const {
    loanAccount, debitAccount, transferSum,
  } = getState().transfersReducerLoan;
  const token = getCookie('access_token');

  if (!token || !CONFIRM_TYPES[confirmMethod]) {
    return;
  }

  const requestData = {
    CodeType: CONFIRM_TYPES[confirmMethod],
    FromBill: debitAccount,
    ToBill: loanAccount,
    Sum: transferSum,
  };
  dispatch(fetchStart());
  fetch(`${BASE_API_URL}/transfer/loan/request-code`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (response.ok) {
        dispatch(fetchStop());
        dispatch(push('/transfers/confirm', {
          transferType: 'loan',
          transferData: requestData,
        }));
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
      /** Если не ОК, рэйзим ошибку из ответа с сервака */
      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .catch((error) => {
      dispatch({
        type: 'loanTransfer/SUBMIT_FORM_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};

export const getShedule = () => (dispatch, getState) => {
  const state = getState().transfersReducerLoan;
  const id = state.loanAccount;
  const sum = state.transferSum;
  const token = getCookie('access_token');

  if (!sum || !id || !token) {
    return;
  }

  dispatch(fetchStart());
  dispatch({
    type: 'loanTransfer/CLEAR_SCHEDULE',
  });
  fetch(`${BASE_API_URL}/transfer/loan/shedule?id=${id}&sum=${sum}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then((json) => {
      const result = resultDataToCamelCase(json);
      dispatch(fetchStop());
      dispatch({
        type: 'loanTransfer/SCHEDULE_SUCCESS',
        payload: result.payments || [],
      });
    })
    .catch((error) => {
      dispatch({
        type: 'loanTransfer/SCHEDULE_FAIL',
        payload: error.message,
      });
      dispatch(fetchStop());
    });
};
