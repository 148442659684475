import { push } from 'react-router-redux';
import {
  resultDataToCamelCase,
  getCookie,
} from '../../../utils';
import {
  BASE_API_URL,
  ACCOUNT_TYPES_FOR_TRANSFER,
  CONFIRM_TYPES,
} from '../../../constants';
import {
  fetchStart,
  fetchStop,
} from './';

export const getAccounts = () => (dispatch) => {
  const token = getCookie('access_token');
  if (!token) {
    return;
  }

  dispatch(fetchStart());

  const requests = ACCOUNT_TYPES_FOR_TRANSFER.map(item => fetch(
    `${BASE_API_URL}/transfer/${item}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }));

  Promise.all(requests).then((json) => {
    dispatch(fetchStop());
    dispatch({
      type: 'salaryTransfer/ACCOUNTS_LOADED_SUCCESS',
      payload: resultDataToCamelCase(json.reduce((a, b) => a.concat(b))),
    });
  }).catch((error) => {
    dispatch({
      type: 'salaryTransfer/ACCOUNTS_LOADED_FAIL',
      payload: error.message,
    });
    dispatch(fetchStop());
  });
};

export const changeAccount = id => ({
  type: 'salaryTransfer/CHANGE_ACCOUNT',
  payload: id,
});

export const setTransferSum = sum => ({
  type: 'salaryTransfer/SET_TRANSFER_SUM',
  payload: sum,
});

export const submitTransferForm = confirmMethod => (dispatch, getState) => {
  const token = getCookie('access_token');

  if (!token || !CONFIRM_TYPES[confirmMethod]) {
    return;
  }
  dispatch(fetchStart());

  const { accountFrom, transferSum } = getState().transfersReducerSalary;
  const requestData = {
    CodeType: CONFIRM_TYPES[confirmMethod],
    Id: accountFrom,
    Sum: transferSum,
  };

  fetch(`${BASE_API_URL}/transfer/request-code`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  }).then((response) => {
    if (response.ok) {
      dispatch(fetchStop());
      return dispatch(push('/transfers/confirm', {
        confirmMethod,
        transferType: 'salary',
        transferData: requestData,
      }));
    }

    if (response.status === 500) {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response.json().then((json) => {
      throw new Error(json.Message);
    });
  }).catch((error) => {
    dispatch({
      type: 'salaryTransfer/SUBMIT_FORM_FAIL',
      payload: error.message,
    });
    dispatch(fetchStop());
  });
};
