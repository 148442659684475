import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../commonComponents/Button';
import { history } from '../../../../store';
import './assets/styles/styles.scss';


const AccountsBanner = ({ type }) => {
  const getTitle = () => {
    switch (type) {
      case 'deposit':
        return 'Персональный Вклад';

      case 'loan':
        return 'Персональный Заем';

      default:
        return '';
    }
  };

  const handleClick = () => {
    if (history) {
      history.push('/applications/new', { type });
    }
  };

  if (!type) {
    return null;
  }

  return (
    <div className="accounts-banner">
      <div>
        <span className="accounts-banner__title">{getTitle()}</span>
        {' '}
        для членов КПК Морской Фонд
      </div>
      <div>
        <Button type="primary" onClick={handleClick}>
          Заполнить заявку
        </Button>
      </div>
    </div>
  );
};

AccountsBanner.propTypes = {
  type: PropTypes.string,
};

AccountsBanner.defaultProps = {
  type: null,
};

export default AccountsBanner;
