import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import SingleRequest from '../../components/SingleRequest';
import ApplicationStatus from '../../components/Status';
import DataTable from '../../../../commonComponents/DataTable';
import Throbber from '../../../../commonComponents/Throbber';
import Pagination from '../../../../commonComponents/Pagination';
import Button from '../../../../commonComponents/Button';
import Modal from '../../../../commonComponents/Modal';
import FormLine, { FormLineColumn } from '../../../../commonComponents/FormLine/index.tsx';
import SelectBox from '../../../../commonComponents/SelectBox';
import DatePicker from '../../../../commonComponents/DatePicker';
import { FormControl } from '../../../../commonComponents/FormControl';
import { getRequests, getAccounts, cancelRequest } from '../../actions';
import { PAGINATION_LIMIT } from '../../../../constants';
import {
  getMoneyString,
  getDateString,
  getDateTimeString,
  getAccountLabel,
} from '../../../../utils';
import './assets/styles/styles.scss';

class ApplicationsList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    applications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    applicationsCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    accountForFilter: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })).isRequired,
    accountsFetching: PropTypes.bool.isRequired,
    cancelInProgress: PropTypes.bool.isRequired,
  };

  static availableStatuses = [
    {
      value: -1,
      label: 'Все',
    },
    {
      value: 0,
      label: 'Новые',
    },
    {
      value: 1,
      label: 'Выполненные',
    },
    {
      value: 2,
      label: 'Отклоненные',
    },
    {
      value: 3,
      label: 'Отмененные',
    },
  ];

  constructor(props) {
    super(props);

    this.maxDate = moment();
    this.minDate = moment().subtract(1, 'years');

    this.state = {
      selectedStatus: -1,
      selectedAccount: -1,
      dates: {
        startDate: this.minDate,
        endDate: this.maxDate,
      },
    };
  }

  componentDidMount() {
    if (document) {
      document.title = 'Мои распоряжения';
    }

    this.props.dispatch(getRequests({
      status: this.state.selectedStatus,
      dates: this.state.dates,
    }));
    this.props.dispatch(getAccounts());
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
      this.props.dispatch(getRequests({
        status: this.state.selectedStatus,
        dates: this.state.dates,
        account: this.state.selectedAccount,
      }));
    }

    if (prevProps.cancelInProgress && !this.props.cancelInProgress) {
      this.props.dispatch(getRequests({
        status: this.state.selectedStatus,
        dates: this.state.dates,
        account: this.state.selectedAccount,
      }));
    }
  }

  getAccountOptions = () => {
    const accountOptions = [
      {
        value: -1,
        label: 'Все счета',
      },
    ];

    this.props.accountForFilter.forEach(item => accountOptions.push({
      value: item.id,
      label: getAccountLabel(item),
    }));

    return accountOptions;
  };

  handleChangePage = pageNumber =>
    this.props.dispatch(getRequests({
      page: pageNumber,
      status: this.state.selectedStatus,
      dates: this.state.dates,
      account: this.state.selectedAccount,
    }));

  handleRequestClick = (requestId) => {
    if (this.modal) {
      this.modal.openModal({ requestId });
    }
  };

  handleChangeStatus = ({ value }) => this.setState({ selectedStatus: value });

  handleChangeAccount = ({ value }) => this.setState({ selectedAccount: value });

  handleChangeDates = dates => this.setState({ dates });

  cancelRequest = id => this.props.dispatch(cancelRequest(id));

  render() {
    const { loading, applications, cancelInProgress } = this.props;

    return (
      <div className="applications-page">
        <div className="applications-page__filter">
          <FormLine>
            <FormLineColumn size="4">
              <FormControl.FormLabel>Выберите счет</FormControl.FormLabel>
              <SelectBox
                placeholder="Выберите счет"
                options={this.getAccountOptions()}
                clearable={false}
                searchable={false}
                isLoading={this.props.accountsFetching}
                onChange={this.handleChangeAccount}
                value={this.state.selectedAccount}
              />
            </FormLineColumn>
            <FormLineColumn size="4">
              <FormControl.FormLabel>Выберите период</FormControl.FormLabel>
              <DatePicker
                dateStart={this.state.dates.startDate}
                dateEnd={this.state.dates.endDate}
                minDate={this.minDate}
                maxDate={this.maxDate}
                onChange={this.handleChangeDates}
              />
            </FormLineColumn>
            <FormLineColumn size="3">
              <FormControl.FormLabel>Выберите статус</FormControl.FormLabel>
              <SelectBox
                placeholder="Выберите статус"
                options={ApplicationsList.availableStatuses}
                clearable={false}
                searchable={false}
                onChange={this.handleChangeStatus}
                value={this.state.selectedStatus}
              />
            </FormLineColumn>
          </FormLine>
        </div>
        {(loading || cancelInProgress) ? (
          <Throbber inPanel />
        ) : (
          <div>
            <DataTable>
              <DataTable.Head>
                <DataTable.Row>
                  <DataTable.Cell>
                    <span>Получатель</span>
                    <br />
                    <small>Банк получателя</small>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <span>Сумма</span>
                    <br />
                    <small>Детали</small>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <span>Дата распоряжения</span>
                    <br />
                    <small>Номер распоряжения</small>
                  </DataTable.Cell>
                  <DataTable.Cell>Дата валютации</DataTable.Cell>
                  <DataTable.Cell>Статус</DataTable.Cell>
                </DataTable.Row>
              </DataTable.Head>
              <DataTable.Body columnsCount={5} notFoundMessage="Распоряжений не найдено">
                {applications.map(item => (
                  <DataTable.Row key={item.id}>
                    <DataTable.Cell>
                      <strong className="applications-page__receiver">{item.receiver}</strong>
                      <br />
                      <small>{item.receiverBank}</small>
                      <br />
                      {item.status === 1 && (
                        <small>
                          <Button type="inline" onClick={() => this.handleRequestClick(item.id)}>
                            Подробнее
                          </Button>
                        </small>
                      )}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      <strong>{getMoneyString(item.sum, item.currencyName)}</strong>
                      <br />
                      {item.details && (
                        <small>{item.details}</small>
                      )}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      <strong>{getDateTimeString(item.applicationDate)}</strong>
                      <br />
                      <small>Распоряжение № {item.id}</small>
                    </DataTable.Cell>
                    <DataTable.Cell>
                      <strong>{getDateString(item.executionDate)}</strong>
                    </DataTable.Cell>
                    <DataTable.Cell>
                      <ApplicationStatus status={item.status} />
                      {item.status === 0 && (
                        <small>
                          <Button
                            type="inline"
                            onClick={() => this.cancelRequest(item.id)}
                            className="applications-page__cancel-button"
                          >
                            Отменить
                          </Button>
                        </small>
                      )}
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
              </DataTable.Body>
            </DataTable>
            <Pagination
              key="pagination"
              items={applications}
              current={this.props.currentPage}
              limit={PAGINATION_LIMIT}
              onChangePage={this.handleChangePage}
              totalCount={this.props.applicationsCount}
            />
            <Modal
              ref={(element) => {
                this.modal = element;
              }}
              size="large"
            >
              <SingleRequest />
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applications: state.applicationsReducer.applications,
  applicationsCount: state.applicationsReducer.applicationsCount,
  loading: state.applicationsReducer.fetching,
  currentPage: state.applicationsReducer.currentPage,
  accountForFilter: state.applicationsReducer.accountForFilter,
  accountsFetching: state.applicationsReducer.accountsFetching,
  cancelInProgress: state.applicationsReducer.cancelInProgress,
});

export default connect(mapStateToProps)(ApplicationsList);
