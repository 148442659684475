import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../actions';
import './assets/styles/styles.scss';
import RecoveryForm from '../../components/RecoveryForm';
import logo from '../../../../static/img/logo.png';
import Throbber from '../../../../commonComponents/Throbber';
import RecoverySuccess from '../../components/RecoverySuccess';

const RecoveryPasswordContainer = (props) => {
  if (props.isAuthenticated || !props.showRecoveryForm) {
    props.appActions.push('/');
    return null;
  }

  return (
    <div className="recovery-screen">
      <img className="recovery-screen__logo" alt="Морской фонд" src={logo} />
      <div className="recovery-screen__wrapper">
        <p className="recovery-screen__heading">Забыли пароль?</p>
        {props.recoverySuccess ?
          <RecoverySuccess /> :
          <RecoveryForm
            loginAction={props.appActions.login}
            authError={props.authError ? props.authError.message : null}
            fetching={props.fetching}
          />
        }
        {props.fetching &&
          <Throbber className="recovery-screen__throbber" />
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.appReducer.isAuthenticated,
  authError: state.appReducer.authError,
  fetching: state.appReducer.fetching,
  showRecoveryForm: state.appReducer.showRecoveryForm,
  recoverySuccess: state.appReducer.recoverySuccess,
  recoveryBySms: state.appReducer.recoveryBySms,
});
const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions, push }, dispatch),
});

const RecoveryPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecoveryPasswordContainer);

export default RecoveryPassword;

RecoveryPasswordContainer.propTypes = {
  appActions: PropTypes.objectOf(PropTypes.func).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  authError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  fetching: PropTypes.bool.isRequired,
  showRecoveryForm: PropTypes.bool.isRequired,
  recoverySuccess: PropTypes.bool.isRequired,
};

RecoveryPasswordContainer.defaultProps = {
  authError: null,
};
