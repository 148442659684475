const initialState = {
  accounts: [],
  accountFrom: null,
  accountTo: null,
  transferSum: 0,
  accountsLoadingError: null,
  transferFormError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'currencyTransfer/ACCOUNTS_LOADED_SUCCESS':
      return {
        ...state,
        accounts: action.payload,
      };

    case 'currencyTransfer/ACCOUNTS_LOADED_FAIL':
      return {
        ...state,
        accountsLoadingError: action.payload,
      };

    case 'currencyTransfer/CHANGE_ACCOUNT': {
      if (action.payload.direction === 'from') {
        return {
          ...state,
          accountFrom: action.payload.id,
          accountTo: initialState.accountTo,
          transferSum: 0,
        };
      }

      return {
        ...state,
        accountTo: action.payload.id,
        transferSum: 0,
      };
    }

    case 'currencyTransfer/SET_TRANSFER_SUM':
      return {
        ...state,
        transferSum: action.payload,
      };

    case 'currencyTransfer/SUBMIT_FORM_FAIL':
      return {
        ...state,
        transferFormError: action.payload,
      };

    case 'CANCEL_TRANSFER':
      return initialState;

    default:
      return state;
  }
};
