import moment from 'moment';
import { getMoneyString } from '../../../utils';

export const DEFAULT_STRING_VALUE = '---';
export const CLIENT_DATE_FORMAT = 'DD.MM.YYYY';
export const CLIENT_DATETIME_FORMAT = 'DD.MM.YYYY, HH:mm:ss';

export const getCurrencyTitle = (currency) => {
  switch (currency) {
    case 'RUB':
      return 'Рубль РФ';

    case 'EUR':
      return 'Евро';

    case 'USD':
      return 'Доллар США';

    default:
      return currency;
  }
};

export const getValueString = (value, paramName, currency) => {
  switch (paramName) {
    case 'dateOfCreate':
    case 'dateOfEnd':
    case 'nextPaymentDate':
      return moment(value).format(CLIENT_DATE_FORMAT);

    case 'date':
      return moment(value).format(CLIENT_DATETIME_FORMAT);

    case 'sum':
    case 'balance':
    case 'clearBalance':
    case 'accruedIncome':
    case 'incomeToday':
    case 'paidIncome':
    case 'nextPayment':
    case 'nextPaymentSum':
    case 'openingSum':
    case 'penalty':
    case 'loanLateSum':
    case 'balanceIn':
    case 'balanceOut':
    case 'balanceDiff':
    case 'currentLoanBalance':
      return getMoneyString(value, currency);

    case 'percent':
    case 'memberFee':
      return `${value}%`;

    case 'currency':
    case 'currencySign':
      return getCurrencyTitle(value);

    default:
      return value;
  }
};

export const ACCOUNT_SUMMARY_COLUMNS = {
  current: [
    [
      {
        name: 'balance',
        title: 'Баланс',
      },
    ],
    [
      {
        name: 'number',
        title: 'Номер счета',
      },
    ],
    [
      {
        name: 'balanceDiff',
        title: 'Изменение баланса за период',
      },
    ],
  ],
  shares: [
    [
      {
        name: 'balance',
        title: 'Баланс',
      },
    ],
    [
      {
        name: 'number',
        title: 'Номер счета',
      },
    ],
    [
      {
        name: 'balanceDiff',
        title: 'Изменение баланса за период',
      },
    ],
  ],
  loan: [
    [
      {
        name: 'balance',
        title: 'Остаток основного долга',
      },
      {
        name: 'currentLoanBalance',
        title: 'Баланс счета',
      },
    ],
    [
      {
        name: 'number',
        title: 'Номер счета',
      },
    ],
    [
      {
        name: 'nextPaymentDate',
        title: 'Дата следующего платежа',
      },
    ],
    [
      {
        name: 'nextPaymentSum',
        title: 'Сумма следующего платежа',
      },
    ],
  ],
  deposit: [
    [
      {
        name: 'balance',
        title: 'Сумма размещения',
      },
    ],
    [
      {
        name: 'number',
        title: 'Номер счета',
      },
    ],
    [
      {
        name: 'nextPaymentDate',
        title: 'Дата следующего начисления',
      },
    ],
    [
      {
        name: 'nextPaymentSum',
        title: 'Сумма следующего начисления',
      },
    ],
  ],
  salary: [
    [
      {
        name: 'clearBalance',
        title: 'Текущий баланс',
      },
    ],
    [
      {
        name: 'number',
        title: 'Номер счета',
      },
    ],
    [
      {
        name: 'nextPaymentDate',
        title: 'Дата следующего начисления',
      },
    ],
    [
      {
        name: 'nextPaymentSum',
        title: 'Сумма следующего начисления',
      },
    ],
  ],
  raising: [
    [
      {
        name: 'balance',
        title: 'Сумма размещения',
      },
    ],
    [
      {
        name: 'number',
        title: 'Номер счета',
      },
    ],
    [
      {
        name: 'nextPaymentDate',
        title: 'Дата следующего начисления',
      },
    ],
    [
      {
        name: 'nextPaymentSum',
        title: 'Сумма следующего начисления',
      },
    ],
  ],
};

export const CONDITIONS_COLUMNS = {
  loan: [
    [
      {
        name: 'openingSum',
        title: 'Сумма открытия',
      },
      {
        name: 'penalty',
        title: 'Пеня',
      },
      {
        name: 'loanLateSum',
        title: 'Общая сумма просрочки',
      },
    ],
    [
      {
        name: 'dateOfCreate',
        title: 'Дата открытия',
      },
      {
        name: 'dateOfEnd',
        title: 'Плановая дата закрытия',
      },
      {
        name: 'funcPay',
        title: 'Тип начисления',
      },
      {
        name: 'percent',
        title: 'Процентная ставка',
      },
      {
        name: 'memberFee',
        title: 'Ставка членских взносов',
      },
    ],
    [
      {
        name: 'balanceIn',
        title: 'Входящий баланс на конец периода',
      },
      {
        name: 'balanceOut',
        title: 'Исходящий баланс на конец периода',
      },
      {
        name: 'balanceDiff',
        title: 'Изменение баланса за период',
      },
    ],
  ],
  deposit: [
    [
      {
        name: 'currency',
        title: 'Валюта',
      },
      {
        name: 'dateOfCreate',
        title: 'Дата открытия',
      },
      {
        name: 'dateOfEnd',
        title: 'Плановая дата закрытия',
      },
    ],
    [
      {
        name: 'funcPay',
        title: 'Тип начисления',
      },
      {
        name: 'percent',
        title: 'Процентная ставка',
      },
      {
        name: 'memberFee',
        title: 'Ставка членских взносов',
      },
    ],
  ],
  raising: [
    [
      {
        name: 'currency',
        title: 'Валюта',
      },
      {
        name: 'dateOfCreate',
        title: 'Дата открытия',
      },
      {
        name: 'dateOfEnd',
        title: 'Плановая дата закрытия',
      },
    ],
    [
      {
        name: 'funcPay',
        title: 'Тип начисления',
      },
      {
        name: 'percent',
        title: 'Процентная ставка',
      },
    ],
  ],
  salary: [
    [
      {
        name: 'currency',
        title: 'Валюта',
      },
      {
        name: 'dateOfCreate',
        title: 'Дата открытия',
      },
      {
        name: 'dateOfEnd',
        title: 'Плановая дата закрытия',
      },
    ],
    [
      {
        name: 'funcPay',
        title: 'Тип начисления',
      },
      {
        name: 'percent',
        title: 'Процентная ставка',
      },
      {
        name: 'memberFee',
        title: 'Ставка членских взносов',
      },
    ],
    [
      {
        name: 'balanceIn',
        title: 'Входящий баланс на конец периода',
      },
      {
        name: 'balanceOut',
        title: 'Исходящий баланс на конец периода',
      },
      {
        name: 'balanceDiff',
        title: 'Изменение баланса за период',
      },
    ],
  ],
  pension: [
    [
      {
        name: 'currency',
        title: 'Валюта',
      },
      {
        name: 'dateOfCreate',
        title: 'Дата открытия',
      },
      {
        name: 'dateOfEnd',
        title: 'Плановая дата закрытия',
      },
    ],
    [
      {
        name: 'funcPay',
        title: 'Тип начисления',
      },
      {
        name: 'percent',
        title: 'Процентная ставка',
      },
    ],
  ],
};

export const DETAILS_TABS = {
  current: [
    {
      name: 'operations',
      title: 'История операций',
    },
  ],
  shares: [
    {
      name: 'operations',
      title: 'История операций',
    },
  ],
  loan: [
    {
      name: 'operations',
      title: 'История операций',
    },
    {
      name: 'conditions',
      title: 'Информация',
    },
    {
      name: 'schedule',
      title: 'График платежей',
    },
  ],
  deposit: [
    {
      name: 'operations',
      title: 'История операций',
    },
    {
      name: 'conditions',
      title: 'Информация',
    },
  ],
  salary: [
    {
      name: 'operations',
      title: 'История операций',
    },
    {
      name: 'conditions',
      title: 'Информация',
    },
  ],
  raising: [
    {
      name: 'operations',
      title: 'История операций',
    },
    {
      name: 'conditions',
      title: 'Информация',
    },
  ],
};
