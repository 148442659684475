import {
  resultDataToCamelCase,
  getCookie,
} from '../../../utils';
import { BASE_API_URL } from '../../../constants';


export const getNotices = (sorting, section, { limit, page }) => (dispatch) => {
  const token = getCookie('access_token');

  if (!token) {
    return;
  }

  const urlBase = `${BASE_API_URL}/notices${section === 'new' ? '/new' : ''}`;
  const offset = ((page - 1) * limit) + 1;
  const url = `${urlBase}?ordering=${sorting}&limit=${limit}&offset=${offset}`;

  dispatch({
    type: 'NOTICES_FETCH_START',
  });

  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then((json) => {
      const result = resultDataToCamelCase(json);
      if (section === 'new') {
        dispatch({
          type: 'NOTICES_FETCH_SUCCESS',
          payload: {
            countNew: result.count,
            noticesNew: resultDataToCamelCase(result.notices),
          },
        });
      } else {
        dispatch({
          type: 'NOTICES_FETCH_SUCCESS',
          payload: {
            count: result.count,
            notices: resultDataToCamelCase(result.notices),
          },
        });
      }
    })
    .catch(error => dispatch({
      type: 'NOTICES_FETCH_FAIL',
      payload: error.message,
    }));
};

export const changeSorting = () => ({
  type: 'CHANGE_SORTING',
});

export const getNotice = id => (dispatch) => {
  const token = getCookie('access_token');

  if (!token || !id) {
    return;
  }

  dispatch({ type: 'GET_NOTICE_START' });
  fetch(`${BASE_API_URL}/notices/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }

      return response.json().then((json) => {
        throw new Error(json.Message);
      });
    })
    .then(json => dispatch({
      type: 'GET_NOTICE_SUCCESS',
      payload: resultDataToCamelCase(json),
    }))
    .catch(error => dispatch({
      type: 'GET_NOTICE_FAIL',
      payload: error.message,
    }));
};

export const noticeHide = id => (dispatch) => {
  const token = getCookie('access_token');
  fetch(`${BASE_API_URL}/notices/hide/${id}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return dispatch({
        type: 'HIDE_NOTICE',
        payload: id,
      });
    }
    /** Если не ОК, рэйзим ошибку из ответа с сервака */
    return response.json().then((json) => {
      throw new Error(resultDataToCamelCase(json).message);
    });
  }).catch(error => console.warn(error));
};

