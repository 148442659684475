const initialState = {
  accounts: [],
  loanAccount: null,
  debitAccount: null,
  transferSum: 0,
  shedule: [],
  transferFormError: null,
  sheduleError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'loanTransfer/ACCOUNTS_LOADED':
      return {
        ...state,
        accounts: action.payload,
        transferFormError: null,
      };


    case 'loanTransfer/ACCOUNTS_LOADING_FAIL':
      return {
        ...state,
        transferFormError: action.payload,
      };

    case 'loanTransfer/CHANGE_ACCOUNT_LOAN':
      return {
        ...state,
        loanAccount: action.payload,
        debitAccount: initialState.debitAccount,
        transferSum: initialState.transferSum,
        transferFormError: initialState.transferFormError,
        shedule: initialState.shedule,
      };

    case 'loanTransfer/CHANGE_ACCOUNT_DEBIT':
      return {
        ...state,
        debitAccount: action.payload,
        transferSum: initialState.transferSum,
        transferFormError: initialState.transferFormError,
        shedule: initialState.shedule,
      };

    case 'loanTransfer/SET_TRANSFER_SUM':
      return {
        ...state,
        transferSum: action.payload,
        transferFormError: initialState.transferFormError,
      };

    case 'loanTransfer/SCHEDULE_SUCCESS':
      return {
        ...state,
        shedule: action.payload,
        transferFormError: initialState.transferFormError,
        sheduleError: initialState.sheduleError,
      };

    case 'loanTransfer/SCHEDULE_FAIL':
      return {
        ...state,
        shedule: initialState.shedule,
        sheduleError: action.payload,
      };

    case 'loanTransfer/SUBMIT_FORM_FAIL':
      return {
        ...state,
        transferFormError: action.payload,
      };

    case 'loanTransfer/CLEAR_SCHEDULE':
      return {
        ...state,
        shedule: initialState.shedule,
        transferFormError: initialState.transferFormError,
        sheduleError: initialState.sheduleError,
      };

    case 'CANCEL_TRANSFER':
      return initialState;

    default:
      return state;
  }
};
