import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import TransferConfirm from '../../components/TransferConfirm';

export default class ConfirmPage extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        transferType: PropTypes.string,
      }),
    }).isRequired,
  };

  componentDidMount() {
    document.title = 'Платежи и переводы';
  }

  getHeading = () => {
    const { transferType } = this.props.location.state;
    switch (transferType) {
      case 'salary':
        return 'Перевод средств на зарплатную карту';

      case 'inner':
        return 'Перевод между своими счетами';

      case 'loan':
        return 'Досрочное погашение займа';

      case 'deposit':
        return 'Пополнение вклада';

      case 'currency':
        return 'Конвертация валюты';

      case 'to-partner':
        return 'Перевод другому пайщику кооператива';

      default:
        return '---';
    }
  };

  render() {
    if (!this.props.location.state) {
      return <Redirect to="/transfers" />;
    }

    return (
      <div className="transfers-page">
        <h1 key="heading" className="transfers-page__heading">
          {this.getHeading()}
        </h1>
        <div key="content" className="transfers-page__content">
          <TransferConfirm className="transfers-page__form" />
        </div>
      </div>
    );
  }
}
