import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as accountsActions from '../../actions';
import Table from '../../../../commonComponents/Table';
import Throbber from '../../../../commonComponents/Throbber';
import AccountsBanner from '../../components/AccountsBanner';
import SalaryTable from '../../components/SalaryTable';
import CurrentTable from '../../components/CurrentTable';


class Listing extends React.Component {
  static propTypes = {
    accounts: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])))).isRequired,
    accountsActions: PropTypes.objectOf(PropTypes.func).isRequired,
    match: PropTypes.shape({
      isExact: PropTypes.bool.isRequired,
      params: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.objectOf(PropTypes.string),
  }

  static defaultProps = {
    error: null,
  };

  componentDidMount() {
    const { accountType } = this.props.match.params;
    switch (accountType) {
      case 'current':
        document.title = 'Текущие счета';
        break;
      case 'shares':
        document.title = 'Счета паенакоплений';
        break;
      case 'salary':
        document.title = 'Cчета зарплатных программ';
        break;
      case 'deposit':
        document.title = 'Счета сберегательных программ';
        break;
      case 'loan':
        document.title = 'Займы';
        break;
      case 'raising':
        document.title = 'Другие доходы';
        break;

      default:
        break;
    }

    this.props.accountsActions.getAccountsListing(accountType);
  }

  renderTable = () => {
    if (this.props.error) {
      return (
        <div>
          <h2>Кажется имеет место быть досадная ошибка... Попробуйте обновить страницу</h2>
          <div><pre>{this.props.error.message}</pre></div>
        </div>
      );
    }

    if (this.props.match.params.accountType === 'current') {
      return <CurrentTable />;
    }

    if (this.props.match.params.accountType === 'salary') {
      return <SalaryTable />;
    }

    return <Table type={this.props.match.params.accountType} data={this.props.accounts} />;
  };

  render() {
    if (this.props.loading) {
      return <Throbber inPanel />;
    }

    return (
      <div>
        {this.renderTable()}
        {this.props.match.params.accountType === 'deposit' &&
          <AccountsBanner type="deposit" />
        }
        {this.props.match.params.accountType === 'loan' &&
          <AccountsBanner type="loan" />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.accountsReducer.accountsFetching,
  accounts: state.accountsReducer.accounts,
  error: state.accountsReducer.accountsError,
});

const mapDispatchToProps = dispatch => ({
  accountsActions: bindActionCreators(accountsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
