import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FormLabel from './label';
import FormError from './error';
import './assets/styles/styles.scss';
import CurrencyInput from './currencyInput';
import Throbber from '../Throbber';

export class FormControl extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    error: null,
    label: '',
    type: 'text',
    loading: false,
  };

  static FormLabel = FormLabel;
  static FormError = FormError;

  render() {
    const {
      className, type, name, error, id, label, onChange, loading, ...props
    } = this.props;
    return (
      <div
        className={cx('form-control', { [className]: className }, { 'form-control--error': error })}
      >
        {label && <FormLabel id={id}>{label}</FormLabel>}
        {type === 'money' ? (
          <CurrencyInput
            id={id}
            className={cx('form-control__input', { 'form-control__input--error': error })}
            name={name}
            onChangeEvent={(event, string, float) => onChange(float)}
            {...props}
          />
        ) : (
          <input
            id={id}
            className={cx('form-control__input', { 'form-control__input--error': error })}
            type={type}
            name={name}
            onChange={onChange}
            {...props}
          />
        )}
        {loading && (
          <span className="form-control__throbber">
            <Throbber inline />
          </span>
        )}
        {error && <FormError>{error}</FormError>}
      </div>
    );
  }
}

export const Radio = ({
  className, field, id, value, checked, children, onChange, ...props
}) => (
  <div className={cx('form-control', 'form-control--radio', { [className]: className })}>
    <FormLabel id={id}>
      <div>
        <span
          className={cx('form-control__radio-icon', {
            'form-control__radio-icon--active': checked,
          })}
        />
        {children}
      </div>
    </FormLabel>
    <input
      id={id}
      name={field}
      type="radio"
      value={value}
      checked={checked}
      className="form-control__input form-control__input--radio"
      onChange={event => onChange(event)}
      {...props}
    />
  </div>
);

Radio.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
};

Radio.defaultProps = {
  className: '',
  children: null,
};
