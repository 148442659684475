import React from 'react';
import PropTypes from 'prop-types';
import './assets/styles/styles.scss';


export default class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    onClose: PropTypes.func,
    size: PropTypes.oneOf([
      'medium',
      'large',
    ]),
  };

  static defaultProps = {
    onClose: null,
    size: 'medium',
  };

  state = {
    opened: false,
    additionalProps: null,
  };

  openModal = (additionalProps) => {
    this.setState({
      opened: true,
      additionalProps,
    });
    document.body.classList.add('fixed');
  }

  closeModal = () => {
    this.setState({
      opened: false,
    }, () => {
      if (typeof this.props.onClose === 'function') {
        this.props.onClose();
      }

      document.body.classList.remove('fixed');
    });
  }

  handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  }

  render() {
    if (this.state.opened) {
      return (
        <div className="modal" onScroll={event => event.stopPropagation()}>
          <div
            className="modal__overlay"
            onClick={this.closeModal}
            onKeyPress={this.handleKeyPress}
            role="button"
            tabIndex="0"
          />
          <button
            className={`modal__close-button modal__close-button--position-${this.props.size}`}
            onClick={this.closeModal}
          >
            X
          </button>
          <div
            className={`modal__window modal__window--${this.props.size}`}
            onScroll={event => event.stopPropagation()}
          >
            <div className="modal__content">
              {React.cloneElement(
                this.props.children,
                { propsFromModal: this.state.additionalProps },
              )}
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}
