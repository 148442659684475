import { BASE_API_URL } from '../../../constants';
import { getCookie } from '../../../utils';
import {
  DASHBOARD_FETCH_ACCOUNTS_FAILURE,
  DASHBOARD_FETCH_ACCOUNTS_REQUEST,
  DASHBOARD_FETCH_ACCOUNTS_SUCCESS,
  DASHBOARD_FETCH_LAST_OPERATIONS_REQUEST,
  DASHBOARD_FETCH_LAST_OPERATIONS_SUCCESS,
  DASHBOARD_FETCH_LAST_OPERATIONS_FAILURE,
} from '../constants';

export const getAccounts = () => (dispatch) => {
  const token = getCookie('access_token');

  if (!token) {
    return;
  }

  dispatch({
    type: DASHBOARD_FETCH_ACCOUNTS_REQUEST,
  });

  fetch(`${BASE_API_URL}/accounts`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text()
          .then((text) => {
            throw new Error(text);
          });
      }

      return response.json()
        .then((json) => {
          throw new Error(json.Message);
        });
    })
    .then(json => dispatch({
      type: DASHBOARD_FETCH_ACCOUNTS_SUCCESS,
      payload: json,
    }))
    .catch(error => dispatch({
      type: DASHBOARD_FETCH_ACCOUNTS_FAILURE,
      payload: error.message,
    }));
};


export const getLastOperations = limit => (dispatch) => {
  const token = getCookie('access_token');

  if (!token) {
    return;
  }

  dispatch({
    type: DASHBOARD_FETCH_LAST_OPERATIONS_REQUEST,
  });

  fetch(`${BASE_API_URL}/accounts/last-operations?limit=${limit}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      if (response.status === 500) {
        return response.text()
          .then((text) => {
            throw new Error(text);
          });
      }

      return response.json()
        .then((json) => {
          throw new Error(json.Message);
        });
    })
    .then(json => dispatch({
      type: DASHBOARD_FETCH_LAST_OPERATIONS_SUCCESS,
      payload: json,
    }))
    .catch(error => dispatch({
      type: DASHBOARD_FETCH_LAST_OPERATIONS_FAILURE,
      payload: error.message,
    }));
};
