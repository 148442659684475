import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DataTable from '../../../../commonComponents/DataTable';
import { getMoneyString } from '../../../../utils';

const OperationsTable = ({ data, currency, isLoan }) => (
  <DataTable>
    <DataTable.Head>
      <DataTable.Row>
        <DataTable.Cell>Дата транзакции</DataTable.Cell>
        <DataTable.Cell>Операция</DataTable.Cell>
        <DataTable.Cell>Приход</DataTable.Cell>
        <DataTable.Cell>Расход</DataTable.Cell>
        <DataTable.Cell>{isLoan ? 'Остаток\u00A0долга' : 'Баланс'}</DataTable.Cell>
      </DataTable.Row>
    </DataTable.Head>
    <DataTable.Body>
      {data.map(item => (
        <DataTable.Row key={item.id}>
          <DataTable.Cell>{moment(item.date).format('DD.MM.YYYY, HH:mm:ss')}</DataTable.Cell>
          <DataTable.Cell>
            <span>
              <span className="table__operation">{item.title}</span>
              <br />
              <small className="table__operation table__operation--details">{item.details}</small>
            </span>
          </DataTable.Cell>
          <DataTable.Cell>{item.sum >= 0 && getMoneyString(item.sum, currency)}</DataTable.Cell>
          <DataTable.Cell>{item.sum < 0 && getMoneyString(item.sum, currency)}</DataTable.Cell>
          <DataTable.Cell>{getMoneyString(item.balance, currency)}</DataTable.Cell>
        </DataTable.Row>
      ))}
    </DataTable.Body>
  </DataTable>
);

OperationsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.string,
    sum: PropTypes.number,
    balance: PropTypes.number,
  })).isRequired,
  currency: PropTypes.string.isRequired,
  isLoan: PropTypes.bool.isRequired,
};

export default OperationsTable;
