import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../actions';
import Main from '../Main';

export default (Component) => {
  class Authentication extends React.Component {
    componentDidMount() {
      this.props.appActions.getUser();
    }

    render() {
      if (!this.props.isAuthenticated) {
        return <Redirect to="/login" />;
      }

      return (
        <Main title>
          <Component {...this.props} />
        </Main>
      );
    }
  }

  Authentication.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    appActions: PropTypes.objectOf(PropTypes.func).isRequired,
  };

  const mapStateToProps = state => ({
    isAuthenticated: state.appReducer.isAuthenticated,
  });
  const mapDispatchToProps = dispatch => ({
    appActions: bindActionCreators(appActions, dispatch),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Authentication);
};
