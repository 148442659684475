import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../commonComponents/Button';
import './assets/styles/styles.scss';

const SetMaxSum = ({ label, onClick }) => (
  <div className="set-max-sum">
    <small>
      <Button
        type="inline"
        onClick={onClick}
      >
        {label}
      </Button>
    </small>
  </div>
);

SetMaxSum.propTypes = {
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SetMaxSum;
