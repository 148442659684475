const initialState = {
  accountsFetching: false,
  detailsFetching: false,
  accounts: {
    current: [],
    shares: [],
    salary: [],
    deposit: [],
    loan: [],
    raising: [],
    details: null,
  },
  accountsError: null,
  accountsFilters: {
    type: 'current',
    accountId: null,
    dateStart: null,
    dateEnd: null,
    minDate: null,
    maxDate: null,
  },
  details: {
    account: null,
    balance: null,
    operations: [],
    shedule: [],
    sheduleCount: 0,
  },
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ACCOUNTS_FETCH_START':
      return {
        ...state,
        accountsFetching: true,
        accountsError: initialState.accountsError,
      };

    case 'ACCOUNT_DETAILS_FETCH_START':
      return {
        ...state,
        detailsFetching: true,
      };

    case 'ACCOUNTS_FETCH_FAIL':
      return {
        ...state,
        accountsFetching: false,
        accountsError: action.payload,
      };

    case 'ACCOUNT_DETAILS_FETCH_FAIL':
      return {
        ...state,
        detailsFetching: false,
      };

    case 'ACCOUNTS_FETCH_SUCCESS':
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.payload.accountType]: action.payload.data,
        },
        accountsFetching: false,
        accountsError: initialState.accountsError,
      };

    case 'FETCH_FAIL':
      return {
        ...state,
        accountsError: action.payload,
        accountsFetching: false,
      };

    case 'ACCOUNT_DETAILS_FETCH_SUCCESS':
      return {
        ...state,
        details: {
          ...state.details,
          account: action.payload,
        },
        detailsFetching: false,
      };

    case 'BALANCE_DETAILS_SUCCESS':
      return {
        ...state,
        details: {
          ...state.details,
          balance: action.payload,
        },
      };

    case 'SET_ACCOUNT_FILTERS':
      return {
        ...state,
        accountsFilters: {
          ...state.accountsFilters,
          ...action.payload,
        },
      };

    case 'OPERATIONS_FETCH_SUCCESS':
      return {
        ...state,
        details: {
          ...state.details,
          operations: action.payload.operations,
          operationsCount: action.payload.count,
        },
      };

    case 'CLEAR_ACCOUNT_DETAILS':
      return {
        ...state,
        details: initialState.details,
      };

    case 'SHEDULE_FETCH_SUCCESS':
      return {
        ...state,
        details: {
          ...state.details,
          shedule: action.payload.shedule,
          sheduleCount: action.payload.sheduleCount,
        },
      };

    case 'RESET_DETAILS_AND_FILTERS':
      return {
        ...state,
        details: initialState.details,
        accountsFilters: initialState.accountsFilters,
      };

    default:
      return state;
  }
};

export default accountsReducer;
