const initialState = {
  news: [],
  newsFetching: false,
  newsFetchError: null,
};


const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NEWS_FETCH_START':
      return {
        ...state,
        newsFetching: true,
        newsFetchError: null,
      };

    case 'NEWS_FETCH_SUCCESS':
      return {
        ...state,
        news: action.payload.publications || [],
        newsFetching: false,
        newsFetchError: null,
      };

    case 'NEWS_FETCH_FAIL':
      return {
        ...state,
        newsFetching: false,
        newsFetchError: action.payload,
      };

    default:
      return state;
  }
};

export default newsReducer;
