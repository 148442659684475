import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '../../../../commonComponents/Button';

const FormOverlay = ({ dispatch }) => {
  const goToAccounts = () => {
    dispatch(push('/accounts/current'));
  };

  return (
    <div className="transfer-currency__overlay">
      <div className="transfer-currency__overlay-wrapper">
        <p className="transfer-currency__overlay-message">
          Уважаемы клиент, мы осуществляем обмен валюты по курсу биржи ММВБ. Данный функционал
          личного кабинета доступен с 10:00 до 18:30 (время московское). Пожалуйста, зайдите позже.
        </p>
        <Button onClick={goToAccounts} className="transfer-currency__overlay-button" type="primary">
          Перейти к текущим счетам
        </Button>
      </div>
    </div>
  );
};

FormOverlay.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(FormOverlay);
