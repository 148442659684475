import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import thunkMiddleware from 'redux-thunk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createLogger } from 'redux-logger';
import PromiseHelper from '../utils/PromiseHelper';

import rootReducer from '../rootReducer';

const loggerMiddleware = createLogger();

export const history = createHistory();

const middlewares = [
  routerMiddleware(history),
  thunkMiddleware.withExtraArgument(new PromiseHelper()),
];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(loggerMiddleware);
}
// eslint-disable-next-line no-underscore-dangle
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// // eslint-disable-next-line no-undef,function-paren-newline,max-len
// export const configureStore = preloadedState => createStore(rootReducer, preloadedState, composeEnhancers(
//   // eslint-disable-next-line no-undef
//   applyMiddleware(...middlewares)));
export const configureStore = preloadedState =>
  createStore(rootReducer, preloadedState, applyMiddleware(...middlewares));
