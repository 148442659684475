import { getCookie, resultDataToCamelCase } from '../../../../utils';
import PromiseHelper from '../../../../utils/PromiseHelper';
import { BASE_API_URL, CONFIRM_TYPES } from '../../../../constants';
import { ConfirmMethodType } from '.';

const promiseHelper = new PromiseHelper();

export interface DefaultResponseWithError {
  Message?: string;
}

export interface BaseAccount {
  id: number;
  accountName: string;
  currencyName: string;
}

export interface InnerAccount extends BaseAccount {
  avaliableAccounts: BaseAccount[];
  balance: number;
}

async function processResponse<T = {}>(response: Response): Promise<T> {
  if (response.ok) {
    const result = await response.json();
    return resultDataToCamelCase(typeof result === 'string' ? JSON.parse(result) : result) as T;
  }

  if (response.status === 500) {
    throw Error(await response.text());
  }

  const errorResult: DefaultResponseWithError = await response.json();
  throw Error(errorResult.Message);
}

export async function getAccounts<T = {}>(): Promise<T> {
  const token = getCookie('access_token');
  if (!token) {
    throw Error('access_token is required');
  }

  const request = () =>
    fetch(`${BASE_API_URL}/transfer/inner`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

  const response = await promiseHelper.request(request(), 'getAccounts', 500);

  return await processResponse<T>(response);
}

export async function getPartner<T = {}>(partnerPhoneOrEmail: string): Promise<T> {
  const token = getCookie('access_token');
  if (!token) {
    throw Error('access_token is required');
  }

  const request = () =>
    fetch(`${BASE_API_URL}/transfer/outer/transferpartner?emailorphone=${partnerPhoneOrEmail}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

  const response = await promiseHelper.request(request(), 'getPartner', 500);

  return await processResponse<T>(response);
}

export async function getCode<T extends { confirmMethod: ConfirmMethodType }>(transferData: T) {
  const token = getCookie('access_token');
  if (!token) {
    throw Error('access_token is required');
  }

  const codeType = CONFIRM_TYPES[transferData.confirmMethod as ConfirmMethodType];

  const request = () =>
    fetch(`${BASE_API_URL}/transfer/outer/request-code/${codeType}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

  const response = await promiseHelper.request(request(), 'getCode', 500);

  if (response.ok) {
    return Promise.resolve();
  }

  if (response.status === 500) {
    throw Error(await response.text());
  }

  const errorResult: DefaultResponseWithError = await response.json();
  throw Error(errorResult.Message);
}
