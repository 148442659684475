import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'react-router-redux';
import ReactGA from 'react-ga';
import 'normalize.css';
import './static/styles/global.scss';
import { configureStore, history } from './store';
import Routes from './routes';

const store = configureStore();
ReactGA.initialize('UA-67697148-2', { debug: process.env.NODE_ENV === 'development' });

history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
