import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './assets/styles/styles.scss';
import { FormControl } from '../../../../commonComponents/FormControl';
import { showRecoveryForm } from '../../actions';

class LoginForm extends React.Component {
  static propTypes = {
    loginAction: PropTypes.func.isRequired,
    authError: PropTypes.string,
    fetching: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    authError: null,
  }

  constructor(props) {
    super(props);

    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.recoveryHandler = this.recoveryHandler.bind(this);

    this.state = {
      login: '',
      password: '',
      showPassword: false,
      loginError: '',
      passwordError: '',
      authError: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.authError !== nextProps.authError) {
      this.setState({ authError: nextProps.authError });
    }
  }

  changeHandler(event) {
    const { name, value, checked } = event.target;

    if (name === 'showPassword') {
      this.setState({
        showPassword: checked,
      });
    } else {
      this.setState({
        [name]: value,
        [`${name}Error`]: '',
        authError: null,
      });
    }
  }

  submitHandler(event) {
    event.preventDefault();
    const loginData = {
      login: this.state.login,
      password: this.state.password,
    };
    if (loginData.login.length === 0) {
      this.setState({
        loginError: 'Введите логин или e-mail',
      });
    }
    if (loginData.password.length === 0) {
      this.setState({
        passwordError: 'Введите пароль',
      });
    }

    if (this.state.loginError === '' && this.state.passwordError === '') {
      this.props.loginAction(loginData);
    }
  }

  recoveryHandler() {
    this.props.dispatch(showRecoveryForm());
  }

  render() {
    return (
      <div>
        <form className="login-form" onSubmit={this.submitHandler} onChange={this.changeHandler}>
          <FormControl
            className="login-form__form-control"
            type="text"
            name="login"
            id="login"
            placeholder="Логин или e-mail"
            error={this.state.loginError}
            onChange={() => {}}
          />
          <FormControl
            className="login-form__form-control"
            type={this.state.showPassword ? 'text' : 'password'}
            name="password"
            id="password"
            placeholder="Пароль"
            error={this.state.passwordError}
            onChange={() => {}}
          />
          <label htmlFor="showPassword" className="login-form__show-password">
            <input type="checkbox" id="showPassword" name="showPassword" defaultChecked={this.state.showPassword} />
            Показать пароль
          </label>
          <input
            type="submit"
            className="login-form__button"
            value={this.props.fetching ? 'Выполняется вход' : 'Войти в личный кабинет'}
          />
          {this.state.authError &&
            <p className="login-form__error">{this.state.authError}</p>
          }
        </form>
        <p className="login-form__restore-password">
          <button className="inline-button" onClick={this.recoveryHandler}>Забыли пароль?</button>
        </p>
      </div>
    );
  }
}

export default connect()(LoginForm);
